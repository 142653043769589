import React, { FC, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import '../../style/style.scss';
import I18n from '../../helpers/i18n';
import LoginRegisterModal from '../loginregister/loginregister';
import API from '../../helpers/api';
import {
    refreshTokenRequest,
    refreshTokenReset,
    getUserInfo,
    getUserInfoReset,
    updateUserInfo,
} from '../../helpers/actions/redux-user-actions';
import Link from '../../components/link';

import Logo from '../../images/logo-large-white.png';
import Hamburger from '../../images/menu-mobile.png';
import Underline from '../../images/menu-selected.png';
import Menuclose from '../../images/menu-mobile-cross.png';
import i18n from '../../helpers/i18n';

const APIManager = API.instance;
interface Props {
    disableNavigation?: boolean;
    customLogo?: string;
}

const Header: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const location = useLocation();

    const userLanguage = APIManager.getCookie('language');
    //const [userLanguage, setUserLanguage] = useState(APIManager.getCookie('language'));

    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [loginRegisterModalVisible, setLoginRegisterModalVisible] = useState(false);

    const refreshTokenState = useSelector((state: RootStateOrAny) => state.userReducers.refreshTokenState);
    const getUserState = useSelector((state: RootStateOrAny) => state.userReducers.getUserState);
    const userData = useSelector((state: RootStateOrAny) => state.userReducers.userData);

    useEffect(() => {
        if ((!APIManager.isLoggedIn() || !APIManager.hasUserID) && APIManager.refreshPossible()) {
            dispatch(refreshTokenRequest());
        }
    }, [APIManager.isLoggedIn()]);

    useEffect(() => {
        if (refreshTokenState === 'success' || refreshTokenState === 'error') {
            dispatch(refreshTokenReset());
            dispatch(getUserInfo());
        }
    }, [refreshTokenState]);

    useEffect(() => {
        if (getUserState === 'success') {
            APIManager.setCookie('user_id', userData.id, { maxAge: 604800 });
            dispatch(getUserInfoReset());
        } else if (getUserState === 'error') {
            dispatch(getUserInfoReset());
        }
    }, [getUserState]);

    useEffect(() => {
        if (state && state.showLoginModal) {
            setLoginRegisterModalVisible(true);
        }
    }, [state]);

    const isCurrentLocation = (data: string) => {
        if (location && location.pathname && location.pathname === data) {
            return true;
        } else {
            return false;
        }
    };

    const setNewLanguage = (language: string) => {
        if (i18n.language !== language) {
            i18n.changeLanguage(language, (err) => {
                if (!err) {
                    if (APIManager.isLoggedIn() && APIManager.hasUserID()) {
                        dispatch(
                            updateUserInfo({
                                language: language,
                            })
                        );
                    }

                    APIManager.setCookie('language', language, { maxAge: 604800 });

                    setTimeout(() => {
                        window.location.reload();
                    }, 200);
                }
            });
        }
    };

    const renderLanguageSetting = () => {
        let availableLanguages = APIManager.getAvailableLanguages();
        let browserLanguage = navigator.language;
        let defaultLanguage = 'en-US';

        if (userLanguage === undefined) {
            let languageSupported = false;
            for (let language of availableLanguages) {
                if (language === browserLanguage) {
                    languageSupported = true;
                }
            }
            setNewLanguage(languageSupported ? browserLanguage : defaultLanguage);
        } else {
            setNewLanguage(userLanguage);
        }

        return (
            <span className="language-setting">
                <select onChange={(val) => setNewLanguage(val.target.value)} value={userLanguage}>
                    {availableLanguages.map((item: any, index: number) => {
                        return (
                            <option value={item} key={index}>
                                {I18n.t(item.toUpperCase()) as string}
                            </option>
                        );
                    })}
                </select>
            </span>
        );
    };

    const renderMenuItem = (location: string, name: string) => {
        return (
            <Link onClick={() => navigate(location, { state: {} })} path={location}>
                <span className="menu-item">
                    {name}
                    {isCurrentLocation(location) && <img src={Underline} className="underlined" alt="menu-underline" />}
                </span>
            </Link>
        );
    };

    const renderMobileMenuItem = (location: string, name: string) => {
        return (
            <div className="mobile-menu-item-container" onClick={() => navigate(location, { state: {} })}>
                <span className="mobile-menu-item">
                    {name}
                    {isCurrentLocation(location) && (
                        <img src={Underline} className="underlined" alt="menu-underline-mobile" />
                    )}
                </span>
            </div>
        );
    };

    return (
        <header className={props.disableNavigation ? 'custom-view' : ''}>
            <div className="container-fluid">
                <div
                    className={`menu-dark-background ${mobileMenuVisible && 'open'}`}
                    onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
                />

                {props.customLogo && props.customLogo !== '' && (
                    <div className="d-flex justify-content-center align-items-center custom-logo-container">
                        <img
                            className="custom-logo"
                            src={APIManager.getDirectusItemURL(props.customLogo)}
                            alt="brand logo"
                        />
                    </div>
                )}

                {!props.disableNavigation && (
                    <>
                        <div className={`mobile-menu ${mobileMenuVisible && 'open'}`}>
                            <div className="menu-top">
                                <span>{I18n.t('MENU') as string}</span>
                                <img
                                    src={Menuclose}
                                    className="menu-icon-close"
                                    alt="menu"
                                    onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
                                />
                            </div>
                            <div className="language-setting-mobile">{renderLanguageSetting()}</div>
                            <div className="menu-divider" />
                            <div className="menu-container ">
                                {renderMobileMenuItem('/', I18n.t('MENU_HOME'))}
                                {renderMobileMenuItem('/catalog', I18n.t('MENU_CATALOG'))}
                                {renderMobileMenuItem('/pricing', I18n.t('MENU_PRICING'))}
                                {renderMobileMenuItem('/contact', I18n.t('MENU_CONTACT'))}
                                {APIManager.isLoggedIn() ? (
                                    renderMobileMenuItem('/account', I18n.t('MENU_ACCOUNT'))
                                ) : (
                                    <span
                                        className="mobile-menu-item"
                                        onClick={() => setLoginRegisterModalVisible(true)}
                                    >
                                        {I18n.t('MENU_LOGIN') as string}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="d-flex flex-row align-items-center">
                            <div className="d-lg-none">
                                <img
                                    src={Hamburger}
                                    alt="mobilemenu"
                                    className="mobile-menu-icon"
                                    onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
                                />
                            </div>
                            <div className="col-12 col-lg-2 header-logo">
                                <Link onClick={() => navigate('/', { state: {} })} path={'/'}>
                                    <img src={Logo} alt="Logo" className="logo" />
                                </Link>
                            </div>
                            <div className="d-none d-lg-block col-lg-10 header-menu">
                                {renderMenuItem('/', I18n.t('MENU_HOME'))}
                                {renderMenuItem('/catalog', I18n.t('MENU_CATALOG'))}
                                {renderMenuItem('/pricing', I18n.t('MENU_PRICING'))}
                                {renderMenuItem('/contact', I18n.t('MENU_CONTACT'))}

                                {APIManager.isLoggedIn() ? (
                                    renderMenuItem('/account', I18n.t('MENU_ACCOUNT'))
                                ) : (
                                    <span className="menu-item" onClick={() => setLoginRegisterModalVisible(true)}>
                                        {I18n.t('MENU_LOGIN') as string}
                                    </span>
                                )}

                                {renderLanguageSetting()}
                            </div>
                        </div>
                    </>
                )}
                {
                    <LoginRegisterModal
                        open={loginRegisterModalVisible}
                        Close={() => setLoginRegisterModalVisible(false)}
                    />
                }
            </div>
        </header>
    );
};

export default Header;
