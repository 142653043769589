import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AutoScroll: FC = (props) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        } as any);
    }, [location]);

    return null;
};

export default AutoScroll;
