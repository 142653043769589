import React, { useState, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import {
    getCatalogItem,
    catalogItemReset,
    uploadRequest,
    uploadDataReset,
} from '../../helpers/actions/redux-data-actions';
import { hasValidSubscription, hasValidSubscriptionReset } from '../../helpers/actions/redux-stripe-actions';
import {
    renderRequest,
    renderRequestReset,
    checkUserLimit,
    checkUserLimitReset,
} from '../../helpers/actions/redux-user-actions';
import { getPageGalleryItem } from '../../helpers/actions/redux-page-actions';
import Wrapper from '../../components/wrapper/wrapper';
import API from '../../helpers/api';
import I18n from '../../helpers/i18n';
import moment from 'moment';
import htmr from 'htmr';
import '../../style/style.scss';
import DropDownArrow from '../../images/drop-down-arrow.png';
import Link from '../../components/link';
import Watermark from '../../images/watermark.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingIcon from '../../images/loading.png';
import Resizer from 'react-image-file-resizer';
import OptionToggle from './optionToggle/toggle';
import OptionColor from './optionColor/color';
import OptionNumber from './optionNumber/number';
import OptionDate from './optionDate/date';
import OptionString from './optionString/string';
import OptionImage from './optionImage/image';

const APIManager = API.instance;
interface Props {}

const CustomizePage: FC<Props> = (props) => {
    interface optionObject {
        optionName: string;
        optionPlaceholder: string;
        optionText: string;
        optionType: string;
        optionValue: any;
    }

    const resizeFile = (file: any, newWidth: number, newHeight: number, minWidth: number, minHeight: number) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                newWidth,
                newHeight,
                'PNG',
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                'blob',
                minWidth,
                minHeight
            );
        });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const videoSizes = APIManager.getAvailableSizes();
    const defaultInputLimit = APIManager.getCustomizeFieldLimit();
    const queryParameters = new URLSearchParams(window.location.search);
    const itemId = queryParameters.get('id') as any;
    const defaultImageSize = 750;
    const defaultMaxResolution = 3000;

    const [seoTitle, setSeoTitle] = useState<string>('');
    const [seoDesc, setSeoDesc] = useState<string>('');
    const pageData = useSelector((state: RootStateOrAny) => state.pageReducers.galleryItemData);

    const [feedbackText, setFeedbackText] = useState<string>('');
    const [rotDeg, setRotDeg] = useState(0);
    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [uploadingRequest, setUploadingRequest] = useState<boolean>(false);
    const [item, setItem] = useState(null as any);
    const [itemPreview, setItemPreview] = useState<(string | boolean)[]>([]);
    const [availableSizes, setAvailableSizes] = useState([]);
    const [availableOptions, setAvailableOptions] = useState<optionObject[]>([]);
    const [availableOptionsFound, setAvailableOptionsFound] = useState(0);
    const [size, setSize] = useState<number>(0);
    const [hasSubscription, setHasSubscription] = useState<boolean>(false);
    const [translatedData, setTranslatedData] = useState(null as any);
    const [renderLimitReached, setRenderLimitReached] = useState<boolean>(false);
    const [uploadingFile, setUploadingFile] = useState<boolean>(false);
    const [uploadingFileID, setUploadingFileID] = useState<number>(-1);
    const [toastId, setToastId] = useState<number | string>(null as any);
    const [submittingData, setSubmittingData] = useState<boolean>(false);

    const catalogItemState = useSelector((state: RootStateOrAny) => state.dataReducers.catalogItemState);
    const catalogItemData = useSelector((state: RootStateOrAny) => state.dataReducers.catalogItemData);
    const validSubState = useSelector((state: RootStateOrAny) => state.stripeReducers.validSubState);
    const validSubData = useSelector((state: RootStateOrAny) => state.stripeReducers.validSubData);
    const renderRequestState = useSelector((state: RootStateOrAny) => state.userReducers.renderRequestState);
    const renderRequestData = useSelector((state: RootStateOrAny) => state.userReducers.renderRequestData);
    const userLimitsState = useSelector((state: RootStateOrAny) => state.userReducers.limitState);
    const userLimitsData = useSelector((state: RootStateOrAny) => state.userReducers.limitData);
    const uploadState = useSelector((state: RootStateOrAny) => state.dataReducers.uploadState);
    const uploadData = useSelector((state: RootStateOrAny) => state.dataReducers.uploadData);
    const uploadError = useSelector((state: RootStateOrAny) => state.dataReducers.uploadError);

    useEffect(() => {
        setLoadingData(true);
        dispatch(catalogItemReset());
        dispatch(renderRequestReset());
        dispatch(getPageGalleryItem());
        dispatch(checkUserLimitReset());
        dispatch(checkUserLimit());

        return () => {
            dispatch(checkUserLimitReset());
            dispatch(catalogItemReset());
            dispatch(renderRequestReset());
            dispatch(hasValidSubscriptionReset());
            setItem(null);
            setItemPreview([]);
            dispatch(uploadDataReset());
        };
    }, []);

    useEffect(() => {
        if (Object.keys(pageData).length > 0) {
            setSeoTitle(pageData.seo_title);
            setSeoDesc(pageData.seo_description);
            setFeedbackText(APIManager.filterLanguageData(pageData).feedback);
        }
    }, [pageData]);

    useEffect(() => {
        if (validSubState === 'success') {
            setHasSubscription(validSubData.status);
        } else if (validSubState === 'error') {
            setHasSubscription(false);
            dispatch(hasValidSubscriptionReset());
        }
    }, [validSubState]);

    useEffect(() => {
        if (userLimitsState === 'success') {
            if (
                userLimitsData &&
                userLimitsData !== '' &&
                userLimitsData !== undefined &&
                userLimitsData !== null &&
                userLimitsData?.remain &&
                userLimitsData.remain !== undefined
            ) {
                if (parseInt(userLimitsData.remain) <= 0) {
                    setRenderLimitReached(true);
                } else {
                    setRenderLimitReached(false);
                }
            } else {
                setRenderLimitReached(false);
            }
        }
    }, [userLimitsState]);

    useEffect(() => {
        dispatch(getCatalogItem(itemId));
        dispatch(hasValidSubscription());
    }, [itemId]);

    useEffect(() => {
        if (loadingData && catalogItemState === 'success' && catalogItemData.length > 0) {
            setLoadingData(false);
            setItem(catalogItemData[0]);
            setTranslatedData(APIManager.filterLanguageData(catalogItemData[0]));
        }
    }, [catalogItemState]);

    useEffect(() => {
        if (uploadingFile) {
            if (uploadState === 'success') {
                dispatch(uploadDataReset());

                toast.update(toastId, {
                    render: I18n.t('CUST_UPLOAD_SUCCESS') as string,
                    type: 'success',
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                });

                setTimeout(() => {
                    let fileObject = availableOptions[uploadingFileID]['optionValue'];
                    fileObject['uploadID'] = uploadData.id;
                    changeValueOption(uploadingFileID, fileObject, 0);
                }, 1000);
            } else if (uploadState === 'error') {
                setUploadingFile(false);
                setSubmittingData(false);
                dispatch(uploadDataReset());

                toast.update(toastId, {
                    render:
                        uploadError.message !== undefined
                            ? uploadError.message
                            : (I18n.t('CUST_UPLOAD_FAILURE') as string),
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                });
            }
        }
    }, [uploadState]);

    useEffect(() => {
        if (uploadingFile) {
            setUploadingFile(false);
            startOrder();
        }
    }, [availableOptions]);

    useEffect(() => {
        if (uploadingRequest) {
            if (renderRequestState === 'success') {
                setUploadingRequest(false);
                setSubmittingData(false);
                navigate('/processing?id=' + renderRequestData.id, {
                    state: {},
                });
            } else if (renderRequestState === 'error') {
                setUploadingRequest(false);
                setSubmittingData(false);
                dispatch(renderRequestReset());
            }
        }
    }, [renderRequestState]);

    useEffect(() => {
        if (item !== null) {
            let tempArraySizes = [] as any;
            let tempArrayOptions = [] as any;
            let firstSizeFound = false;
            let optionsFound = 0;

            for (let i = 0; i < videoSizes.length; i++) {
                tempArraySizes.push({ name: videoSizes[i], enabled: false, thumbNailID: 0 });
            }
            for (let i = 1; i < APIManager.getCustomizeMaxSizes() + 1; i++) {
                if (item['size_' + i] !== null && item['size_' + i] !== undefined) {
                    let tempSize = parseInt(item['size_' + i]);

                    if (!Number.isNaN(tempSize) && tempSize >= 0 && tempSize <= videoSizes.length) {
                        tempArraySizes[tempSize] = { name: videoSizes[tempSize], enabled: true, thumbNailID: i };

                        if (!firstSizeFound) {
                            firstSizeFound = true;
                            setSize(tempSize);
                        }
                    }
                }
            }

            for (let i = 0; i < APIManager.getCustomizeMaxOptions(); i++) {
                if (item['option' + (i + 1)] === null) {
                    tempArrayOptions.push({
                        optionName: null,
                        optionType: null,
                    });
                    optionsFound++;
                } else if (item['option' + (i + 1)] !== undefined && item['option' + (i + 1)] !== '') {
                    tempArrayOptions.push({
                        optionName: item['option' + (i + 1)].toLowerCase(),
                        optionType: item['type' + (i + 1)].toLowerCase(),
                        optionText: translatedData['text' + (i + 1)],
                        optionPlaceholder: translatedData['place' + (i + 1)],
                        optionValue: '',
                    });
                    optionsFound++;
                }
            }

            setAvailableSizes(tempArraySizes);
            setAvailableOptions(tempArrayOptions);
            setAvailableOptionsFound(optionsFound);

            setSubmittingData(false);
            setUploadingFile(false);
            setUploadingRequest(false);
        }
    }, [item]);

    useEffect(() => {
        if (item !== null && size !== 0) {
            let thumbNailID = availableSizes[size]['thumbNailID'];
            let previewData = APIManager.getCatalogPreviewURL(item, thumbNailID);

            setItemPreview([previewData[0], previewData[1]]);
        }
    }, [size]);

    useEffect(() => {
        if (submittingData) {
            setTimeout(() => {
                setRotDeg(rotDeg < 360 ? rotDeg + 45 : 45);
            }, 100);
        }
    }, [rotDeg, submittingData]);

    const changeValueOption = (index: number, value: any, limit: number) => {
        if (value !== null && value !== undefined && (value.length <= limit || limit === 0)) {
            setAvailableOptions(
                Object.values({
                    ...availableOptions,
                    [index]: { ...availableOptions[index], optionValue: value },
                } as any)
            );
        }
    };

    const prepareOptionData = (data: any) => {
        if (data !== null && data !== undefined && data.optionType !== null && data.optionType !== undefined) {
            if (String(data.optionType).includes('string')) {
                if (data.optionValue !== '' && data.optionValue !== null && data.optionValue !== undefined) {
                    return data.optionValue;
                } else {
                    return ' ';
                }
            } else if (String(data.optionType).includes('image')) {
                if (
                    data.optionValue !== null &&
                    data.optionValue !== undefined &&
                    data.optionValue.uploadID !== null &&
                    data.optionValue.uploadID !== undefined &&
                    data.optionValue.uploadID !== ''
                ) {
                    return data.optionValue.uploadID;
                } else {
                    return ' ';
                }
            }
        }
        return '';
    };

    const startOrder = () => {
        if (APIManager.isLoggedIn() && APIManager.hasUserID() && hasSubscription) {
            setSubmittingData(true);
            let filesToUpload = false;

            for (let i = 0; i < availableOptions.length && !filesToUpload; i++) {
                if (
                    availableOptions[i]['optionType'] !== null &&
                    availableOptions[i]['optionType'].includes('image') &&
                    availableOptions[i]['optionValue'] !== undefined &&
                    availableOptions[i]['optionValue'] !== null &&
                    typeof availableOptions[i]['optionValue'] == 'object' &&
                    availableOptions[i]['optionValue']['uploadID' as any] === undefined
                ) {
                    let requestedWidth = defaultImageSize;
                    let requestedHeight = defaultImageSize;
                    let optionType = availableOptions[i]['optionType'];
                    let imageWidth = parseInt(availableOptions[i]['optionValue']['width']);
                    let imageHeight = parseInt(availableOptions[i]['optionValue']['height']);
                    const formData = new FormData();

                    formData.append('folder', APIManager.getUploadFolder());
                    filesToUpload = true;
                    setUploadingFile(true);
                    setUploadingFileID(i);
                    setToastId(
                        toast.loading(
                            (I18n.t('CUST_UPLOAD_START') as string) + ' ' + availableOptions[i]['optionValue']['name']
                        )
                    );

                    if (optionType.includes('image') && optionType.includes('-') && optionType.includes('x')) {
                        requestedWidth = parseInt(
                            optionType.substring(optionType.indexOf('-') + 1, optionType.indexOf('x'))
                        );
                        requestedHeight = parseInt(
                            optionType.substring(optionType.indexOf('x') + 1, optionType.length)
                        );
                    }

                    resizeFile(
                        availableOptions[i]['optionValue'],
                        requestedWidth,
                        requestedHeight,
                        imageWidth >= imageHeight ? requestedWidth : 0,
                        imageHeight >= imageWidth ? requestedHeight : 0
                    )
                        .then((result: any) => {
                            formData.append('file', result, availableOptions[i]['optionValue']['name']);
                            setTimeout(() => {
                                dispatch(uploadRequest(formData));
                            }, 1000);
                        })
                        .catch((error) => {});
                }
            }

            if (!filesToUpload) {
                let renderRequestBody = {} as any;

                renderRequestBody['user'] = APIManager.getCookie('user_id');
                renderRequestBody['gallery'] = itemId;
                renderRequestBody['size'] = size;
                renderRequestBody['status'] = 'created';
                renderRequestBody['created'] = moment.utc().format('YYYY-MM-DD HH:MM').toString();

                for (let i = 0; i < availableOptionsFound; i++) {
                    renderRequestBody['option' + (i + 1)] = prepareOptionData(availableOptions[i]);
                }

                setUploadingRequest(true);
                dispatch(renderRequest(renderRequestBody));
            }
        } else {
            navigate('/pricing', { state: {} });
        }
    };

    const renderAvailableSizes = () => {
        return (
            <div className="option-size">
                <div className="option-title">{I18n.t('CUST_SIZE') as string}</div>
                <div className="option-size-container">
                    <img src={DropDownArrow} alt={'icon-menu-size'} className="option-size-icon" />
                    <select onChange={(val) => setSize(parseInt(val.target.value))} value={size}>
                        {availableSizes.map((item: any, index: number) => {
                            if (item.enabled && item.name !== null) {
                                return (
                                    <option value={index} key={index}>
                                        {I18n.t(item.name.toUpperCase()) as string}
                                    </option>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </select>
                </div>
            </div>
        );
    };

    const renderOption = (item: any, index: number) => {
        if (item['optionType'] !== null && item['optionType'] !== undefined) {
            if (item['optionType'] === 'toggle') {
                return (
                    <OptionToggle
                        index={index}
                        data={item}
                        changeValueOption={(data) => changeValueOption(index, data, defaultInputLimit)}
                        submittingData={submittingData}
                    />
                );
            } else if (item['optionType'] === 'color') {
                return (
                    <OptionColor
                        index={index}
                        data={item}
                        changeValueOption={(data) => changeValueOption(index, data, defaultInputLimit)}
                        submittingData={submittingData}
                    />
                );
            } else if (item['optionType'] === 'number') {
                return (
                    <OptionNumber
                        index={index}
                        data={item}
                        changeValueOption={(data) => changeValueOption(index, data, defaultInputLimit)}
                        submittingData={submittingData}
                    />
                );
            } else if (item['optionType'] === 'date') {
                return (
                    <OptionDate
                        index={index}
                        data={item}
                        changeValueOption={(data) => changeValueOption(index, data, defaultInputLimit)}
                        submittingData={submittingData}
                    />
                );
            } else if (item['optionType'].includes('image')) {
                return (
                    <OptionImage
                        index={index}
                        data={item}
                        changeValueOption={(data) => changeValueOption(index, data, 0)}
                        submittingData={submittingData}
                        defaultImageSize={defaultImageSize}
                    />
                );
            } else {
                return (
                    <OptionString
                        index={index}
                        data={item}
                        changeValueOption={(data, limit) => changeValueOption(index, data, limit)}
                        submittingData={submittingData}
                    />
                );
            }
        }
    };

    const uploadFilesValid = () => {
        for (let option of availableOptions) {
            if (
                option['optionValue'] !== undefined &&
                typeof option['optionValue'] == 'object' &&
                (!isSupportedResolution(option['optionValue']) ||
                    !isSupportedFormat(option['optionValue']) ||
                    !isSupportedSize(option['optionValue']))
            ) {
                return false;
            }
        }
        return true;
    };
    const isSupportedFormat = (data: any) => {
        if (submittingData || data == null || data === undefined || data === '') {
            return true;
        }

        let supportedFileFormats = APIManager.getUploadFileFormats();
        let type = data['type'];

        for (let format of supportedFileFormats) {
            if (format === type) {
                return true;
            }
        }

        return false;
    };
    const isSupportedSize = (data: any) => {
        if (submittingData || data == null || data === undefined || data === '') {
            return true;
        }

        if (data.size !== undefined && data.size <= APIManager.getUploadFileSizeLimit()) {
            return true;
        }

        return false;
    };
    const isSupportedResolution = (data: any) => {
        if (submittingData || data == null || data === undefined || data === '') {
            return true;
        }

        if (
            data.width !== undefined &&
            data.height !== undefined &&
            (parseInt(data.width) > defaultMaxResolution || parseInt(data.height) > defaultMaxResolution)
        ) {
            return false;
        }

        return true;
    };
    const renderPreview = (link: string, isVideo: boolean) => {
        return (
            <div className="item-preview">
                {isVideo == true ? (
                    <video
                        key={link}
                        autoPlay
                        loop
                        muted
                        playsInline
                        // height={'100%'}
                        // width={'100%'}
                        // style={{ objectFit: 'contain' }}
                        className="video-properties"
                    >
                        <source src={link as string} />
                    </video>
                ) : (
                    <img src={link as string} alt={item['id']} className="image" />
                )}

                {!APIManager.isLoggedIn() && <img src={Watermark} alt={'Watermark'} className="watermark" />}
            </div>
        );
    };

    return (
        <Wrapper seo_title={seoTitle} seo_description={seoDesc}>
            <section className="section-customize">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2" />
                        <div className="col-lg-10 text-header">
                            <Link
                                title={I18n.t('MENU_CATALOG') as any}
                                onClick={() => navigate('/catalog')}
                                path={'/catalog'}
                            />
                            {item !== null && <span className="arrow">{'>'}</span>}
                            {item !== null && <span className="nonselectable">{item['name']}</span>}
                        </div>
                    </div>
                </div>
                <div className="divider" />
                {!loadingData && item !== null && parseInt(item.id) === parseInt(itemId) ? (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2" />
                            <div className="col-12 col-lg-4 preview-container ">
                                <div className="preview-title">
                                    {(I18n.t('CUST_PREVIEW') as string) + ' (' + videoSizes[size] + '):'}
                                </div>
                                <div>
                                    {itemPreview[0] !== undefined &&
                                        itemPreview[1] !== undefined &&
                                        renderPreview(itemPreview[0] as string, itemPreview[1] as boolean)}
                                </div>
                            </div>

                            <div className="col-12 col-lg-4 customize-container">
                                <ToastContainer
                                    position="bottom-right"
                                    hideProgressBar={true}
                                    newestOnTop={true}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss={false}
                                    pauseOnHover={false}
                                    theme="dark"
                                />

                                <div className="item-title">{item['name']}</div>
                                <div className="tags row">
                                    <div className="item-tag">{APIManager.filterLanguageData(item['group'].group)}</div>
                                    <div className="item-tag">
                                        {APIManager.filterLanguageData(item['category'].category)}
                                    </div>
                                    <div className="item-tag">
                                        {item.duration + ' ' + (I18n.t('CUST_DURATION_FULL') as string)}
                                    </div>
                                </div>
                                <div className="description">
                                    {APIManager.filterLanguageData(item).description &&
                                        htmr(APIManager.filterLanguageData(item).description)}
                                </div>

                                <div className="customize-options">
                                    {renderAvailableSizes()}
                                    {availableOptions.map((item: any, index: number) => {
                                        return renderOption(item, index);
                                    })}
                                </div>
                                {hasSubscription ? (
                                    <>
                                        {renderLimitReached ? (
                                            <div>
                                                <span className="limit-reached">
                                                    {I18n.t('RENDERS_LIMIT_REACHED') as string}
                                                </span>
                                            </div>
                                        ) : submittingData ? (
                                            <div className="get-button loading">
                                                <img
                                                    src={LoadingIcon}
                                                    className="loading-icon"
                                                    style={{
                                                        rotate: rotDeg + 'deg',
                                                    }}
                                                    alt=""
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="get-button"
                                                onClick={() => uploadFilesValid() && !submittingData && startOrder()}
                                            >
                                                {I18n.t('CUST_GET_BUTTON') as string}
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="sub-button" onClick={() => navigate('/pricing', { state: {} })}>
                                        {I18n.t('CUST_NO_SUB') as string}
                                    </div>
                                )}
                                <div className="feedback-text">{feedbackText !== '' && htmr(feedbackText)}</div>
                            </div>
                            <div className="col-lg-2" />
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}
            </section>
        </Wrapper>
    );
};
export default CustomizePage;
