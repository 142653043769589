import React, { FC } from 'react';
import Underline from '../../images/menu-selected.png';

interface Props {
    title?: string;
    button?: boolean;
    small?: boolean;
    onClick?: any;
    path?: string;
    style?: string;
    loader?: boolean;
    disabled?: boolean;
    activeLine?: boolean;
    children?: any;
}
const Link: FC<Props> = (props) => {
    const onPress = (e: any) => {
        e.preventDefault();

        if (props.onClick) {
            props.onClick();
        }
    };

    const prevDef = (e: any) => {
        e.preventDefault();
    };

    const getStyle = () => {
        let style = 'link';

        if (props.button) {
            if (props.disabled) {
                style = 'btn-disabled';
            } else {
                style = 'btn-default';
            }
            if (props.small) {
                style = 'btn-small';
            }
        }

        return style;
    };

    return (
        <a
            href={props.path ? props.path : ''}
            className={getStyle()}
            onClick={(event) => (props.disabled ? prevDef(event) : onPress(event))}
        >
            {props.title && <span className="paragraph-regular btn-text">{props.title}</span>}
            {props.activeLine && <img src={Underline} className="underlined" alt="menu-item-underline" />}
            {props.children && props.children}
        </a>
    );
};
export default Link;
