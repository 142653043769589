import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { logoutRequest, logoutReset, checkUserLimitReset } from '../../helpers/actions/redux-user-actions';
import '../../style/style.scss';
import I18n from '../../helpers/i18n';
import Menuclose from '../../images/menu-mobile-cross.png';
import LoadingIcon from '../../images/loading.png';

interface Props {
    open: boolean;
    Close: () => void;
}

const LogoutModal: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [screenOpen, setScreenOpen] = useState(false);
    const [rotating, setRotating] = useState(false);
    const [rotDeg, setRotDeg] = useState(0);

    const logoutState = useSelector((state: RootStateOrAny) => state.userReducers.logoutState);

    useEffect(() => {
        if (screenOpen) {
            if (logoutState === 'success' || logoutState === 'error') {
                setRotating(false);
                dispatch(logoutReset());
                navigate('/', { state: {} });
            }
        }
    }, [logoutState]);

    useEffect(() => {
        if (rotating) {
            setTimeout(() => {
                setRotDeg(rotDeg < 360 ? rotDeg + 45 : 45);
            }, 100);
        }
    }, [rotDeg, rotating]);

    useEffect(() => {
        if (props.open) {
            setScreenOpen(true);
        } else {
            setScreenOpen(false);
        }
    }, [props.open]);

    useEffect(() => {
        if (props.open && !screenOpen) {
            setRotating(false);
            props.Close();
        }
    }, [screenOpen]);

    const loggingOut = () => {
        dispatch(checkUserLimitReset());
        setRotating(true);
        dispatch(logoutRequest());
    };

    return (
        <div>
            <div className={`dark-background ${screenOpen && 'open'}`} onClick={() => setScreenOpen(!screenOpen)} />

            <div className={`modal-logout ${screenOpen && 'open'}`}>
                <img
                    src={Menuclose}
                    className="icon-close"
                    alt="menu"
                    onClick={() => setScreenOpen(!screenOpen)}
                    onKeyDown={(event) => {
                        if (event.code === 'Enter') {
                            setScreenOpen(!screenOpen);
                        }
                    }}
                    tabIndex={3}
                />
                <div className="container logout-block">
                    <div className="row justify-content-center">
                        <div className="title">{I18n.t('MENU_LOGOUT') as string}</div>
                        <div className="text">{I18n.t('LOGOUT_TITLE') as string}</div>
                        <div
                            className="button-logout"
                            onClick={() => loggingOut()}
                            onKeyDown={(event) => {
                                if (event.code === 'Enter') {
                                    loggingOut();
                                }
                            }}
                            tabIndex={1}
                        >
                            {rotating ? (
                                <img
                                    src={LoadingIcon}
                                    className="loading-icon"
                                    style={{
                                        rotate: rotDeg + 'deg',
                                    }}
                                    alt=""
                                />
                            ) : (
                                (I18n.t('MENU_LOGOUT') as string)
                            )}
                        </div>
                        <div>
                            <span
                                className="text-underlined"
                                onClick={() => setScreenOpen(!screenOpen)}
                                onKeyDown={(event) => {
                                    if (event.code === 'Enter') {
                                        setScreenOpen(!screenOpen);
                                    }
                                }}
                                tabIndex={2}
                            >
                                {I18n.t('LOGOUT_BUTTON') as string}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogoutModal;
