import React, { FC } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AutoScroll from './autoscroll';
import Analytics from './analytics';

import MainPage from '../screens/main/main';
import CatalogPage from '../screens/catalog/catalog';
import CustomizePage from '../screens/customize/customize';
import PricingPage from '../screens/pricing/pricing';
import TermsPage from '../screens/terms/terms';
import PrivacyPage from '../screens/privacy/privacy';
import ContactPage from '../screens/contact/contact';
import ProcessingPage from '../screens/processing/processing';
import AccountPage from '../screens/account/account';
import PageNotFound from '../screens/404/404';

const Navigation: FC = (props) => {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <AutoScroll />
            <Analytics />
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/catalog" element={<CatalogPage />} />
                <Route path="/catalog?custom=*" element={<CatalogPage />} />
                <Route path="/customize" element={<CustomizePage />} />
                <Route path="/processing" element={<ProcessingPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/order" element={<AccountPage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/reset-password" element={<MainPage />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Router>
    );
};

export default Navigation;
