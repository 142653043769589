export const dataConstants = {
    GET_CUSTOM_DATA: 'GET_CUSTOM_DATA',
    GET_CUSTOM_DATA_SUCCESS: 'GET_CUSTOM_DATA_SUCCESS',
    GET_CUSTOM_DATA_ERROR: 'GET_CUSTOM_DATA_ERROR',
    GET_CUSTOM_DATA_RESET: 'GET_CUSTOM_DATA_RESET',

    UPLOAD_DATA: 'UPLOAD_DATA',
    UPLOAD_DATA_SUCCESS: 'UPLOAD_DATA_SUCCESS',
    UPLOAD_DATA_ERROR: 'UPLOAD_DATA_ERROR',
    UPLOAD_DATA_RESET: 'UPLOAD_DATA_RESET',

    DELETE_FILE: 'DELETE_FILE',
    DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
    DELETE_FILE_ERROR: 'DELETE_FILE_ERROR',
    DELETE_FILE_RESET: 'DELETE_FILE_RESET',

    UPDATE_CUSTOM_DATA: 'UPDATE_CUSTOM_DATA',
    UPDATE_CUSTOM_DATA_SUCCESS: 'UPDATE_CUSTOM_DATA_SUCCESS',
    UPDATE_CUSTOM_DATA_ERROR: 'UPDATE_CUSTOM_DATA_ERROR',
    UPDATE_CUSTOM_DATA_RESET: 'UPDATE_CUSTOM_DATA_RESET',

    GET_CATEGORIES: 'GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',

    GET_GROUPS: 'GET_GROUPS',
    GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',

    CATALOG_FULL: 'CATALOG_FULL',
    CATALOG_FULL_SUCCESS: 'CATALOG_FULL_SUCCESS',
    CATALOG_FULL_ERROR: 'CATALOG_FULL_ERROR',
    CATALOG_FULL_RESET: 'CATALOG_FULL_RESET',

    CATALOG_SAMPLES: 'CATALOG_SAMPLES',
    CATALOG_SAMPLES_SUCCESS: 'CATALOG_SAMPLES_SUCCESS',
    CATALOG_SAMPLES_ERROR: 'CATALOG_SAMPLES_ERROR',
    CATALOG_SAMPLES_RESET: 'CATALOG_SAMPLES_RESET',

    FAVORITE_SAMPLES: 'FAVORITE_SAMPLES',
    FAVORITE_SAMPLES_SUCCESS: 'FAVORITE_SAMPLES_SUCCESS',
    FAVORITE_SAMPLES_ERROR: 'FAVORITE_SAMPLES_ERROR',
    FAVORITE_SAMPLES_RESET: 'FAVORITE_SAMPLES_RESET',

    CATALOG_ITEM: 'CATALOG_ITEM',
    CATALOG_ITEM_SUCCESS: 'CATALOG_ITEM_SUCCESS',
    CATALOG_ITEM_ERROR: 'CATALOG_ITEM_ERROR',
    CATALOG_ITEM_RESET: 'CATALOG_ITEM_RESET',
};
