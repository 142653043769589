import React, { FC } from 'react';
import DocumentMeta from 'react-document-meta';
import Header from '../header/header';
import Footer from '../footer/footer';
import I18n from '../../helpers/i18n';

interface Props {
    seo_title?: string;
    seo_description?: string;
    loggedIn?: boolean;
    children?: React.ReactNode;
    disableNavigation?: boolean;
    disableFooter?: boolean;
    disableHeader?: boolean;
    customLogo?: string;
}

const Wrapper: FC<Props> = (props) => {
    const meta = {
        title: props.seo_title ? props.seo_title : I18n.t('DEFAULT_SEO_TITLE'),
        description: props.seo_description ? props.seo_description : I18n.t('DEFAULT_SEO_DESCRIPTION'),
        canonical: window.location.href,
        meta: {
            charset: 'utf-8',
        },
    };

    return (
        <DocumentMeta {...meta}>
            {props.customLogo !== '' && !props.disableHeader && (
                <Header disableNavigation={props.disableNavigation} customLogo={props.customLogo} />
            )}
            {props.children}
            {!props.disableFooter && <Footer />}
        </DocumentMeta>
    );
};
export default Wrapper;
