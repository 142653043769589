import { createRoot } from 'react-dom/client';
import store from './helpers/redux-store';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

import Router from './navigation';
import 'bootstrap/dist/css/bootstrap.min.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement as any);

root.render(
    <Provider store={store}>
        <CookiesProvider>
            <Router />
        </CookiesProvider>
    </Provider>
);
