import React, { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import I18n from '../../../helpers/i18n';
import API from '../../../helpers/api';
import InfoIcon from '../../../images/info.svg';
import Menuclose from '../../../images/menu-mobile-cross.png';
import Tooltipicon from '../../../images/check-purple.png';

const APIManager = API.instance;

interface Props {
    index: number;
    data: any;
    changeValueOption: (data: any) => void;
    submittingData: boolean;
    defaultImageSize: number;
}

const OptionImage: FC<Props> = (props) => {
    const defaultMaxResolution = 3000;

    const loadImage = (file: any) =>
        new Promise((resolve, reject) => {
            try {
                const image = new Image();

                image.onload = function () {
                    resolve(this);
                };

                image.onerror = function () {
                    reject('Invalid image');
                };

                image.src = window.URL.createObjectURL(file);
            } catch (e) {
                reject(e);
            }
        });
    const onFileChangeHandler = (e: any) => {
        e.preventDefault();
        const selectedFile = e.target.files[0];
        selectedFile['value'] = e.target.value;

        loadImage(selectedFile)
            .then((result: any) => {
                selectedFile['width'] = String(result.width);
                selectedFile['height'] = String(result.height);
                selectedFile['preview'] = result.src;
                props.changeValueOption(selectedFile);
            })
            .catch((error) => {});
    };
    const fileChosen = () => {
        return (
            props.data['optionValue'] !== null &&
            props.data['optionValue'] !== undefined &&
            typeof props.data['optionValue'] !== 'string'
        );
    };
    const inputValue = () => {
        if (typeof props.data['optionValue'] == 'object' && props.data['optionValue'].value !== undefined) {
        } else {
            return [];
        }
    };

    let requestedWidth = props.defaultImageSize;
    let requestedHeight = props.defaultImageSize;
    let optionType = props.data['optionType'];

    if (optionType.includes('image') && optionType.includes('-') && optionType.includes('x')) {
        requestedWidth = parseInt(optionType.substring(optionType.indexOf('-') + 1, optionType.indexOf('x')));
        requestedHeight = parseInt(optionType.substring(optionType.indexOf('x') + 1, optionType.length));
    }

    const isSupportedFormat = (data: any) => {
        if (props.submittingData || data == null || data === undefined || data === '') {
            return true;
        }

        let supportedFileFormats = APIManager.getUploadFileFormats();
        let type = data['type'];

        for (let format of supportedFileFormats) {
            if (format === type) {
                return true;
            }
        }

        return false;
    };
    const isSupportedSize = (data: any) => {
        if (props.submittingData || data == null || data === undefined || data === '') {
            return true;
        }

        if (data.size !== undefined && data.size <= APIManager.getUploadFileSizeLimit()) {
            return true;
        }

        return false;
    };
    const isSupportedResolution = (data: any) => {
        if (props.submittingData || data == null || data === undefined || data === '') {
            return true;
        }

        if (
            data.width !== undefined &&
            data.height !== undefined &&
            (parseInt(data.width) > defaultMaxResolution || parseInt(data.height) > defaultMaxResolution)
        ) {
            return false;
        }

        return true;
    };

    return (
        <div className="option-image" key={props.index}>
            <div className="option-title">{props.data['optionText']}</div>
            <Tooltip id="limit-tooltip">
                <div>
                    <div className="tooltip-title">{I18n.t('CUST_UPLOAD_TOOLTIP_LINE1') as string}</div>
                    <div className="tooltip-option">
                        <img src={Tooltipicon} className="tooltip-icon" alt="sentence-icon" />
                        {I18n.t('CUST_UPLOAD_TOOLTIP_LINE2') as string}
                    </div>
                    <div className="tooltip-option">
                        <img src={Tooltipicon} className="tooltip-icon" alt="sentence-icon" />
                        {I18n.t('CUST_UPLOAD_TOOLTIP_LINE3') as string}
                    </div>
                    <div className="tooltip-option">
                        <img src={Tooltipicon} className="tooltip-icon" alt="sentence-icon" />
                        {I18n.t('CUST_UPLOAD_TOOLTIP_LINE4') as string}
                    </div>
                    <div className="tooltip-option-small">
                        {(I18n.t('CUST_UPLOAD_TOOLTIP_LINE5') as string) +
                            ' ' +
                            requestedWidth +
                            ' x ' +
                            requestedHeight}
                    </div>
                </div>
            </Tooltip>
            <div
                className={`input-field ${
                    (!isSupportedFormat(props.data['optionValue']) ||
                        !isSupportedSize(props.data['optionValue']) ||
                        !isSupportedResolution(props.data['optionValue'])) &&
                    'error'
                }`}
            >
                {fileChosen() ? (
                    <>
                        <img src={props.data['optionValue'].preview} className="upload-preview" alt="" />
                        <div className="upload-filename">{props.data['optionValue'].name}</div>
                        <img
                            src={Menuclose}
                            className="icon-close"
                            onClick={() => {
                                !props.submittingData && props.changeValueOption('');
                            }}
                            alt=""
                        />
                    </>
                ) : (
                    <>
                        <input
                            disabled={props.submittingData}
                            type="file"
                            value={inputValue()}
                            onChange={(e) => onFileChangeHandler(e)}
                        />
                        <img src={InfoIcon} className="info-icon" data-tooltip-id="limit-tooltip" alt="" />
                    </>
                )}
            </div>

            {!isSupportedFormat(props.data['optionValue']) && (
                <div className="error-text">{I18n.t('CUST_ERROR_FILEFORMAT') as string}</div>
            )}
            {!isSupportedSize(props.data['optionValue']) && (
                <div className="error-text">{I18n.t('CUST_ERROR_FILESIZE') as string}</div>
            )}
            {!isSupportedResolution(props.data['optionValue']) && (
                <div className="error-text">{I18n.t('CUST_ERROR_FILERES') as string}</div>
            )}
        </div>
    );
};
export default OptionImage;
