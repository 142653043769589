import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import '../../style/style.scss';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCategories, getGroups } from '../../helpers/actions/redux-data-actions';
import Logo from '../../images/logo-large-white.png';
import Link from '../../components/link';

interface Props {}
const APIManager = API.instance;

const Footer: FC<Props> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [catData, setCatData] = useState([]);
    const [groupData, setGroupData] = useState([]);

    const categoriesState = useSelector((state: RootStateOrAny) => state.dataReducers.categoriesState);
    const categoriesData = useSelector((state: RootStateOrAny) => state.dataReducers.categoriesData);
    const groupsState = useSelector((state: RootStateOrAny) => state.dataReducers.groupsState);
    const groupsData = useSelector((state: RootStateOrAny) => state.dataReducers.groupsData);

    useEffect(() => {
        if (categoriesState !== 'success' && categoriesData !== null && categoriesData.length === 0) {
            dispatch(getCategories());
        } else {
            setCatData(categoriesData);
        }
        if (groupsState !== 'success' && groupsData !== null && groupsData.length === 0) {
            dispatch(getGroups());
        }
        return () => {};
    }, []);

    useEffect(() => {
        if (categoriesState === 'success' && catData.length === 0) {
            setCatData(categoriesData);
        }
    }, [categoriesState]);

    useEffect(() => {
        if (groupsState === 'success' && groupData.length === 0) {
            setGroupData(groupsData);
        }
    }, [groupsState]);

    const isCurrentLocation = (data: string) => {
        if (location && location.pathname && location.pathname === data) {
            return true;
        } else {
            return false;
        }
    };

    const renderMenuItem = (location: string, name: string) => {
        return (
            <div className="menu-item">
                <Link
                    activeLine={isCurrentLocation(location) ? true : false}
                    title={name}
                    onClick={() => navigate(location)}
                    path={location}
                />
            </div>
        );
    };

    const translatedCatName = (data: any) => {
        let translatedData = APIManager.filterLanguageData(data);

        if (translatedData.category !== undefined) return translatedData.category;
        else return data.category;
    };

    const gatherGroupedCategoryData = (data: any) => {
        let combinatedData = [] as any;

        for (let category of catData) {
            if (data.category === category['category']) {
                combinatedData.push(category);
            }
        }
        return combinatedData;
    };

    const renderCategoryItems = () => {
        let renderString = [] as any;

        for (let i = 0; i < catData.length; i++) {
            // if (
            //     i + 3 < catData.length &&
            //     catData[i]['category'] === catData[i + 1]['category'] &&
            //     catData[i]['category'] === catData[i + 2]['category'] &&
            //     catData[i]['category'] === catData[i + 3]['category']
            // ) {
            //     i += 3;
            // } else if (
            //     i + 2 < catData.length &&
            //     catData[i]['category'] === catData[i + 1]['category'] &&
            //     catData[i]['category'] === catData[i + 2]['category']
            // ) {
            //     i += 2;
            // } else if (i + 1 < catData.length && catData[i]['category'] === catData[i + 1]['category']) {
            //     i++;
            // }

            let counter = 0;
            for (let j = 0; j + i + 1 < catData.length; j++) {
                if (catData[i + 1]['category'] === catData[i + 1 + j]['category']) {
                    counter++;
                } else {
                    break;
                }
            }
            i += counter;

            let catalogLink = '/catalog';
            let categoryIDs = '';
            let groupIDs = '';
            if (
                gatherGroupedCategoryData(catData[i]) !== null &&
                gatherGroupedCategoryData(catData[i]) !== undefined &&
                gatherGroupedCategoryData(catData[i]) !== '' &&
                gatherGroupedCategoryData(catData[i]).length > 0
            ) {
                for (let j = 0; j < gatherGroupedCategoryData(catData[i]).length; j++) {
                    if (
                        gatherGroupedCategoryData(catData[i])[j].status === 'Public' &&
                        gatherGroupedCategoryData(catData[i])[j].group.status === 'Public'
                    ) {
                        if (categoryIDs === '' && groupIDs === '') {
                            categoryIDs += gatherGroupedCategoryData(catData[i])[j].id;
                            groupIDs += gatherGroupedCategoryData(catData[i])[j].group.id;
                        } else {
                            categoryIDs += ',' + gatherGroupedCategoryData(catData[i])[j].id;
                            groupIDs += ',' + gatherGroupedCategoryData(catData[i])[j].group.id;
                        }
                    }
                }
            }
            catalogLink += '?group=' + groupIDs;
            catalogLink += '&category=' + categoryIDs;
            catalogLink += '&page=1';

            renderString.push(
                <div className="menu-item col-12 col-md-4 col-lg-3" key={i}>
                    <Link
                        title={translatedCatName(catData[i])}
                        onClick={() => navigate(catalogLink, { state: {} })}
                        path={catalogLink}
                    />
                </div>
            );
        }

        return renderString;
    };

    return (
        <footer>
            <section className="section_footer">
                <div className="container">
                    <div className="row footer-menu">
                        <div className="col-lg-2" />
                        <div className="col-md-4 col-lg-2 left-menu">
                            <div className="footer-logo">
                                <Link onClick={() => navigate('/', { state: {} })} path={'/'}>
                                    <img src={Logo} alt="Logo" />
                                </Link>
                            </div>
                            {renderMenuItem('/privacy', I18n.t('MENU_PRIVACY'))}
                            {renderMenuItem('/terms', I18n.t('MENU_TERMS'))}
                        </div>
                        <div className="col-md-8 col-lg-6 right-menu">
                            <div>
                                <div className="title">{I18n.t('MENU_CAT') as string}</div>
                                <div className="row d-flex">{renderCategoryItems()}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-menu-cr">{I18n.t('MENU_CR') as string}</div>
            </section>
        </footer>
    );
};

export default Footer;
