import { pageConstants } from '../constants/redux-page-constants';

import API from '../api';
const APIManager = API.instance;
const defaultErrorObject = {
    code: '404',
    message: 'No data found',
};

//HOME PAGE
export const getPageHome = () => {
    return (dispatch: any) => {
        dispatch(getPageHomeStart());

        APIManager.get('items/home?fields=*.*').then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getPageHomeFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    let data = APIManager.filterLanguageData(response.data);

                    data['background'] = response.data?.introbackground?.filename_disk;
                    data['promovideo'] = response.data?.promovideo?.filename_disk;
                    data['seo_title'] = data.seo_title;
                    data['seo_description'] = data.seo_description;

                    dispatch(getPageHomeSuccess(data));
                } else {
                    dispatch(getPageHomeFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getPageHomeStart = () => {
    return {
        type: pageConstants.GET_HOME_DATA,
    };
};
export const getPageHomeSuccess = (data: any) => {
    return {
        type: pageConstants.GET_HOME_DATA_SUCCESS,
        payload: data,
    };
};
export const getPageHomeFailure = (data: any) => {
    return {
        type: pageConstants.GET_HOME_DATA_ERROR,
        payload: data,
    };
};

//CONTACT PAGE
export const getPageContact = () => {
    return (dispatch: any) => {
        dispatch(getPageHomeStart());

        APIManager.get('items/contact?fields=*.*').then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getPageContactFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    let data = response.data;
                    //Fix incorrect data only for the contact page.
                    if (data.translations !== undefined) {
                        for (let i = 0; i < data.translations.length; i++) {
                            if (
                                data.translations[i].languages_id !== undefined &&
                                data.translations[i].languages_code == undefined
                            ) {
                                data.translations[i]['languages_code'] = data.translations[i].languages_id;
                            }
                        }
                    }
                    data = APIManager.filterLanguageData(data);
                    data['seo_title'] = data.seo_title;
                    data['seo_description'] = data.seo_description;

                    dispatch(getPageContactSuccess(data));
                } else {
                    dispatch(getPageContactFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getPageContactStart = () => {
    return {
        type: pageConstants.GET_CONTACT_DATA,
    };
};
export const getPageContactSuccess = (data: any) => {
    return {
        type: pageConstants.GET_CONTACT_DATA_SUCCESS,
        payload: data,
    };
};
export const getPageContactFailure = (data: any) => {
    return {
        type: pageConstants.GET_CONTACT_DATA_ERROR,
        payload: data,
    };
};

//PRIVACY PAGE
export const getPagePrivacy = () => {
    return (dispatch: any) => {
        dispatch(getPagePrivacyStart());

        APIManager.get('items/privacy?fields=*.*').then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getPagePrivacyFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    let data = APIManager.filterLanguageData(response.data);
                    data['seo_title'] = data.seo_title;
                    data['seo_description'] = data.seo_description;

                    dispatch(getPagePrivacySuccess(data));
                } else {
                    dispatch(getPagePrivacyFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getPagePrivacyStart = () => {
    return {
        type: pageConstants.GET_PRIVACY_DATA,
    };
};
export const getPagePrivacySuccess = (data: any) => {
    return {
        type: pageConstants.GET_PRIVACY_DATA_SUCCESS,
        payload: data,
    };
};
export const getPagePrivacyFailure = (data: any) => {
    return {
        type: pageConstants.GET_PRIVACY_DATA_ERROR,
        payload: data,
    };
};

// //TERMS PAGE
export const getPageTerms = () => {
    return (dispatch: any) => {
        dispatch(getPageTermsStart());

        APIManager.get('items/terms?fields=*.*').then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getPageTermsFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    let data = APIManager.filterLanguageData(response.data);
                    data['seo_title'] = data.seo_title;
                    data['seo_description'] = data.seo_description;

                    dispatch(getPageTermsSuccess(data));
                } else {
                    dispatch(getPageTermsFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getPageTermsStart = () => {
    return {
        type: pageConstants.GET_TERMS_DATA,
    };
};
export const getPageTermsSuccess = (data: any) => {
    return {
        type: pageConstants.GET_TERMS_DATA_SUCCESS,
        payload: data,
    };
};
export const getPageTermsFailure = (data: any) => {
    return {
        type: pageConstants.GET_TERMS_DATA_ERROR,
        payload: data,
    };
};

//PRICING PAGE
export const getPagePrice = () => {
    return (dispatch: any) => {
        dispatch(getPagePriceStart());

        APIManager.get('items/pricing?fields=*.*').then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getPagePriceFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    let data = APIManager.filterLanguageData(response.data);
                    data['seo_title'] = data.seo_title;
                    data['seo_description'] = data.seo_description;

                    dispatch(getPagePriceSuccess(data));
                } else {
                    dispatch(getPagePriceFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getPagePriceStart = () => {
    return {
        type: pageConstants.GET_PRICE_DATA,
    };
};
export const getPagePriceSuccess = (data: any) => {
    return {
        type: pageConstants.GET_PRICE_DATA_SUCCESS,
        payload: data,
    };
};
export const getPagePriceFailure = (data: any) => {
    return {
        type: pageConstants.GET_PRICE_DATA_ERROR,
        payload: data,
    };
};

//PROCESSING PAGE
export const getPageProcessing = () => {
    return (dispatch: any) => {
        dispatch(getPageProcessingStart());

        APIManager.get('items/processing?fields=*.*').then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getPageProcessingFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    let data = APIManager.filterLanguageData(response.data);
                    data['seo_title'] = data.seo_title;
                    data['seo_description'] = data.seo_description;

                    dispatch(getPageProcessingSuccess(data));
                } else {
                    dispatch(getPageProcessingFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getPageProcessingStart = () => {
    return {
        type: pageConstants.GET_PROCESSING_DATA,
    };
};
export const getPageProcessingSuccess = (data: any) => {
    return {
        type: pageConstants.GET_PROCESSING_DATA_SUCCESS,
        payload: data,
    };
};
export const getPageProcessingFailure = (data: any) => {
    return {
        type: pageConstants.GET_PROCESSING_DATA_ERROR,
        payload: data,
    };
};

//ACCOUNT PAGE
export const getPageAccount = () => {
    return (dispatch: any) => {
        dispatch(getPageAccountStart());

        APIManager.get('items/account?fields=*.*').then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getPageAccountFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    let data = APIManager.filterLanguageData(response.data);
                    data['seo_title'] = data.seo_title;
                    data['seo_description'] = data.seo_description;

                    dispatch(getPageAccountSuccess(data));
                } else {
                    dispatch(getPageAccountFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getPageAccountStart = () => {
    return {
        type: pageConstants.GET_ACCOUNT_DATA,
    };
};
export const getPageAccountSuccess = (data: any) => {
    return {
        type: pageConstants.GET_ACCOUNT_DATA_SUCCESS,
        payload: data,
    };
};
export const getPageAccountFailure = (data: any) => {
    return {
        type: pageConstants.GET_ACCOUNT_DATA_ERROR,
        payload: data,
    };
};

//GALLERY OVERVIEW PAGE
export const getPageGalleryOverview = () => {
    return (dispatch: any) => {
        dispatch(getPageGalleryOverviewStart());

        APIManager.get('items/gallery_overview?fields=*.*').then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getPageGalleryOverviewFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    let data = APIManager.filterLanguageData(response.data);
                    data['seo_title'] = data.seo_title;
                    data['seo_description'] = data.seo_description;

                    dispatch(getPageGalleryOverviewSuccess(data));
                } else {
                    dispatch(getPageGalleryOverviewFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getPageGalleryOverviewStart = () => {
    return {
        type: pageConstants.GET_GALLERY_OVERVIEW_DATA,
    };
};
export const getPageGalleryOverviewSuccess = (data: any) => {
    return {
        type: pageConstants.GET_GALLERY_OVERVIEW_DATA_SUCCESS,
        payload: data,
    };
};
export const getPageGalleryOverviewFailure = (data: any) => {
    return {
        type: pageConstants.GET_GALLERY_OVERVIEW_DATA_ERROR,
        payload: data,
    };
};

//GALLERY ITEM PAGE
export const getPageGalleryItem = () => {
    return (dispatch: any) => {
        dispatch(getPageGalleryItemStart());

        APIManager.get('items/gallery_item?fields=*.*').then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getPageGalleryItemFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    let data = APIManager.filterLanguageData(response.data);
                    data['seo_title'] = data.seo_title;
                    data['seo_description'] = data.seo_description;

                    dispatch(getPageGalleryItemSuccess(data));
                } else {
                    dispatch(getPageGalleryItemFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getPageGalleryItemStart = () => {
    return {
        type: pageConstants.GET_GALLERY_ITEM_DATA,
    };
};
export const getPageGalleryItemSuccess = (data: any) => {
    return {
        type: pageConstants.GET_GALLERY_ITEM_DATA_SUCCESS,
        payload: data,
    };
};
export const getPageGalleryItemFailure = (data: any) => {
    return {
        type: pageConstants.GET_GALLERY_ITEM_DATA_ERROR,
        payload: data,
    };
};
