import React, { FC } from 'react';
import '../../style/style.scss';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import OutsideClickHandler from 'react-outside-click-handler';

interface Props {
    show: boolean;
    value?: Date | undefined;
    onConfirm?: (newVal?: any) => void;
    onClose: () => void;
}

const DatePickerComponent: FC<Props> = (props) => {
    return props.show ? (
        <OutsideClickHandler
            onOutsideClick={() => {
                props.onClose();
            }}
        >
            <div className="date-picker-wrapper">
                <DayPicker
                    showOutsideDays
                    mode="single"
                    selected={props.value}
                    onSelect={(value) => props.onConfirm && props.onConfirm(value)}
                    modifiersClassNames={{
                        rdp: 'rdp',
                    }}
                />
            </div>
        </OutsideClickHandler>
    ) : null;
};

export default DatePickerComponent;
