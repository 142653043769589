import React, { FC, useState } from 'react';
import ColorPicker from '../../../components/colorpicker/colorpicker';

interface Props {
    index: number;
    data: any;
    changeValueOption: (data: any) => void;
    submittingData: boolean;
}

const OptionColor: FC<Props> = (props) => {
    const [showColorPicker, setShowColorPicker] = useState<string | null>(null);

    return (
        <div key={props.index}>
            <div className="option-color">
                <div
                    className="option-title"
                    onClick={() =>
                        !props.submittingData &&
                        setShowColorPicker(showColorPicker === props.data.optionName ? null : props.data.optionName)
                    }
                >
                    {props.data['optionText'] + ' (' + props.data['optionPlaceholder'] + '): '}
                    <div className="option-color-pointer">
                        <div
                            style={{
                                backgroundColor:
                                    props.data['optionValue'] !== undefined
                                        ? props.data['optionValue']
                                        : 'rgb(255, 255, 255)',
                                height: 30,
                                width: 30,
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                borderRadius: 7,
                            }}
                        />
                    </div>
                </div>
            </div>
            {showColorPicker && (
                <div className="option-color-picker">
                    <ColorPicker
                        show={showColorPicker === props.data.optionName ? true : false}
                        onClose={() => setShowColorPicker(null)}
                        value={
                            props.data['optionValue'] !== undefined ? props.data['optionValue'] : 'rgb(255, 255, 255)'
                        }
                        onConfirm={(value) => {
                            props.changeValueOption(value);
                        }}
                    />
                </div>
            )}
        </div>
    );
};
export default OptionColor;
