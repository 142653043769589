import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { getSubscriptions, getSubscriptionsReset } from '../../../helpers/actions/redux-stripe-actions';
import '../../../style/style.scss';
import I18n from '../../../helpers/i18n';
import API from '../../../helpers/api';
import moment from 'moment';
import Link from '../../../components/link';

const APIManager = API.instance;
interface Props {
    active: boolean;
    viewOrderDetails: (data: any) => void;
}

const AccountSubscription: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const getSubsState = useSelector((state: RootStateOrAny) => state.stripeReducers.getSubsState);
    const getSubsData = useSelector((state: RootStateOrAny) => state.stripeReducers.getSubsData);

    useEffect(() => {
        if (props.active) {
            setLoading(true);
            dispatch(getSubscriptions());
        } else {
            setLoading(false);
            dispatch(getSubscriptionsReset());
        }
    }, [props.active]);

    useEffect(() => {
        if (getSubsState === 'success') {
            setData(getSubsData);
            setLoading(false);
        }
    }, [getSubsState]);

    const getProductName = (data: any) => {
        if (data !== null && data !== undefined && data.product !== undefined) {
            let translated = APIManager.filterLanguageData(data['product']);
            return translated['product_name'];
        }
        return '';
    };

    const renderDate = (date: string) => {
        return moment.utc(date).format('MMMM DD, YYYY');
    };

    const renderItemRow = (data?: any) => {
        if (data === undefined) {
            return (
                <div className="row order-row">
                    <div className="col-2 order-item first hide-on-mobile">
                        {I18n.t('ACCOUNT_ITEM_PRODUCT') as string}
                    </div>
                    <div className="col-2 order-item order-status first hide-on-mobile">
                        {I18n.t('ACCOUNT_ITEM_STATUS') as string}
                    </div>
                    <div className="col-3 col-md-2 order-item first">{I18n.t('ACCOUNT_ITEM_START') as string}</div>
                    <div className="col-3 col-md-2 order-item first">{I18n.t('ACCOUNT_ITEM_END') as string}</div>

                    <div className="col-2 order-item first hide-on-mobile">{I18n.t('ACCOUNT_ITEM_AUTO') as string}</div>
                    <div className="col-6 col-md-2 first">{I18n.t('ACCOUNT_ITEM_ACTION') as string}</div>
                </div>
            );
        } else {
            return (
                <div className="row order-row">
                    <div className="col-2 order-item rest hide-on-mobile">{getProductName(data)}</div>
                    <div className="col-2 order-item order-status rest hide-on-mobile">{data.status}</div>
                    <div className="col-3 col-md-2 order-item rest">{renderDate(data.date_created) as string}</div>
                    <div className="col-3 col-md-2 order-item rest">{renderDate(data.expiration_time)}</div>

                    <div className="col-2 order-item rest hide-on-mobile">
                        {data.automatic_renewal ? (I18n.t('YES') as string) : (I18n.t('NO') as string)}
                    </div>
                    <div className="col-6 col-md-2">
                        <Link
                            onClick={() => props.viewOrderDetails(data.stripe_id_subscription)}
                            path={'/account?section=20&id=' + data.stripe_id_subscription}
                        >
                            <span className="view-button">{I18n.t('ACCOUNT_ITEM_VIEW') as string}</span>
                        </Link>
                    </div>
                </div>
            );
        }
    };

    return (
        <section className="section-account-subscription">
            <div className="container">
                <div className="col-12">
                    <div className="title">{I18n.t('ACCOUNT_SUB_TITLE') as string}</div>
                </div>
                {data.length > 0 ? (
                    <div>
                        {renderItemRow()}
                        {data.map((item: any, index: number) => {
                            return renderItemRow(item);
                        })}
                        <div className="divider" />
                    </div>
                ) : (
                    data.length === 0 &&
                    !loading && (
                        <div className="col-12">
                            <span className="no-result">{(I18n.t('ACCOUNT_NO_SUB') as string) + ' '}</span>
                            <span className="no-result-link" onClick={() => navigate('/pricing', {})}>
                                {I18n.t('ACCOUNT_NO_SUB_SUGGESTION') as string}
                            </span>
                        </div>
                    )
                )}
            </div>
        </section>
    );
};

export default AccountSubscription;
