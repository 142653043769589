import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../style/style.scss';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import htmr from 'htmr';
import Link from '../../components/link';
import Watermark from '../../images/watermark.png';
import WatermarkCustom from '../../images/watermark-custom.png';

const APIManager = API.instance;
interface Props {
    data: any;
    customView?: boolean;
    customAccentColor?: string;
}

const CatalogItem: FC<Props> = (props) => {
    interface item {
        id: string;
        name: string;
        description: string;
        group: string;
        groupID: number;
        duration: string;
        preview: any;
        isVideo: any;
    }

    const navigate = useNavigate();
    const [data, setData] = useState<item>();

    useEffect(() => {
        if (props.data !== null && props.data !== undefined) {
            let translatedData = APIManager.filterLanguageData(props.data);
            let previewData = APIManager.getCatalogPreviewURL(props.data, 1);

            setData({
                id: props.data?.id,
                group: props.data?.group?.group,
                groupID: props.data.group.id,
                name: translatedData?.name,
                description: translatedData?.description,
                duration: props.data?.duration,
                preview: previewData[0],
                isVideo: previewData[1],
            });
        }
    }, [props.data]);

    const checkGroupID = (id: number) => {
        let affectedGroups = process.env.REACT_APP_IMAGE_CONTAIN_SIZE_GROUPS;
        if (id !== undefined && affectedGroups && affectedGroups.length > 0) {
            for (let i = 1; i < 5; i++) {
                if (affectedGroups[i] && affectedGroups[i].toString() === id.toString()) {
                    return true;
                }
            }
        }
        return false;
    };

    const showPreview = (item: any) => {
        if (item.isVideo) {
            return (
                <video
                    height={296}
                    width={'100%'}
                    playsInline
                    autoPlay
                    muted
                    loop
                    style={{ objectFit: checkGroupID(item.groupID) ? 'contain' : 'fill' }}
                    src={item.preview}
                />
            );
        } else {
            return (
                <img
                    src={item.preview}
                    alt={'Preview' + item.id}
                    className={checkGroupID(item.groupID) ? 'image -contain ' : 'image'}
                />
            );
        }
    };

    return (
        <section className="section-catalog-item">
            {data !== undefined ? (
                <div className="catalog-item catalog-background">
                    <div className="item-picture-background">
                        {props.customView ? (
                            <div className="item-picture">
                                {data.preview !== '' && showPreview(data)}
                                {!APIManager.isLoggedIn() && (
                                    <>
                                        <img
                                            src={props.customView ? WatermarkCustom : Watermark}
                                            alt={'Watermark'}
                                            className="watermark
                                           "
                                        />
                                    </>
                                )}
                            </div>
                        ) : (
                            <Link
                                onClick={() =>
                                    navigate('/customize?id=' + data.id, {
                                        state: {},
                                    })
                                }
                                path={'/customize?id=' + data.id}
                            >
                                <div className="item-picture -cp">
                                    {data.preview !== '' && showPreview(data)}
                                    {!APIManager.isLoggedIn() && (
                                        <img src={Watermark} alt={'Watermark'} className="watermark" />
                                    )}
                                </div>
                            </Link>
                        )}
                    </div>
                    {props.customView && (
                        <div
                            className="custom-view-number"
                            style={{ backgroundColor: props.customAccentColor ? props.customAccentColor : '#cd2b38' }}
                        >
                            ID: {data.id}
                        </div>
                    )}
                    <div className="item-title">{data.name}</div>
                    <div className="item-description">
                        {data.description && htmr(data.description.replace('<p>', '').replace('</p>', ''))}
                    </div>
                    <div className="tags">
                        <div className="item-tag">{data.group}</div>
                        <div className="item-tag">{data.duration + ' ' + (I18n.t('CUST_DURATION_FULL') as string)}</div>
                    </div>
                    {!props.customView && (
                        <Link
                            button
                            small
                            title={I18n.t('CUST_ITEM_BUTTON') as any}
                            onClick={() =>
                                navigate('/customize?id=' + data.id, {
                                    state: {},
                                })
                            }
                            path={'/customize?id=' + data.id}
                        />
                    )}
                </div>
            ) : (
                <div className="catalog-item"></div>
            )}
        </section>
    );
};

export default CatalogItem;
