import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { getPageTerms } from '../../helpers/actions/redux-page-actions';
import Wrapper from '../../components/wrapper/wrapper';
import htmr from 'htmr';
import '../../style/style.scss';

interface Props {}

const TermsPage: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDesc, setSeoDesc] = useState('');
    const [data, setData] = useState({
        text: '',
        title: '',
    });
    const pageData = useSelector((state: RootStateOrAny) => state.pageReducers.termsData);

    useEffect(() => {
        dispatch(getPageTerms());
    }, []);

    useEffect(() => {
        if (Object.keys(pageData).length > 0) {
            setSeoTitle(pageData.seo_title);
            setSeoDesc(pageData.seo_description);
            setData(pageData);
        }
    }, [pageData]);

    return (
        <Wrapper seo_title={seoTitle} seo_description={seoDesc}>
            <section className="section-terms">
                <div className="text-title">{data['title'] && htmr(data['title'])}</div>
                <div className="divider" />
                <div className="text-paragraph">{data['text'] && htmr(data['text'])}</div>
            </section>
        </Wrapper>
    );
};
export default TermsPage;
