export const stripeConstants = {
    GET_PRODUCTS: 'GET_PRODUCTS',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_ERROR: 'GET_PRODUCTS_ERROR',
    GET_PRODUCTS_RESET: 'GET_PRODUCTS_RESET',

    CHECKOUT: 'CHECKOUT',
    CHECKOUT_SUCCESS: 'CHECKOUT_SUCCESS',
    CHECKOUT_ERROR: 'CHECKOUT_ERROR',
    CHECKOUT_RESET: 'CHECKOUT_RESET',

    STRIPE_DATA_URL: 'STRIPE_DATA_URL',
    STRIPE_DATA_URL_SUCCESS: 'STRIPE_DATA_URL_SUCCESS',
    STRIPE_DATA_URL_ERROR: 'STRIPE_DATA_URL_ERROR',
    STRIPE_DATA_URL_RESET: 'STRIPE_DATA_URL_RESET',

    AUTO_RENEWAL: 'AUTO_RENEWAL',
    AUTO_RENEWAL_SUCCESS: 'AUTO_RENEWAL_SUCCESS',
    AUTO_RENEWAL_ERROR: 'AUTO_RENEWAL_ERROR',
    AUTO_RENEWAL_RESET: 'AUTO_RENEWAL_RESET',

    GET_SUBS: 'GET_SUBS',
    GET_SUBS_SUCCESS: 'GET_SUBS_SUCCESS',
    GET_SUBS_ERROR: 'GET_SUBS_ERROR',
    GET_SUBS_RESET: 'GET_SUBS_RESET',

    HAS_VALID_SUB: 'HAS_VALID_SUB',
    HAS_VALID_SUB_SUCCESS: 'HAS_VALID_SUB_SUCCESS',
    HAS_VALID_SUB_ERROR: 'HAS_VALID_SUB_ERROR',
    HAS_VALID_SUB_RESET: 'HAS_VALID_SUB_RESET',
};
