export const pageConstants = {
    GET_HOME_DATA: 'GET_HOME_DATA',
    GET_HOME_DATA_SUCCESS: 'GET_HOME_DATA_SUCCESS',
    GET_HOME_DATA_ERROR: 'GET_HOME_DATA_ERROR',

    GET_CONTACT_DATA: 'GET_CONTACT_DATA',
    GET_CONTACT_DATA_SUCCESS: 'GET_CONTACT_DATA_SUCCESS',
    GET_CONTACT_DATA_ERROR: 'GET_CONTACT_DATA_ERROR',

    GET_PRIVACY_DATA: 'GET_PRIVACY_DATA',
    GET_PRIVACY_DATA_SUCCESS: 'GET_PRIVACY_DATA_SUCCESS',
    GET_PRIVACY_DATA_ERROR: 'GET_PRIVACY_DATA_ERROR',

    GET_TERMS_DATA: 'GET_TERMS_DATA',
    GET_TERMS_DATA_SUCCESS: 'GET_TERMS_DATA_SUCCESS',
    GET_TERMS_DATA_ERROR: 'GET_TERMS_DATA_ERROR',

    GET_PRICE_DATA: 'GET_PRICE_DATA',
    GET_PRICE_DATA_SUCCESS: 'GET_PRICE_DATA_SUCCESS',
    GET_PRICE_DATA_ERROR: 'GET_PRICE_DATA_ERROR',

    GET_PROCESSING_DATA: 'GET_PROCESSING_DATA',
    GET_PROCESSING_DATA_SUCCESS: 'GET_PROCESSING_DATA_SUCCESS',
    GET_PROCESSING_DATA_ERROR: 'GET_PROCESSING_DATA_ERROR',

    GET_ACCOUNT_DATA: 'GET_ACCOUNT_DATA',
    GET_ACCOUNT_DATA_SUCCESS: 'GET_ACCOUNT_DATA_SUCCESS',
    GET_ACCOUNT_DATA_ERROR: 'GET_ACCOUNT_DATA_ERROR',

    GET_GALLERY_OVERVIEW_DATA: 'GET_GALLERY_OVERVIEW_DATA',
    GET_GALLERY_OVERVIEW_DATA_SUCCESS: 'GET_GALLERY_OVERVIEW_DATA_SUCCESS',
    GET_GALLERY_OVERVIEW_DATA_ERROR: 'GET_GALLERY_OVERVIEW_DATA_ERROR',

    GET_GALLERY_ITEM_DATA: 'GET_GALLERY_ITEM_DATA',
    GET_GALLERY_ITEM_DATA_SUCCESS: 'GET_GALLERY_ITEM_DATA_SUCCESS',
    GET_GALLERY_ITEM_DATA_ERROR: 'GET_GALLERY_ITEM_DATA_ERROR',
};
