import { dataConstants } from '../constants/redux-data-constants';

const initialState = {
    customDataState: '',
    customDataError: '',
    customData: [],

    updateCustomDataState: '',
    updateCustomDataError: '',

    uploadState: '',
    uploadError: '',
    uploadData: [],

    deleteState: '',
    deleteError: '',

    categoriesState: '',
    categoriesData: [],

    groupsState: '',
    groupsData: [],

    catalogState: '',
    catalogError: '',
    catalogData: [],

    catalogSamplesState: '',
    catalogSamplesError: '',
    catalogSamplesData: [],

    favoriteSamplesState: '',
    favoriteSamplesError: '',
    favoriteSamplesData: [],

    catalogItemState: '',
    catalogItemError: '',
    catalogItemData: [],
};

const dataReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case dataConstants.DELETE_FILE:
            return Object.assign({}, state, {
                deleteState: 'in_progress',
            });
        case dataConstants.DELETE_FILE_SUCCESS:
            return Object.assign({}, state, {
                deleteState: 'success',
            });
        case dataConstants.DELETE_FILE_ERROR:
            return Object.assign({}, state, {
                deleteState: 'error',
                deleteError: action.payload,
            });
        case dataConstants.DELETE_FILE_RESET:
            return Object.assign({}, state, {
                deleteState: '',
                deleteError: '',
            });
        case dataConstants.UPDATE_CUSTOM_DATA:
            return Object.assign({}, state, {
                updateCustomDataState: 'in_progress',
            });
        case dataConstants.UPDATE_CUSTOM_DATA_SUCCESS:
            return Object.assign({}, state, {
                updateCustomDataState: 'success',
            });
        case dataConstants.UPDATE_CUSTOM_DATA_ERROR:
            return Object.assign({}, state, {
                updateCustomDataState: 'error',
                updateCustomDataError: action.payload,
            });
        case dataConstants.UPDATE_CUSTOM_DATA_RESET:
            return Object.assign({}, state, {
                updateCustomDataState: '',
                updateCustomDataError: '',
            });
        case dataConstants.GET_CUSTOM_DATA:
            return Object.assign({}, state, {
                customDataState: 'in_progress',
            });
        case dataConstants.GET_CUSTOM_DATA_SUCCESS:
            return Object.assign({}, state, {
                customDataState: 'success',
                customData: action.payload,
            });
        case dataConstants.GET_CUSTOM_DATA_ERROR:
            return Object.assign({}, state, {
                customDataState: 'error',
                customDataError: action.payload,
            });
        case dataConstants.GET_CUSTOM_DATA_RESET:
            return Object.assign({}, state, {
                customDataState: '',
                customDataError: '',
                customData: [],
            });

        case dataConstants.UPLOAD_DATA:
            return Object.assign({}, state, {
                uploadState: 'in_progress',
            });
        case dataConstants.UPLOAD_DATA_SUCCESS:
            return Object.assign({}, state, {
                uploadState: 'success',
                uploadData: action.payload,
            });
        case dataConstants.UPLOAD_DATA_ERROR:
            return Object.assign({}, state, {
                uploadState: 'error',
                uploadError: action.payload,
            });
        case dataConstants.UPLOAD_DATA_RESET:
            return Object.assign({}, state, {
                uploadState: '',
                uploadError: '',
                uploadData: [],
            });

        case dataConstants.GET_CATEGORIES:
            return Object.assign({}, state, {
                categoriesState: 'in_progress',
            });
        case dataConstants.GET_CATEGORIES_SUCCESS:
            return Object.assign({}, state, {
                categoriesState: 'success',
                categoriesData: action.payload,
            });

        case dataConstants.GET_GROUPS:
            return Object.assign({}, state, {
                groupsState: 'in_progress',
            });
        case dataConstants.GET_GROUPS_SUCCESS:
            return Object.assign({}, state, {
                groupsState: 'success',
                groupsData: action.payload,
            });

        case dataConstants.CATALOG_FULL:
            return Object.assign({}, state, {
                catalogState: 'in_progress',
            });
        case dataConstants.CATALOG_FULL_SUCCESS:
            return Object.assign({}, state, {
                catalogState: 'success',
                catalogData: action.payload,
            });
        case dataConstants.CATALOG_FULL_ERROR:
            return Object.assign({}, state, {
                catalogState: 'error',
                catalogError: action.payload,
            });
        case dataConstants.CATALOG_FULL_RESET:
            return Object.assign({}, state, {
                catalogState: '',
                catalogError: '',
                catalogData: [],
            });

        case dataConstants.CATALOG_SAMPLES:
            return Object.assign({}, state, {
                catalogSamplesState: 'in_progress',
            });
        case dataConstants.CATALOG_SAMPLES_SUCCESS:
            return Object.assign({}, state, {
                catalogSamplesState: 'success',
                catalogSamplesData: action.payload,
            });
        case dataConstants.CATALOG_SAMPLES_ERROR:
            return Object.assign({}, state, {
                catalogSamplesState: 'error',
                catalogSamplesError: action.payload,
            });
        case dataConstants.CATALOG_SAMPLES_RESET:
            return Object.assign({}, state, {
                catalogSamplesState: '',
                catalogSamplesError: '',
                catalogSamplesData: [],
            });

        case dataConstants.FAVORITE_SAMPLES:
            return Object.assign({}, state, {
                favoriteSamplesState: 'in_progress',
            });
        case dataConstants.FAVORITE_SAMPLES_SUCCESS:
            return Object.assign({}, state, {
                favoriteSamplesState: 'success',
                favoriteSamplesData: action.payload,
            });
        case dataConstants.FAVORITE_SAMPLES_ERROR:
            return Object.assign({}, state, {
                favoriteSamplesState: 'error',
                favoriteSamplesError: action.payload,
            });
        case dataConstants.FAVORITE_SAMPLES_RESET:
            return Object.assign({}, state, {
                favoriteSamplesState: '',
                favoriteSamplesError: '',
                favoriteSamplesData: [],
            });

        case dataConstants.CATALOG_ITEM:
            return Object.assign({}, state, {
                catalogItemState: 'in_progress',
            });
        case dataConstants.CATALOG_ITEM_SUCCESS:
            return Object.assign({}, state, {
                catalogItemState: 'success',
                catalogItemData: action.payload,
            });
        case dataConstants.CATALOG_ITEM_ERROR:
            return Object.assign({}, state, {
                catalogItemState: 'error',
                catalogItemError: action.payload,
            });
        case dataConstants.CATALOG_ITEM_RESET:
            return Object.assign({}, state, {
                catalogItemState: '',
                catalogItemError: '',
                catalogItemData: [],
            });

        default:
            return state;
    }
};

export default dataReducers;
