import React, { FC, useState } from 'react';
import moment from 'moment';
import DatePicker from '../../../components/datepicker/datepicker';
import datePickerIcon from '../../../images/date.svg';

interface Props {
    index: number;
    data: any;
    changeValueOption: (data: any) => void;
    submittingData: boolean;
}

const OptionDate: FC<Props> = (props) => {
    const [showDatePicker, setShowDatePicker] = useState<string | null>(null);

    var usedDate =
        props.data['optionValue'] !== undefined
            ? moment(props.data['optionValue'], 'DD-MM-YYYY').toDate()
            : moment().toDate();

    return (
        <div className="option-date" key={props.index}>
            <div className="option-title" onClick={() => setShowDatePicker(props.data.optionName)}>
                {props.data['optionText']}
                <span
                    className="option-selected-date"
                    onClick={() => !props.submittingData && setShowDatePicker(props.data.optionName)}
                >
                    {props.data['optionValue'] !== undefined
                        ? props.data['optionValue']
                        : props.data['optionPlaceholder']}
                    <img src={datePickerIcon} className="datepicker-icon" alt="" />
                </span>
            </div>
            {showDatePicker === props.data.optionName && (
                <div className="date-picker-container">
                    <DatePicker
                        show={showDatePicker === props.data.optionName ? true : false}
                        onClose={() => setShowDatePicker(null)}
                        value={usedDate as Date}
                        onConfirm={(value) => {
                            props.changeValueOption(moment(value).format('DD-MM-YYYY').toString());
                            setShowDatePicker(null);
                        }}
                    />
                </div>
            )}
        </div>
    );
};
export default OptionDate;
