import React, { FC } from 'react';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from '../../helpers/i18n';
import '../../style/style.scss';
import Underline from '../../images/menu-selected.png';

interface Props {}

const PageNotFound: FC<Props> = (props) => {
    return (
        <Wrapper>
            <section className="section-404">
                <div className="container">
                    <div>
                        <div className="first-line">{I18n.t('404_LINE_2') as string}</div>
                        <div>
                            <span className="second-line">
                                {I18n.t('404_LINE_1') as string}
                                <img src={Underline} className="underlined" alt="added-link-img" />
                            </span>
                        </div>
                        <div className="third-line">{I18n.t('404_LINE_3') as string}</div>
                    </div>
                    <div>
                        <div className="fourth-line">{I18n.t('404_LINE_4') as string}</div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
};
export default PageNotFound;
