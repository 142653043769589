import { stripeConstants } from '../constants/redux-stripe-constants';

import API from '../api';
const APIManager = API.instance;
const defaultErrorObject = {
    code: '404',
    message: 'No data found',
};

//GET ALL PRODUCTS
export const getProducts = (id?: string) => {
    return (dispatch: any) => {
        dispatch(getProductsStart());

        let link = 'items/products?fields=*.*&filter[active]=true&sort=price';

        if (id !== null && id !== undefined && id !== '') {
            link += '&filter[id][_eq]=' + id;
        }

        APIManager.get(link).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getProductsFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data.length > 0) {
                    dispatch(getProductsSuccess(response.data));
                } else {
                    dispatch(getProductsFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getProductsStart = () => {
    return {
        type: stripeConstants.GET_PRODUCTS,
    };
};
export const getProductsSuccess = (data: any) => {
    return {
        type: stripeConstants.GET_PRODUCTS_SUCCESS,
        payload: data,
    };
};
export const getProductsFailure = (data: any) => {
    return {
        type: stripeConstants.GET_PRODUCTS_ERROR,
        payload: data,
    };
};
export const getProductsReset = () => {
    return {
        type: stripeConstants.GET_PRODUCTS_RESET,
    };
};

//GET PAYMENT STRIPE URL
export const postCheckout = (data: any) => {
    return (dispatch: any) => {
        dispatch(checkoutStart());

        APIManager.post('checkout?access_token=' + APIManager.getCookie('access_token'), data).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(checkoutFailure(APIManager.filterErrorData(response)));
            } else {
                if (response.url !== null && response.url !== undefined) {
                    dispatch(checkoutSuccess(response.url));
                } else {
                    dispatch(checkoutFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const checkoutStart = () => {
    return {
        type: stripeConstants.CHECKOUT,
    };
};
export const checkoutSuccess = (data: any) => {
    return {
        type: stripeConstants.CHECKOUT_SUCCESS,
        payload: data,
    };
};
export const checkoutFailure = (data: any) => {
    return {
        type: stripeConstants.CHECKOUT_ERROR,
        payload: data,
    };
};
export const checkoutReset = () => {
    return {
        type: stripeConstants.CHECKOUT_RESET,
    };
};

//GET STRIPE URL
export const postStripeURL = (id: string) => {
    return (dispatch: any) => {
        dispatch(postStripeURLStart());

        APIManager.post('stripe-url-data?access_token=' + APIManager.getCookie('access_token'), {
            subscription_id: id,
        }).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(postStripeURLFailure({ message: response.message, code: response.code }));
            } else {
                if (response.url !== null && response.url !== undefined) {
                    dispatch(postStripeURLSuccess(response.url));
                } else {
                    dispatch(postStripeURLFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const postStripeURLStart = () => {
    return {
        type: stripeConstants.STRIPE_DATA_URL,
    };
};
export const postStripeURLSuccess = (data: any) => {
    return {
        type: stripeConstants.STRIPE_DATA_URL_SUCCESS,
        payload: data,
    };
};
export const postStripeURLFailure = (data: any) => {
    return {
        type: stripeConstants.STRIPE_DATA_URL_ERROR,
        payload: data,
    };
};
export const postStripeURLReset = () => {
    return {
        type: stripeConstants.STRIPE_DATA_URL_RESET,
    };
};

//SET AUTO RENEWAL
export const setAutoRenewal = (id: string, status: boolean) => {
    return (dispatch: any) => {
        dispatch(setAutoRenewalStart());

        APIManager.post('subscriptions/autorenewal?access_token=' + APIManager.getCookie('access_token'), {
            subscription_id: id,
            status: status,
        }).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(setAutoRenewalFailure({ message: response.message, code: response.code }));
            } else {
                dispatch(setAutoRenewalSuccess());
            }
        });
    };
};
export const setAutoRenewalStart = () => {
    return {
        type: stripeConstants.AUTO_RENEWAL,
    };
};
export const setAutoRenewalSuccess = () => {
    return {
        type: stripeConstants.AUTO_RENEWAL_SUCCESS,
    };
};
export const setAutoRenewalFailure = (data: any) => {
    return {
        type: stripeConstants.AUTO_RENEWAL_ERROR,
        payload: data,
    };
};
export const setAutoRenewalReset = () => {
    return {
        type: stripeConstants.AUTO_RENEWAL_RESET,
    };
};

//GET SUBSCRIPTION(S)
export const getSubscriptions = (id?: string) => {
    return (dispatch: any) => {
        dispatch(getSubscriptionsStart());

        let link =
            'items/subscriptions?filter[user][_eq]=' +
            APIManager.getCookie('user_id') +
            '&access_token=' +
            APIManager.getCookie('access_token') +
            '&fields=*.*.*';

        if (id !== null && id !== undefined && id !== '') {
            link += '&filter[stripe_id_subscription][_eq]=' + id;
        }

        APIManager.get(link).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getSubscriptionsFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    dispatch(getSubscriptionsSuccess(response.data));
                } else {
                    dispatch(getSubscriptionsFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getSubscriptionsStart = () => {
    return {
        type: stripeConstants.GET_SUBS,
    };
};
export const getSubscriptionsSuccess = (data: any) => {
    return {
        type: stripeConstants.GET_SUBS_SUCCESS,
        payload: data,
    };
};
export const getSubscriptionsFailure = (data: any) => {
    return {
        type: stripeConstants.GET_SUBS_ERROR,
        payload: data,
    };
};
export const getSubscriptionsReset = () => {
    return {
        type: stripeConstants.GET_SUBS_RESET,
    };
};

//CHECK FOR VALID SUB
export const hasValidSubscription = () => {
    return (dispatch: any) => {
        dispatch(hasValidSubscriptionStart());

        let link =
            'items/subscriptions' +
            '?sort=-expiration_time' +
            '&fields[]=expiration_time,product.*' +
            '&filter[status]=active' +
            '&filter[user][_eq]=' +
            APIManager.getCookie('user_id') +
            '&access_token=' +
            APIManager.getCookie('access_token');

        APIManager.get(link).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(hasValidSubscriptionFailure(APIManager.filterErrorData(response)));
            } else {
                if (
                    response.data !== null &&
                    response.data !== undefined &&
                    response.data.length > 0 &&
                    response.data[0] !== null &&
                    response.data[0] !== undefined
                ) {
                    dispatch(
                        hasValidSubscriptionSuccess({
                            id: response.data[0].product.id,
                            status: !APIManager.expiredDate(response.data[0].expiration_time),
                        })
                    );
                } else {
                    dispatch(hasValidSubscriptionFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const hasValidSubscriptionStart = () => {
    return {
        type: stripeConstants.HAS_VALID_SUB,
    };
};
export const hasValidSubscriptionSuccess = (data: any) => {
    return {
        type: stripeConstants.HAS_VALID_SUB_SUCCESS,
        payload: data,
    };
};
export const hasValidSubscriptionFailure = (data: any) => {
    return {
        type: stripeConstants.HAS_VALID_SUB_ERROR,
        payload: data,
    };
};
export const hasValidSubscriptionReset = () => {
    return {
        type: stripeConstants.HAS_VALID_SUB_RESET,
    };
};
