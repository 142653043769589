import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import {
    getUserInfo,
    getUserInfoReset,
    updateUserInfo,
    updateUserInfoReset,
} from '../../../helpers/actions/redux-user-actions';

import '../../../style/style.scss';
import I18n from '../../../helpers/i18n';
import API from '../../../helpers/api';

import LoadingIcon from '../../../images/loading.png';
import PasswordVisibleIcon from '../../../images/visible.png';
import PasswordInvisibleIcon from '../../../images/invisible.png';

const APIManager = API.instance;
interface Props {
    active: boolean;
}

const AccountDetails: FC<Props> = (props) => {
    const dispatch = useDispatch();

    const [savingChanges, setSavingChanges] = useState(false);
    const [rotDeg, setRotDeg] = useState(0);

    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');

    const [passwordNew, setPasswordNew] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [passwordNewVisible, setPasswordNewVisible] = useState(false);
    const [passwordRepeatVisible, setPasswordRepeatVisible] = useState(false);

    const [messageDetails, setMessageDetails] = useState('');
    const [messageDetailsError, setMessageDetailsError] = useState(false);
    const [messagePassword, setMessagePassword] = useState('');
    const [messagePasswordError, setMessagePasswordError] = useState(false);

    const [errorFirstname, setErrorFirstname] = useState(false);
    const [errorLastname, setErrorLastname] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPasswordNew, setErrorPasswordNew] = useState(false);
    const [errorPasswordRepeat, setErrorPasswordRepeat] = useState(false);

    const getUserState = useSelector((state: RootStateOrAny) => state.userReducers.getUserState);
    const userData = useSelector((state: RootStateOrAny) => state.userReducers.userData);

    const updateUserState = useSelector((state: RootStateOrAny) => state.userReducers.updateUserState);

    const toTop = () => {
        window.scrollTo({
            top: 100,
            behavior: 'instant',
        } as any);
    };

    useEffect(() => {
        if (props.active && APIManager.isLoggedIn()) {
            dispatch(getUserInfo());
        } else {
            dispatch(getUserInfoReset());
        }
    }, [props.active]);

    useEffect(() => {
        if (getUserState === 'success') {
            setEmail(userData.email);
            setFirstname(userData.first_name);
            setLastname(userData.last_name);
        } else if (getUserState === 'error') {
            //error
        }
    }, [getUserState]);

    useEffect(() => {
        if (savingChanges) {
            if (updateUserState === 'success') {
                setSavingChanges(false);
                dispatch(updateUserInfoReset());
                dispatch(getUserInfo());
                toTop();
                setMessageDetails(I18n.t('ACCOUNT_CHANGES_SAVED'));
            } else if (updateUserState === 'error') {
                setSavingChanges(false);
                dispatch(updateUserInfoReset());
                toTop();
                setMessageDetails(I18n.t('ERROR'));
                setMessageDetailsError(true);
            }
        }
    }, [updateUserState]);

    useEffect(() => {
        if (savingChanges) {
            setTimeout(() => {
                setRotDeg(rotDeg < 360 ? rotDeg + 45 : 45);
            }, 100);
        }
    }, [rotDeg, savingChanges]);

    useEffect(() => {
        setMessageDetails('');
        setMessageDetailsError(false);

        if (firstname !== '') {
            setErrorFirstname(false);
        }
        if (lastname !== '') {
            setErrorLastname(false);
        }
        if (email !== '') {
            setErrorEmail(false);
        }
    }, [firstname, lastname, email]);

    useEffect(() => {
        setMessagePassword('');
        setMessagePasswordError(false);

        if (passwordNew !== '') {
            setErrorPasswordNew(false);
        }
        if (passwordRepeat !== '') {
            setErrorPasswordRepeat(false);
        }
    }, [passwordNew, passwordRepeat]);

    const saveChanges = () => {
        let issueFoundDetails = false;
        let issueFoundPasswords = false;

        if (firstname === '') {
            setMessageDetails(I18n.t('FIELD_ERROR'));
            setErrorFirstname(true);
            issueFoundDetails = true;
        }
        if (lastname === '') {
            setMessageDetails(I18n.t('FIELD_ERROR'));
            setErrorLastname(true);
            issueFoundDetails = true;
        }
        if (!APIManager.isValidEmail(email)) {
            setMessageDetails(I18n.t('INVALID_FORMAT_EMAIL'));
            setErrorEmail(true);
            issueFoundDetails = true;
        }

        if (passwordNew !== '' && passwordNew !== null && passwordNew !== undefined) {
            if (!APIManager.isValidPassword(passwordNew)) {
                setMessagePassword(I18n.t('INVALID_FORMAT_PASSWORD'));
                setErrorPasswordNew(true);
                issueFoundPasswords = true;
            }
            if (!APIManager.isValidPassword(passwordRepeat)) {
                setMessagePassword(I18n.t('INVALID_FORMAT_PASSWORD'));
                setErrorPasswordRepeat(true);
                issueFoundPasswords = true;
            }
            if (passwordNew !== passwordRepeat) {
                setMessagePassword(I18n.t('ACCOUNT_PW_MISMATCH'));
                setErrorPasswordRepeat(true);
                issueFoundPasswords = true;
            }
        }

        if (issueFoundDetails) {
            toTop();
            setMessageDetailsError(true);
        }
        if (issueFoundPasswords) {
            setMessagePasswordError(true);
        }

        if (!issueFoundDetails && !issueFoundPasswords) {
            setSavingChanges(true);
            dispatch(getUserInfoReset());

            if (passwordNew !== '' && passwordNew !== null && passwordNew !== undefined) {
                dispatch(
                    updateUserInfo({
                        last_name: lastname,
                        first_name: firstname,
                        email: email,
                        password: passwordNew,
                    })
                );
            } else {
                dispatch(
                    updateUserInfo({
                        last_name: lastname,
                        first_name: firstname,
                        email: email,
                    })
                );
            }
        }
    };

    return (
        <section className="section-account-details">
            <div className="container">
                <div className="col-12">
                    <div className="title">{I18n.t('MENU_ACCOUNT_DETAILS') as string}</div>
                    {messageDetails !== '' && (
                        <div className={`message-details ${messageDetailsError && 'error'}`}>{messageDetails}</div>
                    )}
                </div>
                <div className="row details-container">
                    <div className="col-12 col-md-6">
                        <div className="input-text">{I18n.t('REGISTER_FIRSTNAME') as string}</div>
                        <div className={`input-field ${errorFirstname && 'error'}`}>
                            <input
                                name="input-firstname"
                                type="text"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="input-text">{I18n.t('REGISTER_LASTNAME') as string}</div>
                        <div className={`input-field ${errorLastname && 'error'}`}>
                            <input
                                name="input-lastname"
                                type="text"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-6">
                        <div className="input-text">{I18n.t('LOGIN_NAME') as string}</div>
                        <div className={`input-field ${errorEmail && 'error'}`}>
                            <input
                                name="input-email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="divider" />
                {messagePassword !== '' && (
                    <div className={`message-details ${messagePasswordError && 'error'}`}>{messagePassword}</div>
                )}
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-6">
                        <div className="input-text">{I18n.t('ACCOUNT_NEW_PW') as string}</div>
                        <div className={`input-field ${errorPasswordNew && 'error'}`}>
                            <input
                                name="input-password-new"
                                type={passwordNewVisible ? 'text' : 'password'}
                                value={passwordNew}
                                onChange={(e) => setPasswordNew(e.target.value)}
                                autoComplete="new-password"
                            />
                            <img
                                src={passwordNewVisible ? PasswordVisibleIcon : PasswordInvisibleIcon}
                                className={`icon-password ${passwordNewVisible && 'visible'}`}
                                alt="menu"
                                onClick={() => setPasswordNewVisible(!passwordNewVisible)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-6">
                        <div className="input-text">{I18n.t('ACCOUNT_PW_CONFIRM') as string}</div>
                        <div className={`input-field ${errorPasswordRepeat && 'error'}`}>
                            <input
                                name="input-password-repeat"
                                type={passwordRepeatVisible ? 'text' : 'password'}
                                value={passwordRepeat}
                                onChange={(e) => setPasswordRepeat(e.target.value)}
                                autoComplete="new-password"
                            />
                            <img
                                src={passwordRepeatVisible ? PasswordVisibleIcon : PasswordInvisibleIcon}
                                className={`icon-password ${passwordRepeatVisible && 'visible'}`}
                                alt="menu"
                                onClick={() => setPasswordRepeatVisible(!passwordRepeatVisible)}
                            />
                        </div>
                    </div>
                </div>
                <div className="divider" />
                <div className="col-5">
                    <div className="save-button" onClick={() => saveChanges()}>
                        {savingChanges ? (
                            <img
                                src={LoadingIcon}
                                className="loading-icon"
                                style={{
                                    rotate: rotDeg + 'deg',
                                }}
                                alt=""
                            />
                        ) : (
                            (I18n.t('BUTTON_SAVE') as string)
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AccountDetails;
