import { dataConstants } from '../constants/redux-data-constants';

import API from '../api';
const APIManager = API.instance;
const defaultErrorObject = {
    code: '404',
    message: 'No data found',
};

//UPLOAD CUSTOM LINK DATA
export const updateCustomData = (data: any, id: boolean | null) => {
    return (dispatch: any) => {
        dispatch(getCustomDataStart());

        if (id !== null) {
            //update
            let link = `items/custom_links/${id}?access_token=` + APIManager.getCookie('access_token');
            APIManager.patch(link, data).then((response) => {
                if (response.message !== null && response.message !== undefined) {
                    dispatch(updateCustomDataFailure(APIManager.filterErrorData(response)));
                } else {
                    if (response.data !== null && response.data !== undefined) {
                        dispatch(updateCustomDataSuccess(response.data));
                    } else {
                        dispatch(updateCustomDataFailure(defaultErrorObject));
                    }
                }
            });
        } else {
            //first/new upload
            let link = 'items/custom_links?access_token=' + APIManager.getCookie('access_token');
            APIManager.post(link, data).then((response) => {
                if (response.message !== null && response.message !== undefined) {
                    dispatch(updateCustomDataFailure(APIManager.filterErrorData(response)));
                } else {
                    dispatch(updateCustomDataSuccess(response.data));
                }
            });
        }
    };
};
export const updateCustomDataStart = () => {
    return {
        type: dataConstants.UPDATE_CUSTOM_DATA,
    };
};
export const updateCustomDataSuccess = (data: any) => {
    return {
        type: dataConstants.UPDATE_CUSTOM_DATA_SUCCESS,
        payload: data,
    };
};
export const updateCustomDataFailure = (data: any) => {
    return {
        type: dataConstants.UPDATE_CUSTOM_DATA_ERROR,
        payload: data,
    };
};
export const updateCustomDataReset = () => {
    return {
        type: dataConstants.UPDATE_CUSTOM_DATA_RESET,
    };
};

//GET CUSTOM CATALOG DESIGN DATA
export const getCustomData = (userID: any) => {
    return (dispatch: any) => {
        dispatch(getCustomDataStart());

        let link = 'items/custom_links' + '?filter[user][_eq]=' + userID;
        APIManager.get(link).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getCustomDataFailure(APIManager.filterErrorData(response)));
            } else {
                dispatch(getCustomDataSuccess(response.data));
            }
        });
    };
};
export const getCustomDataStart = () => {
    return {
        type: dataConstants.GET_CUSTOM_DATA,
    };
};
export const getCustomDataSuccess = (data: any) => {
    return {
        type: dataConstants.GET_CUSTOM_DATA_SUCCESS,
        payload: data,
    };
};
export const getCustomDataFailure = (data: any) => {
    return {
        type: dataConstants.GET_CUSTOM_DATA_ERROR,
        payload: data,
    };
};
export const getCustomDataReset = () => {
    return {
        type: dataConstants.GET_CUSTOM_DATA_RESET,
    };
};

//UPLOAD FILES
export const uploadRequest = (data: any) => {
    return (dispatch: any) => {
        dispatch(uploadDataStart());

        let link = 'files?access_token=' + APIManager.getCookie('access_token');
        APIManager.post(link, data).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(uploadDataFailure(APIManager.filterErrorData(response)));
            } else {
                dispatch(uploadDataSuccess(response.data));
            }
        });
    };
};
export const uploadDataStart = () => {
    return {
        type: dataConstants.UPLOAD_DATA,
    };
};
export const uploadDataSuccess = (data: any) => {
    return {
        type: dataConstants.UPLOAD_DATA_SUCCESS,
        payload: data,
    };
};
export const uploadDataFailure = (data: any) => {
    return {
        type: dataConstants.UPLOAD_DATA_ERROR,
        payload: data,
    };
};
export const uploadDataReset = () => {
    return {
        type: dataConstants.UPLOAD_DATA_RESET,
    };
};

//DELETE FILE
export const deleteFileRequest = (fileID: any) => {
    return (dispatch: any) => {
        dispatch(deleteFileStart());

        let link = 'files/' + fileID + '?access_token=' + APIManager.getCookie('access_token');
        APIManager.delete(link).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(deleteFileFailure(APIManager.filterErrorData(response)));
            } else {
                dispatch(deleteFileSuccess(response.data));
            }
        });
    };
};
export const deleteFileStart = () => {
    return {
        type: dataConstants.DELETE_FILE,
    };
};
export const deleteFileSuccess = (data: any) => {
    return {
        type: dataConstants.DELETE_FILE_SUCCESS,
        payload: data,
    };
};
export const deleteFileFailure = (data: any) => {
    return {
        type: dataConstants.DELETE_FILE_ERROR,
        payload: data,
    };
};
export const deleteFileReset = () => {
    return {
        type: dataConstants.DELETE_FILE_RESET,
    };
};

//GET ALL AVAILABLE CATEGORIES
export const getCategories = () => {
    return (dispatch: any) => {
        dispatch(getCategoriesStart());

        APIManager.get(
            'items/categories?limit=-1&fields=*.*&filter[status][_eq]=Public&filter[group][status][_eq]=Public&sort=category'
        ).then((response) => {
            if (response.data !== null && response.data !== undefined) {
                dispatch(getCategoriesSuccess(response.data));
            }
        });
    };
};
export const getCategoriesStart = () => {
    return {
        type: dataConstants.GET_CATEGORIES,
    };
};
export const getCategoriesSuccess = (data: any) => {
    return {
        type: dataConstants.GET_CATEGORIES_SUCCESS,
        payload: data,
    };
};

//GET ALL AVAILABLE GROUPS (for the categories)
export const getGroups = () => {
    return (dispatch: any) => {
        dispatch(getGroupsStart());

        APIManager.get('items/groups?limit=-1&fields=*.*&filter[status][_eq]=Public&sort=group').then((response) => {
            if (response.data !== null && response.data !== undefined) {
                dispatch(getGroupsSuccess(response.data));
            }
        });
    };
};
export const getGroupsStart = () => {
    return {
        type: dataConstants.GET_GROUPS,
    };
};
export const getGroupsSuccess = (data: any) => {
    return {
        type: dataConstants.GET_GROUPS_SUCCESS,
        payload: data,
    };
};

//GET FULL CATALOG
export const getCatalogAll = () => {
    return (dispatch: any) => {
        dispatch(catalogAllStart());

        let link = 'items/gallery?limit=-1&filter[status][_eq]=Public&sort=name';
        link +=
            '&fields=id,name,duration,group.*,category.*,translations.*,thumbfile1,thumbfile2,thumbfile3,thumbfile4,thumbfile5,ext1,ext2,ext3,ext4,ext5';

        APIManager.get(link).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(catalogAllFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    dispatch(catalogAllSuccess(response.data));
                } else {
                    dispatch(catalogAllFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const catalogAllStart = () => {
    return {
        type: dataConstants.CATALOG_FULL,
    };
};
export const catalogAllSuccess = (data: any) => {
    return {
        type: dataConstants.CATALOG_FULL_SUCCESS,
        payload: data,
    };
};
export const catalogAllFailure = (data: any) => {
    return {
        type: dataConstants.CATALOG_FULL_ERROR,
        payload: data,
    };
};
export const getCatalogAllReset = () => {
    return {
        type: dataConstants.CATALOG_FULL_RESET,
    };
};

//GET CATALOG SAMPLES FOR MAIN PAGE (page in desc order from creation date).
export const getCatalogSamples = () => {
    return (dispatch: any) => {
        dispatch(catalogSamplesStart());

        let link = 'items/gallery?limit=4&sort=-date_updated&filter[status][_eq]=Public';
        link +=
            '&fields=id,name,duration,group.*,translations.*,thumbfile1,thumbfile2,thumbfile3,thumbfile4,thumbfile5,ext1,ext2,ext3,ext4,ext5';

        APIManager.get(link).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(catalogSamplesFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    dispatch(catalogSamplesSuccess(response.data));
                } else {
                    dispatch(catalogSamplesFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const catalogSamplesStart = () => {
    return {
        type: dataConstants.CATALOG_SAMPLES,
    };
};
export const catalogSamplesSuccess = (data: any) => {
    return {
        type: dataConstants.CATALOG_SAMPLES_SUCCESS,
        payload: data,
    };
};
export const catalogSamplesFailure = (data: any) => {
    return {
        type: dataConstants.CATALOG_SAMPLES_ERROR,
        payload: data,
    };
};
export const catalogSamplesReset = () => {
    return {
        type: dataConstants.CATALOG_SAMPLES_RESET,
    };
};

//GET FAVOURITE CATALOG SAMPLES
export const getFavoriteCatalogSamples = () => {
    return (dispatch: any) => {
        dispatch(favoriteCatalogSamplesStart());

        let link = 'favorites?limit=4&filter[status][_eq]=Public';
        link +=
            '&fields=id,name,duration,group.*,translations.*,thumbfile1,thumbfile2,thumbfile3,thumbfile4,thumbfile5,ext1,ext2,ext3,ext4,ext5';

        APIManager.get(link).then((response) => {
            if (response !== null && response !== undefined) {
                if (response.message !== null && response.message !== undefined) {
                    dispatch(favoriteCatalogSamplesFailure({ message: response.message, code: response.code }));
                } else {
                    dispatch(favoriteCatalogSamplesSuccess(response));
                }
            } else {
                dispatch(favoriteCatalogSamplesFailure(defaultErrorObject));
            }
        });
    };
};
export const favoriteCatalogSamplesStart = () => {
    return {
        type: dataConstants.FAVORITE_SAMPLES,
    };
};
export const favoriteCatalogSamplesSuccess = (data: any) => {
    return {
        type: dataConstants.FAVORITE_SAMPLES_SUCCESS,
        payload: data,
    };
};
export const favoriteCatalogSamplesFailure = (data: any) => {
    return {
        type: dataConstants.FAVORITE_SAMPLES_ERROR,
        payload: data,
    };
};
export const favoriteCatalogSamplesReset = () => {
    return {
        type: dataConstants.FAVORITE_SAMPLES_RESET,
    };
};

//GET SPECIFIC CATALOG ITEM
export const getCatalogItem = (id: string) => {
    return (dispatch: any) => {
        dispatch(catalogItemStart());

        let link = 'items/gallery?limit=1&filter[status][_eq]=Public&filter[id][_eq]=' + id;
        //link += '&fields=*.*';
        link +=
            '&fields=id,name,duration,group.*,category.*,translations.*,size1,size2,size3,size4,size5,size_1,size_2,size_3,size_4,size_5,thumbfile1,thumbfile2,thumbfile3,thumbfile4,thumbfile5,option1,option2,option3,option4,option5,option6,option7,option8,option9,option10,type1,type2,type3,type4,type5,type6,type7,type8,type9,type10,ext1,ext2,ext3,ext4,ext5';

        APIManager.get(link).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(catalogItemFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    dispatch(catalogItemSuccess(response.data));
                } else {
                    dispatch(catalogItemFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const catalogItemStart = () => {
    return {
        type: dataConstants.CATALOG_ITEM,
    };
};
export const catalogItemSuccess = (data: any) => {
    return {
        type: dataConstants.CATALOG_ITEM_SUCCESS,
        payload: data,
    };
};
export const catalogItemFailure = (data: any) => {
    return {
        type: dataConstants.CATALOG_ITEM_ERROR,
        payload: data,
    };
};
export const catalogItemReset = () => {
    return {
        type: dataConstants.CATALOG_ITEM_RESET,
    };
};
