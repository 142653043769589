import { stripeConstants } from '../constants/redux-stripe-constants';

const initialState = {
    productsState: '',
    productsError: '',
    productsData: [],

    checkoutState: '',
    checkoutError: '',
    checkoutData: [],

    stripeDataURLState: '',
    stripeDataURLError: '',
    stripeDataURLData: [],

    renewalState: '',
    renewalError: '',

    getSubsState: '',
    getSubsError: '',
    getSubsData: [],

    validSubState: '',
    validSubError: '',
    validSubData: [],
};

const stripeReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case stripeConstants.GET_PRODUCTS:
            return Object.assign({}, state, {
                productsState: 'in_progress',
            });
        case stripeConstants.GET_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                productsState: 'success',
                productsData: action.payload,
            });
        case stripeConstants.GET_PRODUCTS_ERROR:
            return Object.assign({}, state, {
                productsState: 'error',
                productsError: action.payload,
            });
        case stripeConstants.GET_PRODUCTS_RESET:
            return Object.assign({}, state, {
                productsState: '',
                productsError: '',
                productsData: [],
            });

        case stripeConstants.CHECKOUT:
            return Object.assign({}, state, {
                checkoutState: 'in_progress',
            });
        case stripeConstants.CHECKOUT_SUCCESS:
            return Object.assign({}, state, {
                checkoutState: 'success',
                checkoutData: action.payload,
            });
        case stripeConstants.CHECKOUT_ERROR:
            return Object.assign({}, state, {
                checkoutState: 'error',
                checkoutError: action.payload,
            });
        case stripeConstants.CHECKOUT_RESET:
            return Object.assign({}, state, {
                checkoutState: '',
                checkoutError: '',
                checkoutData: [],
            });

        case stripeConstants.STRIPE_DATA_URL:
            return Object.assign({}, state, {
                stripeDataURLState: 'in_progress',
            });
        case stripeConstants.STRIPE_DATA_URL_SUCCESS:
            return Object.assign({}, state, {
                stripeDataURLState: 'success',
                stripeDataURLData: action.payload,
            });
        case stripeConstants.STRIPE_DATA_URL_ERROR:
            return Object.assign({}, state, {
                stripeDataURLState: 'error',
                stripeDataURLError: action.payload,
            });
        case stripeConstants.STRIPE_DATA_URL_RESET:
            return Object.assign({}, state, {
                stripeDataURLState: '',
                stripeDataURLError: '',
                stripeDataURLData: [],
            });

        case stripeConstants.AUTO_RENEWAL:
            return Object.assign({}, state, {
                renewalState: 'in_progress',
            });
        case stripeConstants.AUTO_RENEWAL_SUCCESS:
            return Object.assign({}, state, {
                renewalState: 'success',
            });
        case stripeConstants.AUTO_RENEWAL_ERROR:
            return Object.assign({}, state, {
                renewalState: 'error',
                renewalError: action.payload,
            });
        case stripeConstants.AUTO_RENEWAL_RESET:
            return Object.assign({}, state, {
                renewalState: '',
                renewalError: '',
            });

        case stripeConstants.GET_SUBS:
            return Object.assign({}, state, {
                getSubsState: 'in_progress',
            });
        case stripeConstants.GET_SUBS_SUCCESS:
            return Object.assign({}, state, {
                getSubsState: 'success',
                getSubsData: action.payload,
            });
        case stripeConstants.GET_SUBS_ERROR:
            return Object.assign({}, state, {
                getSubsState: 'error',
                getSubsError: action.payload,
            });
        case stripeConstants.GET_SUBS_RESET:
            return Object.assign({}, state, {
                getSubsState: '',
                getSubsError: '',
                getSubsData: [],
            });

        case stripeConstants.HAS_VALID_SUB:
            return Object.assign({}, state, {
                validSubState: 'in_progress',
            });
        case stripeConstants.HAS_VALID_SUB_SUCCESS:
            return Object.assign({}, state, {
                validSubState: 'success',
                validSubData: action.payload,
            });
        case stripeConstants.HAS_VALID_SUB_ERROR:
            return Object.assign({}, state, {
                validSubState: 'error',
                validSubError: action.payload,
            });
        case stripeConstants.HAS_VALID_SUB_RESET:
            return Object.assign({}, state, {
                validSubState: '',
                validSubError: '',
                validSubData: [],
            });

        default:
            return state;
    }
};

export default stripeReducers;
