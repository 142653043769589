import { pageConstants } from '../constants/redux-page-constants';

const initialState = {
    homeState: '',
    homeError: '',
    homeData: [],

    contactState: '',
    contactError: '',
    contactData: [],

    privacyState: '',
    privacyError: '',
    privacyData: [],

    termsState: '',
    termsError: '',
    termsData: [],

    priceState: '',
    priceError: '',
    priceData: [],

    processingState: '',
    processingError: '',
    processingData: [],

    accountState: '',
    accountError: '',
    accountData: [],

    galleryOverviewState: '',
    galleryOverviewError: '',
    galleryOverviewData: [],

    galleryItemState: '',
    galleryItemError: '',
    galleryItemData: [],
};

const pageReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case pageConstants.GET_HOME_DATA:
            return Object.assign({}, state, {
                homeState: 'in_progress',
            });
        case pageConstants.GET_HOME_DATA_SUCCESS:
            return Object.assign({}, state, {
                homeState: 'success',
                homeData: action.payload,
            });
        case pageConstants.GET_HOME_DATA_ERROR:
            return Object.assign({}, state, {
                homeState: 'error',
                homeError: action.payload,
            });

        case pageConstants.GET_CONTACT_DATA:
            return Object.assign({}, state, {
                contactState: 'in_progress',
            });
        case pageConstants.GET_CONTACT_DATA_SUCCESS:
            return Object.assign({}, state, {
                contactState: 'success',
                contactData: action.payload,
            });
        case pageConstants.GET_CONTACT_DATA_ERROR:
            return Object.assign({}, state, {
                contactState: 'error',
                contactError: action.payload,
            });

        case pageConstants.GET_PRIVACY_DATA:
            return Object.assign({}, state, {
                privacyState: 'in_progress',
            });
        case pageConstants.GET_PRIVACY_DATA_SUCCESS:
            return Object.assign({}, state, {
                privacyState: 'success',
                privacyData: action.payload,
            });
        case pageConstants.GET_PRIVACY_DATA_ERROR:
            return Object.assign({}, state, {
                privacyState: 'error',
                privacyError: action.payload,
            });

        case pageConstants.GET_TERMS_DATA:
            return Object.assign({}, state, {
                termsState: 'in_progress',
            });
        case pageConstants.GET_TERMS_DATA_SUCCESS:
            return Object.assign({}, state, {
                termsState: 'success',
                termsData: action.payload,
            });
        case pageConstants.GET_TERMS_DATA_ERROR:
            return Object.assign({}, state, {
                termsState: 'error',
                termsError: action.payload,
            });

        case pageConstants.GET_PRICE_DATA:
            return Object.assign({}, state, {
                priceState: 'in_progress',
            });
        case pageConstants.GET_PRICE_DATA_SUCCESS:
            return Object.assign({}, state, {
                priceState: 'success',
                priceData: action.payload,
            });
        case pageConstants.GET_PRICE_DATA_ERROR:
            return Object.assign({}, state, {
                priceState: 'error',
                priceError: action.payload,
            });

        case pageConstants.GET_PROCESSING_DATA:
            return Object.assign({}, state, {
                processingState: 'in_progress',
            });
        case pageConstants.GET_PROCESSING_DATA_SUCCESS:
            return Object.assign({}, state, {
                processingState: 'success',
                processingData: action.payload,
            });
        case pageConstants.GET_PROCESSING_DATA_ERROR:
            return Object.assign({}, state, {
                processingState: 'error',
                processingError: action.payload,
            });

        case pageConstants.GET_ACCOUNT_DATA:
            return Object.assign({}, state, {
                accountState: 'in_progress',
            });
        case pageConstants.GET_ACCOUNT_DATA_SUCCESS:
            return Object.assign({}, state, {
                accountState: 'success',
                accountData: action.payload,
            });
        case pageConstants.GET_ACCOUNT_DATA_ERROR:
            return Object.assign({}, state, {
                accountState: 'error',
                accountError: action.payload,
            });

        case pageConstants.GET_GALLERY_OVERVIEW_DATA:
            return Object.assign({}, state, {
                galleryOverviewState: 'in_progress',
            });
        case pageConstants.GET_GALLERY_OVERVIEW_DATA_SUCCESS:
            return Object.assign({}, state, {
                galleryOverviewState: 'success',
                galleryOverviewData: action.payload,
            });
        case pageConstants.GET_GALLERY_OVERVIEW_DATA_ERROR:
            return Object.assign({}, state, {
                galleryOverviewState: 'error',
                galleryOverviewError: action.payload,
            });

        case pageConstants.GET_GALLERY_ITEM_DATA:
            return Object.assign({}, state, {
                galleryItemState: 'in_progress',
            });
        case pageConstants.GET_GALLERY_ITEM_DATA_SUCCESS:
            return Object.assign({}, state, {
                galleryItemState: 'success',
                galleryItemData: action.payload,
            });
        case pageConstants.GET_GALLERY_ITEM_DATA_ERROR:
            return Object.assign({}, state, {
                galleryItemState: 'error',
                galleryItemError: action.payload,
            });

        default:
            return state;
    }
};

export default pageReducers;
