import { userConstants } from '../constants/redux-user-constants';

const initialState = {
    loginState: '',
    loginError: '',
    loginData: {},

    refreshTokenState: '',
    refreshTokenError: '',
    refreshTokenData: {},

    logoutState: '',
    logoutError: '',
    logoutData: {},

    createNewUserState: '',
    createNewUserError: '',
    createNewUserData: {},

    getUserState: '',
    getUserError: '',
    userData: {},

    updateUserState: '',
    updateUserError: '',

    pwResetRequestState: '',

    pwResetState: '',
    pwResetError: '',

    renderRequestState: '',
    renderRequestError: '',
    renderRequestData: '',

    userRenderRequestsState: '',
    userRenderRequesstError: '',
    userRenderRequestsData: '',

    renderProgressState: '',
    renderProgressError: '',
    renderProgressData: '',

    deleteItemState: '',
    deleteItemError: '',
    deleteItemData: '',

    limitState: '',
    limitError: '',
    limitData: '',
};

const userReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case userConstants.LOGIN:
            return Object.assign({}, state, {
                loginState: 'in_progress',
            });
        case userConstants.LOGIN_SUCCESS:
            return Object.assign({}, state, {
                loginState: 'success',
                loginData: action.payload,
            });
        case userConstants.LOGIN_ERROR:
            return Object.assign({}, state, {
                loginState: 'error',
                loginError: action.payload,
            });
        case userConstants.LOGIN_RESET:
            return Object.assign({}, state, {
                loginState: '',
                loginError: '',
                loginData: {},
            });

        case userConstants.REFRESH_TOKEN:
            return Object.assign({}, state, {
                refreshTokenState: 'in_progress',
            });
        case userConstants.REFRESH_TOKEN_SUCCESS:
            return Object.assign({}, state, {
                refreshTokenState: 'success',
                refreshTokenData: action.payload,
            });
        case userConstants.REFRESH_TOKEN_ERROR:
            return Object.assign({}, state, {
                refreshTokenState: 'error',
                refreshTokenError: action.payload,
            });
        case userConstants.REFRESH_TOKEN_RESET:
            return Object.assign({}, state, {
                refreshTokenState: '',
                refreshTokenError: '',
                refreshTokenData: {},
            });

        case userConstants.LOGOUT:
            return Object.assign({}, state, {
                logoutState: 'in_progress',
            });
        case userConstants.LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                logoutState: 'success',
                logoutData: action.payload,
            });
        case userConstants.LOGOUT_ERROR:
            return Object.assign({}, state, {
                logoutState: 'error',
                logoutError: action.payload,
            });
        case userConstants.LOGOUT_RESET:
            return Object.assign({}, state, {
                logoutState: '',
                logoutError: '',
                logoutData: {},
            });

        case userConstants.CREATE_NEW_USER:
            return Object.assign({}, state, {
                createNewUserState: 'in_progress',
            });
        case userConstants.CREATE_NEW_USER_SUCCESS:
            return Object.assign({}, state, {
                createNewUserState: 'success',
            });
        case userConstants.CREATE_NEW_USER_ERROR:
            return Object.assign({}, state, {
                createNewUserState: 'error',
                createNewUserError: action.payload,
            });
        case userConstants.CREATE_NEW_USER_RESET:
            return Object.assign({}, state, {
                createNewUserState: '',
                createNewUserError: '',
                createNewUserData: {},
            });

        case userConstants.GET_USER_INFO:
            return Object.assign({}, state, {
                getUserState: 'in_progress',
            });
        case userConstants.GET_USER_INFO_SUCCESS:
            return Object.assign({}, state, {
                getUserState: 'success',
                userData: action.payload,
            });
        case userConstants.GET_USER_INFO_ERROR:
            return Object.assign({}, state, {
                getUserState: 'error',
                getUserError: action.payload,
            });
        case userConstants.GET_USER_INFO_RESET:
            return Object.assign({}, state, {
                getUserState: '',
                getUserError: '',
                userData: {},
            });

        case userConstants.UPDATE_USER_INFO:
            return Object.assign({}, state, {
                updateUserState: 'in_progress',
            });
        case userConstants.UPDATE_USER_INFO_SUCCESS:
            return Object.assign({}, state, {
                updateUserState: 'success',
            });
        case userConstants.UPDATE_USER_INFO_ERROR:
            return Object.assign({}, state, {
                updateUserState: 'error',
                updateUserError: action.payload,
            });
        case userConstants.UPDATE_USER_INFO_RESET:
            return Object.assign({}, state, {
                updateUserState: '',
                updateUserError: '',
            });

        case userConstants.PASSWORD_RESET_REQUEST:
            return Object.assign({}, state, {
                pwResetRequestState: 'in_progress',
            });
        case userConstants.PASSWORD_RESET_REQUEST_SUCCESS:
            return Object.assign({}, state, {
                pwResetRequestState: 'success',
            });

        case userConstants.PASSWORD_RESET:
            return Object.assign({}, state, {
                pwResetState: 'in_progress',
            });
        case userConstants.PASSWORD_RESET_SUCCESS:
            return Object.assign({}, state, {
                pwResetState: 'success',
            });
        case userConstants.PASSWORD_RESET_ERROR:
            return Object.assign({}, state, {
                pwResetState: 'error',
                pwResetError: action.payload,
            });
        case userConstants.PASSWORD_RESET_RESET:
            return Object.assign({}, state, {
                pwResetState: '',
                pwResetError: '',
            });

        case userConstants.POST_RENDER_REQUEST:
            return Object.assign({}, state, {
                renderRequestState: 'in_progress',
            });
        case userConstants.POST_RENDER_REQUEST_SUCCESS:
            return Object.assign({}, state, {
                renderRequestState: 'success',
                renderRequestData: action.payload,
            });
        case userConstants.POST_RENDER_REQUEST_ERROR:
            return Object.assign({}, state, {
                renderRequestState: 'error',
                renderRequestError: action.payload,
            });
        case userConstants.POST_RENDER_REQUEST_RESET:
            return Object.assign({}, state, {
                renderRequestState: '',
                renderRequestError: '',
                renderRequestData: [],
            });

        case userConstants.GET_USER_RENDER_REQUESTS:
            return Object.assign({}, state, {
                userRenderRequestsState: 'in_progress',
            });
        case userConstants.GET_USER_RENDER_REQUESTS_SUCCESS:
            return Object.assign({}, state, {
                userRenderRequestsState: 'success',
                userRenderRequestsData: action.payload,
            });
        case userConstants.GET_USER_RENDER_REQUESTS_ERROR:
            return Object.assign({}, state, {
                userRenderRequestsState: 'error',
                userRenderRequesstError: action.payload,
            });
        case userConstants.GET_USER_RENDER_REQUESTS_RESET:
            return Object.assign({}, state, {
                userRenderRequestsState: '',
                userRenderRequesstError: '',
                userRenderRequestsData: [],
            });

        case userConstants.GET_RENDER_PROGRESS:
            return Object.assign({}, state, {
                renderProgressState: 'in_progress',
            });
        case userConstants.GET_RENDER_PROGRESS_SUCCESS:
            return Object.assign({}, state, {
                renderProgressState: 'success',
                renderProgressData: action.payload,
            });
        case userConstants.GET_RENDER_PROGRESS_ERROR:
            return Object.assign({}, state, {
                renderProgressState: 'error',
                renderProgressError: action.payload,
            });
        case userConstants.GET_RENDER_PROGRESS_RESET:
            return Object.assign({}, state, {
                renderProgressState: '',
                renderProgressError: '',
                renderProgressData: [],
            });

        case userConstants.DELETE_STATE:
            return Object.assign({}, state, {
                deleteItemState: 'in_progress',
            });
        case userConstants.DELETE_SUCCESS:
            return Object.assign({}, state, {
                deleteItemState: 'success',
                userRenderRequestsData: action.payload,
            });
        case userConstants.DELETE_ERROR:
            return Object.assign({}, state, {
                deleteItemState: 'error',
                deleteItemError: action.payload,
            });
        case userConstants.DELETE_RESET:
            return Object.assign({}, state, {
                deleteItemState: '',
                deleteItemError: '',
                deleteItemData: '',
            });

        case userConstants.USER_LIMIT_REACHED_STATE:
            return Object.assign({}, state, {
                limitState: 'in_progress',
            });
        case userConstants.USER_LIMIT_REACHED_SUCCESS:
            return Object.assign({}, state, {
                limitState: 'success',
                limitData: action.payload,
            });
        case userConstants.USER_LIMIT_REACHED_ERROR:
            return Object.assign({}, state, {
                limitState: 'error',
                limitError: action.payload,
            });
        case userConstants.USER_LIMIT_REACHED_RESET:
            return Object.assign({}, state, {
                limitState: '',
                limitError: '',
                limitData: '',
            });

        default:
            return state;
    }
};

export default userReducers;
