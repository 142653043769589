import React, { useState, FC, useEffect } from 'react';
import Wrapper from '../../components/wrapper/wrapper';
import { useLocation } from 'react-router-dom';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import '../../style/style.scss';
import {
    getCatalogAll,
    getCatalogAllReset,
    getCustomData,
    getCustomDataReset,
} from '../../helpers/actions/redux-data-actions';
import { getPageGalleryOverview } from '../../helpers/actions/redux-page-actions';
import CatalogItem from '../../components/catalog/item';
import PageButtonNavBar from '../../components/pagebar/pagebar';

import SelectIcon from '../../images/filter-selected.png';
import Search from '../../images/search.png';
import Menuclose from '../../images/menu-mobile-cross.png';

interface Props {}
const APIManager = API.instance;

const CatalogPage: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const queryParameters = new URLSearchParams(window.location.search);
    const pageSize = 12;

    interface customViewObject {
        hideNavbar: boolean;
        hideHeader: boolean;
        hideFooter: boolean;
        hideFilterApps: boolean;
        hideFilterGroup: boolean;
        hideFilterCategory: boolean;
        hideFilterSearch: boolean;
        hidePagination: boolean;
        customLogo: string;
        customTitle: string;
        customIntro: string;
        accentColor: string;
        filterGroup: string;
        filterCategory: string;
        filterSearch: string;
        filterPage: string;
    }
    const [customView, setCustomView] = useState<customViewObject>();
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDesc, setSeoDesc] = useState('');
    const pageData = useSelector((state: RootStateOrAny) => state.pageReducers.galleryOverviewData);
    const [page, setPage] = useState(
        queryParameters.get('page') !== null ? parseInt(queryParameters.get('page') as any, 10) : 1
    );

    const [pageLoading, setPageLoading] = useState(true);
    const [customLoading, setCustomLoading] = useState(true);
    const [initialLoading, setInitialLoading] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [allCatalogData, setAllCatalogData] = useState([]);
    const [paginatedCatalogData, setPaginatedCatalogData] = useState([]);
    const [filteredCatalogData, setFilteredCatalogData] = useState([]);
    const [categories, setCategories] = useState<any>([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [groups, setGroups] = useState<any>([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [openMobileFilterMenu, setOpenMobileFilterMenu] = useState(false);

    const catalogState = useSelector((state: RootStateOrAny) => state.dataReducers.catalogState);
    const catalogData = useSelector((state: RootStateOrAny) => state.dataReducers.catalogData);
    const categoriesData = useSelector((state: RootStateOrAny) => state.dataReducers.categoriesData);
    const categoriesState = useSelector((state: RootStateOrAny) => state.dataReducers.categoriesState);
    const groupsState = useSelector((state: RootStateOrAny) => state.dataReducers.groupsState);
    const groupsData = useSelector((state: RootStateOrAny) => state.dataReducers.groupsData);

    const customDataState = useSelector((state: RootStateOrAny) => state.dataReducers.customDataState);
    const customData = useSelector((state: RootStateOrAny) => state.dataReducers.customData);
    const customDataError = useSelector((state: RootStateOrAny) => state.dataReducers.customDataError);

    useEffect(() => {
        setLoadData(true);
        dispatch(getPageGalleryOverview());
        dispatch(getCustomDataReset());

        let customDataID = queryParameters.get('custom');
        if (customDataID) {
            dispatch(getCustomData(customDataID));
            dispatch(getCatalogAll());
        } else {
            setCustomLoading(false);
        }
    }, []);

    useEffect(() => {
        let customDataID = queryParameters.get('custom');
        if (customDataState == 'success' && customDataID) {
            setCustomLoading(false);
            let data = {
                hideHeader: false,
                hideNavbar: true,
                hideFooter: true,
                hideFilterApps: true,
                hideFilterGroup: false,
                hideFilterCategory: false,
                hideFilterSearch: true,
                hidePagination: false,
                customLogo: '',
                customTitle: '',
                customIntro: '',
                accentColor: '',
                filterGroup: '',
                filterCategory: '',
                filterSearch: '',
                filterPage: '',
            };
            if (customData && customData[0]) {
                if (customData[0].brand_logo && customData[0].brand_logo !== '') {
                    data.customLogo = customData[0].brand_logo;
                } else {
                    data.hideHeader = true;
                }
                if (customData[0].accent_color) {
                    data.accentColor = customData[0].accent_color;
                }
            }
            setCustomView(data);
        } else if (customDataState == 'error') {
            setCustomLoading(false);
        }
    }, [customDataState]);

    useEffect(() => {
        if (Object.keys(pageData).length > 0) {
            setSeoTitle(pageData.seo_title);
            setSeoDesc(pageData.seo_description);
        }
    }, [pageData]);

    useEffect(() => {
        if (loadData) {
            dispatch(getCatalogAll());
        } else {
            dispatch(getCatalogAllReset());
        }
    }, [loadData]);

    useEffect(() => {
        if (
            groupsState === 'success' &&
            groupsData.length > 0 &&
            categoriesState === 'success' &&
            categoriesData.length > 0
        ) {
            setGroups(groupsData);
            setCategories(categoriesData);
        }
    }, [groupsState, categoriesState]);

    useEffect(() => {
        if (loadData) {
            if (catalogState === 'success') {
                setAllCatalogData(catalogData);
                setLoadData(false);
                setInitialLoading(true);
                setPageLoading(false);
            } else if (catalogState === 'error') {
                setLoadData(false);
                setPageLoading(false);
            }
        }
    }, [catalogState]);

    useEffect(() => {
        if (categories.length > 0 && groups.length > 0) {
            let urlCategories = queryParameters.get('category');
            let urlGroups = queryParameters.get('group');

            if ((urlCategories !== null && urlCategories !== '') || (urlGroups !== null && urlGroups !== '')) {
                if (urlCategories === 'all' || urlCategories == null || urlCategories === '') {
                    setSelectedCategories(categories);
                } else {
                    let newSelectedCategories = [] as any;
                    let urlCategoriesArray = urlCategories.replace(/,/g, '|').split('|');
                    for (let item of urlCategoriesArray) {
                        for (let category of categories) {
                            if (parseInt(category['id']) === parseInt(item)) {
                                newSelectedCategories.push(category);
                            }
                        }
                    }
                    setSelectedCategories(newSelectedCategories);
                }

                if (urlGroups === 'all' || urlGroups == null || urlGroups === '') {
                    setSelectedGroups(groups);
                } else {
                    let newSelectedGroups = [] as any;
                    let urlGroupsArray = urlGroups.replace(/,/g, '|').split('|');
                    for (let item of urlGroupsArray) {
                        for (let group of groups) {
                            if (parseInt(group['id']) === parseInt(item)) {
                                newSelectedGroups.push(group);
                            }
                        }
                    }
                    setSelectedGroups(newSelectedGroups);
                }
            } else {
                getFilters();
            }
        }
    }, [categories, groups, state]);

    useEffect(() => {
        if (!loadData && allCatalogData.length > 0) {
            filterData();

            if (!initialLoading) {
                setAddressBarURL();
            }
        }
    }, [allCatalogData, selectedCategories, selectedGroups, searchText]);

    useEffect(() => {
        setCurrentPageData();
    }, [filteredCatalogData, page]);

    const getFilters = () => {
        let savedFilters = APIManager.getCookie('catalog_filter');

        if (savedFilters !== undefined) {
            if (savedFilters['search'] !== undefined) {
                setSearchText(savedFilters['search']);
            }
            if (savedFilters['groups'] !== undefined) {
                let foundGroups = [] as any;
                for (let item of savedFilters['groups']) {
                    for (let group of groups) {
                        if (item === group['id']) {
                            foundGroups.push(group);
                        }
                    }
                }
                if (foundGroups.length > 0) {
                    setSelectedGroups(foundGroups);
                } else {
                    setSelectedGroups(groups);
                }
            }
            if (savedFilters['categories'] !== undefined) {
                let foundCategories = [] as any;
                for (let item of savedFilters['categories']) {
                    for (let category of categories) {
                        if (item === category['id']) {
                            foundCategories.push(category);
                        }
                    }
                }
                if (foundCategories.length > 0) {
                    setSelectedCategories(foundCategories);
                } else {
                    setSelectedCategories(categories);
                }
            }
            if (savedFilters['page'] !== undefined) {
                setPage(savedFilters['page']);
            }
        } else {
            setSearchText('');
            setSelectedCategories(categories);
            setSelectedGroups(groups);
            setPage(1);
        }
    };
    const saveFilters = () => {
        let selCat = [] as any;
        let selGrp = [] as any;

        if (selectedCategories.length > 0) {
            for (let item of selectedCategories) {
                selCat.push(item['id']);
            }
        }
        if (selectedGroups.length > 0) {
            for (let item of selectedGroups) {
                selGrp.push(item['id']);
            }
        }

        let data = {
            search: searchText,
            groups: selGrp,
            categories: selCat,
            page: page,
        };
        APIManager.setCookie('catalog_filter', JSON.stringify(data), { maxAge: 604800 });
    };

    const setAddressBarURL = () => {
        let catalogLink = '/catalog';
        let categoryIDs = '';
        let groupIDs = '';
        let currentPage = page;

        if (page * pageSize > filteredCatalogData.length + pageSize) {
            setPage(1);
        } else {
            if (selectedGroups.length === groups.length) {
                groupIDs = 'all';
            } else {
                for (let i = 0; i < selectedGroups.length; i++) {
                    if (i === 0) {
                        groupIDs += selectedGroups[i]['id'];
                    } else {
                        groupIDs += ',' + selectedGroups[i]['id'];
                    }
                }
            }
            if (selectedCategories.length === categories.length) {
                categoryIDs = 'all';
            } else {
                for (let i = 0; i < selectedCategories.length; i++) {
                    if (i === 0) {
                        categoryIDs += selectedCategories[i]['id'];
                    } else {
                        categoryIDs += ',' + selectedCategories[i]['id'];
                    }
                }
            }

            let customDataID = queryParameters.get('custom');
            if (customDataID) {
                catalogLink += '?custom=' + customDataID + '&group=' + groupIDs;
            } else {
                catalogLink += '?group=' + groupIDs;
            }

            catalogLink += '&category=' + categoryIDs;
            catalogLink += '&page=' + currentPage;
            window.history.replaceState(null, '', catalogLink);
        }
    };

    const setCurrentPageData = () => {
        if (filteredCatalogData.length > 0) {
            let tempData = [] as any;

            for (let i = 0; i < filteredCatalogData.length; i++) {
                if (i + 1 <= page * pageSize && i + 1 > (page - 1) * pageSize) {
                    tempData.push(filteredCatalogData[i]);
                }
            }

            setPaginatedCatalogData(tempData);
            setAddressBarURL();
            saveFilters();

            if (initialLoading) {
                setInitialLoading(false);
            }
        } else {
            setPaginatedCatalogData([]);

            if (initialLoading) {
                setInitialLoading(false);
                setAddressBarURL();
            }
        }
    };

    const filterData = () => {
        let filteredData = [] as any;

        if (selectedCategories.length > 0 && selectedGroups.length > 0) {
            let tempData = [] as any;
            filteredData = allCatalogData;

            //Filtering group
            tempData = [];
            for (let item of filteredData) {
                for (let group of selectedGroups) {
                    if (item.group.id === group['id']) {
                        tempData.push(item);
                    }
                }
            }
            filteredData = tempData;

            //Filtering category
            tempData = [];
            for (let item of filteredData) {
                for (let category of selectedCategories) {
                    if (item.category.id === category['id']) {
                        tempData.push(item);
                    }
                }
            }
            filteredData = tempData;

            //Filtering on search text
            if (searchText !== '') {
                tempData = [];
                for (let item of filteredData) {
                    if (item['name'].toLowerCase().includes(searchText.toLowerCase())) {
                        tempData.push(item);
                    }
                }
                filteredData = tempData;
            }
        }
        setFilteredCatalogData(filteredData);
    };

    const isSelectedCategory = (item: any) => {
        for (let i = 0; i < selectedCategories.length; i++) {
            if (selectedCategories[i]['category'] === item.category) {
                return true;
            }
        }
        return false;
    };
    const clickedCategory = (item: any) => {
        if (isSelectedCategory(item)) {
            let tempData = [] as any;
            for (let i = 0; i < selectedCategories.length; i++) {
                if (selectedCategories[i]['category'] !== item.category) {
                    tempData.push(selectedCategories[i]);
                }
            }
            setSelectedCategories(tempData);
        } else {
            let newData = [] as any;
            let combinedData = selectedCategories;
            for (let i = 0; i < categories.length; i++) {
                if (categories[i]['category'] === item.category) {
                    newData.push(categories[i]);
                }
            }
            setSelectedCategories(combinedData.concat(newData));
        }
    };
    const renderCategories = () => {
        let renderObjects = [] as any;

        renderObjects.push(<div className="title-name-group">{I18n.t('CATALOG_FILTER_CAT') as string}</div>);

        for (let i = 0; i < categories.length; i++) {
            // if (i + 2 < categories.length && categories[i]['category'] === categories[i + 2]['category']) {
            //     i++;
            // }
            // if (i + 1 < categories.length && categories[i]['category'] === categories[i + 1]['category']) {
            //     i++;
            // }
            let counter = 0;
            for (let j = 0; j + i + 1 < categories.length; j++) {
                if (categories[i + 1]['category'] === categories[i + 1 + j]['category']) {
                    counter++;
                } else {
                    break;
                }
            }
            i += counter;

            renderObjects.push(
                <div className="item" key={'category' + i}>
                    <div
                        className="filter-icon-container"
                        style={{
                            background: isSelectedCategory(categories[i])
                                ? customView?.accentColor
                                    ? customView?.accentColor
                                    : '#cd2b38'
                                : '#3A3A3A',
                        }}
                        onClick={() => clickedCategory(categories[i])}
                    >
                        {isSelectedCategory(categories[i]) && (
                            <img src={SelectIcon} className="filter-icon" alt={'item-cat' + i} />
                        )}
                    </div>
                    {APIManager.filterLanguageData(categories[i]).category}
                </div>
            );
        }

        if (renderObjects.length > 1) {
            renderObjects.push(
                <div key="selectingcategories">
                    <span className="selection-text first" onClick={() => setSelectedCategories(categories)}>
                        {I18n.t('SELECT') as string}
                    </span>
                    <span
                        className="selection-text"
                        onClick={() => {
                            setSelectedCategories([]);
                            setPage(1);
                        }}
                    >
                        {I18n.t('DESELECT') as string}
                    </span>
                </div>
            );
        }

        return renderObjects;
    };

    const isSelectedGroup = (item: any) => {
        for (let i = 0; i < selectedGroups.length; i++) {
            if (selectedGroups[i]['id'] === item.id) {
                return true;
            }
        }
        return false;
    };
    const clickedGroup = (item: any) => {
        if (isSelectedGroup(item)) {
            let tempData = [] as any;
            for (let i = 0; i < selectedGroups.length; i++) {
                if (selectedGroups[i]['id'] !== item.id) {
                    tempData.push(selectedGroups[i]);
                }
            }
            setSelectedGroups(tempData);
        } else {
            setSelectedGroups((selectedGroups) => [...selectedGroups, item] as any);
        }
    };
    const renderGroups = () => {
        let renderObjects = [] as any;

        renderObjects.push(<div className="title-name-group">{I18n.t('CATALOG_FILTER_GROUP') as string}</div>);

        for (let i = 0; i < groups.length; i++) {
            renderObjects.push(
                <div className="item" key={'group' + i}>
                    <div
                        className="filter-icon-container"
                        style={{
                            background: isSelectedGroup(groups[i])
                                ? customView?.accentColor
                                    ? customView?.accentColor
                                    : '#cd2b38'
                                : '#3A3A3A',
                        }}
                        onClick={() => clickedGroup(groups[i])}
                    >
                        {isSelectedGroup(groups[i]) && (
                            <img src={SelectIcon} className="filter-icon" alt={'item-cat' + i} />
                        )}
                    </div>
                    {APIManager.filterLanguageData(groups[i]).group}
                </div>
            );
        }

        if (renderObjects.length > 1) {
            renderObjects.push(
                <div key="selectinggroups">
                    <span className="selection-text first" onClick={() => setSelectedGroups(groups)}>
                        {I18n.t('SELECT') as string}
                    </span>
                    <span
                        className="selection-text"
                        onClick={() => {
                            setSelectedGroups([]);
                            setPage(1);
                        }}
                    >
                        {I18n.t('DESELECT') as string}
                    </span>
                </div>
            );
        }

        return renderObjects;
    };

    const renderApps = () => {
        let renderObjects = [] as any;

        let apps = [
            {
                name: 'Touchpix',
                checked: true,
            },
            {
                name: 'GlambotApp',
                checked: true,
            },
            {
                name: I18n.t('CATALOG_FILTER_APPS_MORE_SOON'),
                checked: false,
            },
        ];

        for (let i = 0; i < apps.length; i++) {
            renderObjects.push(
                <div className="item" key={'apps' + i}>
                    <div
                        className="filter-icon-container -disabled"
                        style={{
                            background: apps[i].checked
                                ? customView?.accentColor
                                    ? customView?.accentColor
                                    : '#cd2b38'
                                : '#3A3A3A',
                            opacity: 0.4,
                        }}
                    >
                        {apps[i].checked && (
                            <img src={SelectIcon} className="filter-icon -disabled" alt={apps[i].name} />
                        )}
                    </div>
                    {apps[i].name}
                </div>
            );
        }

        return renderObjects;
    };

    const renderSearchResultsText = () => {
        if (pageLoading || initialLoading || loadData) {
            return I18n.t('CATALOG_RESULTS_LOADING') as string;
        } else {
            return (
                <div>
                    {(filteredCatalogData.length + ' ' + I18n.t('CATALOG_RESULTS_AMOUNT')) as string}
                    {paginatedCatalogData.length > 1 &&
                        ((((' - [' + I18n.t('CATALOG_RESULTS_FROM')) as string) +
                            ' ' +
                            (page * pageSize - pageSize + 1) +
                            ' ' +
                            I18n.t('CATALOG_RESULTS_TO')) as string) +
                            ' ' +
                            (page * pageSize > filteredCatalogData.length
                                ? filteredCatalogData.length
                                : page * pageSize) +
                            ']'}
                </div>
            );
        }
    };

    return (
        <Wrapper
            seo_title={seoTitle}
            seo_description={seoDesc}
            disableHeader={(queryParameters.get('custom') && customLoading) || customView?.hideHeader}
            disableNavigation={customView?.hideNavbar}
            disableFooter={customView?.hideFooter}
            customLogo={customView?.customLogo}
        >
            <section className={`section-catalog ${queryParameters.get('custom') ? 'customview' : ''}`}>
                <div className="container">
                    <div
                        className={`dark-background ${openMobileFilterMenu && 'open'}`}
                        onClick={() => setOpenMobileFilterMenu(!openMobileFilterMenu)}
                    />
                    <div className={`mobile-filter-menu ${openMobileFilterMenu && 'open'}`}>
                        <div>
                            <div className="title">{I18n.t('CATALOG_FILTER_MOBILE_TITLE') as string}</div>
                            <img
                                src={Menuclose}
                                className="icon-close"
                                alt="menu"
                                onClick={() => setOpenMobileFilterMenu(!openMobileFilterMenu)}
                            />
                        </div>
                        <div className="divider" />
                        <div className="row mobile-filter-block">
                            <div className="col-12 col-md-6 mobile-filter-block-left">
                                {!customView?.hideFilterApps && !pageLoading && (
                                    <div className="mb-5">
                                        <div className="title-name-group">
                                            {I18n.t('CATALOG_FILTER_APPS') as string}
                                        </div>
                                        <div className="apps-block">{renderApps()}</div>
                                    </div>
                                )}

                                <div>{renderGroups()}</div>
                            </div>
                            <div className="col-12 col-md-6 mobile-filter-block-right">
                                <div>{renderCategories()}</div>
                            </div>
                        </div>
                        <div className="button-close" onClick={() => setOpenMobileFilterMenu(!openMobileFilterMenu)}>
                            {I18n.t('CATALOG_FILTER_MOBILE_BUTTON_CLOSE') as string}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 top-container">{I18n.t('CATALOG_PAGE_TITLE') as string}</div>
                    </div>
                    <div className="divider-top" />
                </div>
                <div className="container">
                    <div className="row entire-container">
                        {(!customView?.hideFilterApps ||
                            !customView?.hideFilterGroup ||
                            !customView?.hideFilterCategory) && (
                            <div className="col-lg-3 left-filter-container">
                                <div>
                                    {!customView?.hideFilterApps && !pageLoading && (
                                        <div>
                                            <div>{I18n.t('CATALOG_FILTER_APPS') as string}</div>
                                            <div className="apps-block">{renderApps()}</div>
                                        </div>
                                    )}
                                    {!customView?.hideFilterGroup && groups.length > 0 && (
                                        <div>
                                            <div
                                                className={
                                                    customView?.hideFilterApps ? 'groups-block mt-0' : 'groups-block'
                                                }
                                            >
                                                {renderGroups()}
                                            </div>
                                        </div>
                                    )}
                                    {!customView?.hideFilterCategory && categories.length > 0 && (
                                        <div>
                                            <div className="categories-block">{renderCategories()}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="col-12 col-lg-9 items-container">
                            <div className="catalog-title">{I18n.t('CATALOG_RESULTS_TITLE') as string}</div>
                            {!customView?.hideFilterSearch && !customLoading && (
                                <div className="catalog-search">
                                    <img src={Search} className="search-icon" alt="search-icon" />
                                    <span className="search-field">
                                        <input
                                            name="searchInput"
                                            type="text"
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                            placeholder={I18n.t('SEARCH') as string}
                                        />
                                    </span>
                                    <div className="search-close-block">
                                        {searchText !== '' && (
                                            <img
                                                src={Menuclose}
                                                className="search-close-icon"
                                                alt="close-icon"
                                                onClick={() => setSearchText('')}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                            <div
                                className="catalog-filter-button"
                                onClick={() => setOpenMobileFilterMenu(!openMobileFilterMenu)}
                            >
                                {I18n.t('CATALOG_FILTER_MOBILE_BUTTON_OPEN') as string}
                            </div>

                            <div className="catalog-results-text">{renderSearchResultsText()}</div>

                            <div
                                className={`catalog-results ${
                                    customView?.hideFilterApps &&
                                    customView?.hideFilterGroup &&
                                    customView?.hideFilterCategory &&
                                    'customview'
                                }`}
                            >
                                {paginatedCatalogData.map((item: any, index: number) => {
                                    return (
                                        <div className="catalog-result-item" key={index}>
                                            <CatalogItem
                                                data={item}
                                                customView={queryParameters.get('custom') ? true : false}
                                                customAccentColor={customView?.accentColor}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    {!customView?.hidePagination && (
                        <div className="row justify-content-center">
                            <div className="col-lg-12 button-bar">
                                <PageButtonNavBar
                                    currentPage={page}
                                    setPage={(value) => setPage(value)}
                                    maxPages={Math.ceil(filteredCatalogData.length / pageSize)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </Wrapper>
    );
};
export default CatalogPage;
