import React, { FC } from 'react';

interface Props {
    index: number;
    data: any;
    changeValueOption: (data: any) => void;
    submittingData: boolean;
}

const OptionNumber: FC<Props> = (props) => {
    return (
        <div className="option-number" key={props.index}>
            <div className="option-title">{props.data['optionText']}</div>
            <div className="input-field">
                <input
                    disabled={props.submittingData}
                    type="number"
                    value={props.data['optionValue']}
                    onChange={(e) => {
                        props.changeValueOption(e.target.value);
                    }}
                    placeholder={props.data['optionPlaceholder']}
                />
            </div>
        </div>
    );
};
export default OptionNumber;
