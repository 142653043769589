import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import API from './api';
import translationEN from '../languages/en.json';
import translationES from '../languages/es.json';
// import translationFR from '../languages/fr.json';
// import translationPT from '../languages/pt.json';

const resources = {
    'en-US': {
        translation: translationEN,
    },
    'es-ES': {
        translation: translationES,
    },
    // 'fr-FR': {
    //     translation: translationFR,
    // },
    // 'pt-BR': {
    //     translation: translationPT,
    // },
};

var language = API.instance.getCookie('language') !== undefined ? API.instance.getCookie('language') : 'en-US';

i18n.use(initReactI18next as any).init({
    resources: resources,
    lng: language,
    fallbackLng: 'en-US',

    keySeparator: false,

    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
