import React, { FC } from 'react';
import '../../style/style.scss';
import 'react-day-picker/dist/style.css';
import { RgbStringColorPicker } from 'react-colorful';

interface Props {
    show: boolean;
    value: string;
    onConfirm: (newVal?: any) => void;
    onClose: () => void;
}

const DatePickerComponent: FC<Props> = (props) => {
    return props.show ? (
        <div className="color-picker-wrapper">
            <RgbStringColorPicker color={props.value} onChange={(value) => props.onConfirm(value)} />
        </div>
    ) : null;
};

export default DatePickerComponent;
