import React, { FC, useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import '../../style/style.scss';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import Menuclose from '../../images/menu-mobile-cross.png';
import 'react-day-picker/dist/style.css';

const APIManager = API.instance;
interface Props {
    show: boolean;
    onClose: () => void;
    title?: string;
    description?: string;
    //preview image:
    previewUrl?: (string | boolean)[];
    //show QR:
    qrCode?: string;
    //alert buttons
    confirmText?: string;
    onConfirm?: () => void;
    cancel?: boolean;
}

const PreviewModal: FC<Props> = (props) => {
    const [previewURL, setPreviewURL] = useState<(string | boolean)[]>([]);
    const [qrCode, setQRCode] = useState('');

    useEffect(() => {
        if (props.show) {
            if (props.previewUrl) {
                setPreviewURL([
                    APIManager.getDirectusItemURL(props.previewUrl[0] as string),
                    props.previewUrl[1] as boolean,
                ]);
            }
            if (props.qrCode) {
                setQRCode(props.qrCode);
            }
        } else {
            setPreviewURL([]);
            setQRCode('');
        }
    }, [props.show]);

    const renderPreview = () => {
        return previewURL[0] !== undefined ? (
            previewURL[1] !== undefined && previewURL[1] == true ? (
                <video
                    autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    className="preview-image"
                    style={{ objectFit: 'contain' }}
                >
                    <source src={previewURL[0] as string} />
                </video>
            ) : (
                <img src={previewURL[0] as string} alt={'preview'} className="preview-image" />
            )
        ) : (
            <></>
        );
    };

    return props.show ? (
        <div className="modal-wrapper">
            <div
                className={`dark-background open`}
                onClick={() => {
                    props.onClose();
                }}
            />
            <div className={`modal-alert open`}>
                <div className="close-container" onClick={() => props.onClose()}>
                    <img src={Menuclose} className="icon-close" alt="close" />
                </div>

                <div className="container inner-block">
                    {props.title && <span className="title">{props.title}</span>}
                    {props.description && <span className="text">{props.description}</span>}
                    {props.previewUrl && renderPreview()}
                    {props.qrCode && qrCode !== '' && (
                        <>
                            <QRCode size={250} style={{ background: 'white', padding: '16px' }} value={qrCode} />
                        </>
                    )}
                    {((props.confirmText && props.onConfirm) || props.cancel) && (
                        <div className="buttons-row">
                            {props.confirmText && (
                                <div className="confirm-button" onClick={() => props.onConfirm && props.onConfirm()}>
                                    {props.confirmText}
                                </div>
                            )}
                            {props.cancel && (
                                <div className="cancel-button" onClick={() => props.onClose()}>
                                    {I18n.t('ALERT_CANCEL') as string}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : null;
};

export default PreviewModal;
