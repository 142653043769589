import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import '../../../style/style.scss';
import I18n from '../../../helpers/i18n';
import API from '../../../helpers/api';
import Link from '../../../components/link';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import PreviewModal from '../../../components/modal/modal';
import {
    updateCustomData,
    updateCustomDataReset,
    getCustomData,
    getCustomDataReset,
    uploadRequest,
    uploadDataReset,
    deleteFileRequest,
    deleteFileReset,
} from '../../../helpers/actions/redux-data-actions';
import ColorPicker from '../../../components/colorpicker/colorpicker';
import OutsideClickHandler from 'react-outside-click-handler';

const APIManager = API.instance;
interface Props {
    active: boolean;
}

const CustomSharePage: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [customLink, setCustomLink] = useState('');
    const [copyButtonText, setCopyButtonText] = useState(I18n.t('CUSTOM_LINK_COPY') as string);
    const [file, setFile] = useState<any>('');
    const clientID = APIManager.getCookie('user_id');
    const [toastId, setToastId] = useState<number | string>(null as any);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [customDataID, setCustomDataID] = useState(null);
    const [currentLogo, setCurrentLogo] = useState('');
    const [accentColor, setAccentColor] = useState('rgb(205,43,56)');
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [uploadingFile, setUploadingFile] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [updatingItem, setUpdatingItem] = useState(false);

    const deleteItemState = useSelector((state: RootStateOrAny) => state.dataReducers.deleteState);
    const customDataState = useSelector((state: RootStateOrAny) => state.dataReducers.customDataState);
    const customData = useSelector((state: RootStateOrAny) => state.dataReducers.customData);

    const uploadState = useSelector((state: RootStateOrAny) => state.dataReducers.uploadState);
    const uploadData = useSelector((state: RootStateOrAny) => state.dataReducers.uploadData);
    const uploadError = useSelector((state: RootStateOrAny) => state.dataReducers.uploadError);

    const updateCustomDataState = useSelector((state: RootStateOrAny) => state.dataReducers.updateCustomDataState);
    const updateCustomDataError = useSelector((state: RootStateOrAny) => state.dataReducers.updateCustomDataError);

    useEffect(() => {
        let baseURL = window.location.host;
        let customURL = 'https://' + baseURL + '/catalog?custom=' + clientID;
        setCustomLink(customURL);

        if (props.active) {
            dispatch(uploadDataReset());
            setLoadingData(true);
            dispatch(getCustomData(APIManager.getCookie('user_id')));
            dispatch(deleteFileReset());
        } else {
            dispatch(getCustomDataReset());
            dispatch(updateCustomDataReset());
            dispatch(uploadDataReset());
            dispatch(deleteFileReset());
        }
    }, [props.active]);

    useEffect(() => {
        if (loadingData) {
            if (customDataState === 'success') {
                setLoadingData(false);
                if (customData.length > 0) {
                    if (customData[0].id) {
                        setCustomDataID(customData[0].id);
                    }
                    if (customData[0].brand_logo && customData[0].brand_logo !== '') {
                        setCurrentLogo(customData[0].brand_logo);
                    } else {
                        setCurrentLogo('');
                    }
                    if (customData[0].accent_color) {
                        setAccentColor(customData[0].accent_color);
                    }
                }
            } else if (customDataState === 'error') {
                setLoadingData(false);
                toast(I18n.t('ERROR_3') as string);
            }
        }
    }, [customDataState]);

    const uploadLogo = () => {
        if (file !== '') {
            setUploadingFile(true);
            setToastId(toast.loading((I18n.t('CUST_UPLOAD_START') as string) + ' '));
            const formData = new FormData();
            formData.append('folder', APIManager.getUploadFolder());
            formData.append('blob', file, file.name);
            dispatch(uploadRequest(formData));
        }
    };

    useEffect(() => {
        if (uploadingFile) {
            if (uploadState === 'success') {
                dispatch(uploadDataReset());
                setUploadingFile(false);
                setUpdatingItem(true);
                if (uploadData.id) {
                    let data = {
                        user: clientID,
                        brand_logo: uploadData.id,
                    };
                    dispatch(updateCustomData(data, customDataID));
                }
            } else if (uploadState === 'error') {
                dispatch(uploadDataReset());
                setUploadingFile(false);
                toast.update(toastId, {
                    render:
                        uploadError.message !== undefined
                            ? uploadError.message
                            : (I18n.t('CUST_UPLOAD_FAILURE') as string),
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                });
            }
        }
    }, [uploadState]);

    useEffect(() => {
        if (updatingItem) {
            if (updateCustomDataState === 'success') {
                dispatch(updateCustomDataReset());
                setUpdatingItem(false);
                toast.update(toastId, {
                    render: I18n.t('CUST_UPLOAD_SUCCESS') as string,
                    type: 'success',
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                setFile('');
                dispatch(getCustomData(APIManager.getCookie('user_id')));
                setLoadingData(true);
            } else if (updateCustomDataState === 'error') {
                dispatch(updateCustomDataReset());
                setUpdatingItem(false);
                toast(I18n.t('ERROR_3') as string);
            }
        }
    }, [updateCustomDataState]);

    useEffect(() => {
        if (deleteInProgress) {
            if (deleteItemState === 'success') {
                setUpdatingItem(true);
                let data = {
                    user: clientID,
                    brand_logo: null,
                };
                dispatch(updateCustomData(data, customDataID));
                toast(I18n.t('TOAST_FILE_DELETE_SUCCESS') as string);
                setDeleteInProgress(false);
            } else if (deleteItemState === 'error') {
                setDeleteInProgress(false);
                toast(I18n.t('ERROR_3') as string);
            }
        }
    }, [deleteItemState]);

    const copyText = () => {
        if (customLink !== '') {
            setCopyButtonText(I18n.t('CUSTOM_LINK_COPIED') as string);
            navigator.clipboard.writeText(customLink);
            setTimeout(() => {
                setCopyButtonText(I18n.t('CUSTOM_LINK_COPY') as string);
            }, 1000);
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const deleteLogo = () => {
        dispatch(deleteFileReset());
        setShowDeleteModal(false);
        setDeleteInProgress(true);
        dispatch(deleteFileRequest(currentLogo));
    };

    const saveColor = () => {
        dispatch(uploadDataReset());
        setUpdatingItem(true);

        let data = {
            user: clientID,
            accent_color: accentColor,
        };
        dispatch(updateCustomData(data, customDataID));
    };

    return (
        <section className="section-custom-sharing">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="title">{I18n.t('MENU_CUSTOM_LINK') as string}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="text">
                            {I18n.t('CUSTOM_LINK_EXPLANATION')
                                .split('\n')
                                .map((str) => (
                                    <span className="text-block">{str}</span>
                                ))}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-4">
                        <span className="subtitle mb-3">{I18n.t('CUSTOM_LINK') as string}:</span>
                        <Link path={customLink} onClick={() => navigate('/catalog?custom=' + clientID, { state: {} })}>
                            <span className="custom-link">{customLink}</span>
                        </Link>
                        <span className="button mt-3" onClick={() => copyText()}>
                            {copyButtonText}
                        </span>
                    </div>
                </div>
            </div>
            {!loadingData && (
                <div className="container mt-4">
                    <div className="row m-0">
                        <div className="col-12 col-xl-8 table-col -first">
                            <div className="subtitle">{I18n.t('CUSTOM_LINK_ACCENT_COLOR') as string}</div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-12 col-xl-8 table-col">
                            <div
                                className="color-block"
                                style={{ backgroundColor: accentColor }}
                                onClick={() => setShowColorPicker(true)}
                            >
                                {showColorPicker && (
                                    <OutsideClickHandler
                                        onOutsideClick={() => {
                                            setShowColorPicker(false);
                                            saveColor();
                                        }}
                                    >
                                        <div className="option-color-picker">
                                            <ColorPicker
                                                show={showColorPicker}
                                                onClose={() => setShowColorPicker(false)}
                                                value={accentColor}
                                                onConfirm={(value) => {
                                                    setAccentColor(value);
                                                }}
                                            />
                                        </div>
                                    </OutsideClickHandler>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 mt-3">
                        <div className="col-12 col-xl-8 table-col -first">
                            <div className="subtitle">{I18n.t('CUSTOM_LINK_UPLOAD_LOGO') as string}</div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-12 col-xl-8 table-col">
                            {currentLogo ? (
                                <>
                                    <img
                                        src={APIManager.getDirectusDownloadURL(currentLogo)}
                                        className={'currentLogo'}
                                        alt={I18n.t('CUSTOM_CURRENT_LOGO') as string}
                                    />
                                    <span className={`button -delete mt-3`} onClick={() => setShowDeleteModal(true)}>
                                        {I18n.t('CUSTOM_LINK_DELETE_LOGO') as string}
                                    </span>
                                </>
                            ) : (
                                <input id="file" type="file" onChange={handleFileChange} />
                            )}
                        </div>
                    </div>
                    {!currentLogo && (
                        <span
                            className={`button ${file == '' ? '-disabled' : '-upload'} mt-3`}
                            onClick={() => uploadLogo()}
                        >
                            {I18n.t('CUSTOM_LINK_UPLOAD') as string}
                        </span>
                    )}
                </div>
            )}

            <ToastContainer
                position="top-right"
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                theme="dark"
            />
            <PreviewModal
                show={showDeleteModal}
                onClose={() => {
                    setShowDeleteModal(false);
                }}
                title={I18n.t('ALERT_DELETE_TITLE')}
                description={I18n.t('ALERT_DELETE_DESCRIPTION')}
                cancel
                confirmText={I18n.t('ALERT_DELETE_TITLE')}
                onConfirm={() => deleteLogo()}
            />
        </section>
    );
};

export default CustomSharePage;
