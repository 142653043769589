import React, { useState, FC, useEffect } from 'react';
import Wrapper from '../../components/wrapper/wrapper';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { getCatalogSamples, getFavoriteCatalogSamples } from '../../helpers/actions/redux-data-actions';
import { getPageHome } from '../../helpers/actions/redux-page-actions';
import I18n from '../../helpers/i18n';
import htmr from 'htmr';
import '../../style/style.scss';
import CatalogItem from '../../components/catalog/item';
import PwResetModal from '../../components/pwreset/pwreset';
import API from '../../helpers/api';
import Underline from '../../images/menu-selected.png';
import CheckPurple from '../../images/check-purple.png';
import CheckOrange from '../../images/check-orange.png';
import CheckGreen from '../../images/check-green.png';
import Link from '../../components/link';

const APIManager = API.instance;

interface Props {}

const MainPage: FC<Props> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParameters = new URLSearchParams(window.location.search);

    const [seoTitle, setSeoTitle] = useState('');
    const [seoDesc, setSeoDesc] = useState('');
    const [sampleData, setSampleData] = useState([]);
    const [favoriteData, setFavoriteData] = useState([]);
    const [features, setFeatures] = useState<string[]>([]);

    const [token, setToken] = useState('');
    const [pwResetModalVisible, setPwResetModalVisible] = useState(false);

    const pageData = useSelector((state: RootStateOrAny) => state.pageReducers.homeData);

    const catalogSamplesState = useSelector((state: RootStateOrAny) => state.dataReducers.catalogSamplesState);
    const catalogSamplesData = useSelector((state: RootStateOrAny) => state.dataReducers.catalogSamplesData);

    const favoriteSamplesState = useSelector((state: RootStateOrAny) => state.dataReducers.favoriteSamplesState);
    const favoriteSamplesData = useSelector((state: RootStateOrAny) => state.dataReducers.favoriteSamplesData);

    useEffect(() => {
        dispatch(getPageHome());
        dispatch(getCatalogSamples());
        dispatch(getFavoriteCatalogSamples());

        let token = queryParameters.get('token');
        if (token !== null && token !== '') {
            setToken(token);
        }
    }, []);

    useEffect(() => {
        if (Object.keys(pageData).length > 0) {
            setSeoTitle(pageData.seo_title);
            setSeoDesc(pageData.seo_description);

            var sentences = pageData.features;
            if (sentences !== null && sentences !== undefined && sentences !== '') {
                sentences = sentences.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/\n/g, '|').split('|');
                setFeatures(sentences);
            }
        }
    }, [pageData]);

    useEffect(() => {
        if (catalogSamplesState === 'success') {
            setSampleData(catalogSamplesData);
        }
    }, [catalogSamplesState]);

    useEffect(() => {
        if (favoriteSamplesState === 'success') {
            setFavoriteData(favoriteSamplesData);
        }
    }, [favoriteSamplesState]);

    useEffect(() => {
        if (token !== '') {
            setPwResetModalVisible(true);
        }
    }, [token]);

    const renderFeatures = () => {
        let renderObjects = [] as any;

        let iconCounter = 1;
        let icon;
        let featureCounter = 0;

        for (let feature of features) {
            if (iconCounter === 1) {
                icon = CheckGreen;
                iconCounter++;
            } else if (iconCounter === 2) {
                icon = CheckOrange;
                iconCounter++;
            } else if (iconCounter === 3) {
                icon = CheckPurple;
                iconCounter = 1;
            }
            featureCounter++;

            if (featureCounter % 2 === 0) {
                renderObjects.push(
                    <>
                        <div className="col-1"></div>
                        <div className="col-12 col-lg-3 feature-item">
                            <img src={icon} className="feature-icon" alt="sentence-icon" />
                            <div>{htmr(feature)}</div>
                        </div>
                        <div className="col-2"></div>
                    </>
                );
            } else {
                renderObjects.push(
                    <>
                        <div className="col-2"></div>
                        <div className="col-12 col-lg-3 feature-item">
                            <img src={icon} className="feature-icon" alt="sentence-icon" />
                            <div>{htmr(feature)}</div>
                        </div>
                        <div className="col-1"></div>
                    </>
                );
            }
        }

        return renderObjects;
    };

    const renderPromoVideoMobile = (link: string) => {
        if (link !== undefined && link !== null && link !== '') {
            return (
                <div className="promo-video-container-mobile">
                    <video
                        key={link}
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="promo-video-properties-mobile"
                        src={APIManager.getDirectusItemURL(link)}
                    />
                </div>
            );
        } else {
            return <></>;
        }
    };
    const renderPromoVideoDesktop = (link: string) => {
        if (link !== undefined && link !== null && link !== '') {
            return (
                <div className="promo-video-container-desktop">
                    <video
                        key={link}
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="promo-video-properties-desktop"
                        src={APIManager.getDirectusItemURL(link)}
                    />
                </div>
            );
        } else {
            return <></>;
        }
    };

    return (
        <Wrapper seo_title={seoTitle} seo_description={seoDesc}>
            <section className="section-main">
                <div className="container">
                    {pageData.background && (
                        <div
                            className="background-image"
                            style={{
                                backgroundImage: `url(${APIManager.getDirectusItemURL(pageData.background)})`,
                                height: 564,
                                width: '100%',
                            }}
                        />
                    )}
                    <div className="background-image-layer2" />
                    <div className="background-image-layer1" />

                    <div className="row intro-container justify-content-center">
                        <div className="col-lg-2" />
                        <div className="col-12 col-md-10 col-lg-5 intro-paragraph-container">
                            <div className="intro-paragraph-text">{pageData.text && htmr(pageData.text)}</div>
                            <div className="intro-button-container">
                                <Link
                                    button
                                    title={I18n.t('MAIN_BUTTON_CATALOG') as any}
                                    onClick={() => {
                                        APIManager.setCookie('catalog_filter', '', {});
                                        navigate('/catalog', { state: {} });
                                    }}
                                    path={'/catalog'}
                                />
                            </div>
                        </div>
                        <div className="col-lg-5">{renderPromoVideoDesktop(pageData?.promovideo)}</div>
                        <div className="col-lg-1" />
                    </div>
                </div>

                <div className="features-container">
                    <div className="container">
                        <div>
                            <span className="features-title">
                                {pageData.features_title && pageData.features_title}
                                <img src={Underline} className="underlined" alt="added-link-img" />
                            </span>
                        </div>
                        {renderPromoVideoMobile(pageData?.promovideo)}
                        <div className="features-paragraph">
                            {pageData.features_intro && htmr(pageData.features_intro)}
                        </div>
                        <div className="features-items-container">{renderFeatures()}</div>
                    </div>
                </div>

                <div className="favorite-items-container">
                    {favoriteData.length > 0 && (
                        <>
                            <div className="container">
                                <div className="sample-title-container">
                                    <span className="sample-title">
                                        {I18n.t('MAIN_FAV_TEXT1') as string}
                                        <span className="highlighted">
                                            {((' ' + I18n.t('MAIN_FAV_TEXT2')) as string) + ' '}
                                        </span>
                                        <img src={Underline} className="underlined" alt="added-link-img" />
                                    </span>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row flex sample-items">
                                    {favoriteData[0] && (
                                        <div className="sample-item">
                                            <CatalogItem data={favoriteData[0]} />
                                        </div>
                                    )}
                                    {favoriteData[1] && (
                                        <div className="sample-item">
                                            <CatalogItem data={favoriteData[1]} />
                                        </div>
                                    )}
                                    {favoriteData[2] && (
                                        <div className="sample-item">
                                            <CatalogItem data={favoriteData[2]} />
                                        </div>
                                    )}
                                    {favoriteData[3] && (
                                        <div className="sample-item">
                                            <CatalogItem data={favoriteData[3]} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    {sampleData.length > 0 && (
                        <>
                            <div className="container">
                                <div className="sample-title-container">
                                    <span className="sample-title">
                                        {I18n.t('MAIN_SAMPLE_TEXT') as string}
                                        <img src={Underline} className="underlined" alt="added-link-img" />
                                    </span>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row flex sample-items">
                                    {sampleData[0] && (
                                        <div className="sample-item">
                                            <CatalogItem data={sampleData[0]} />
                                        </div>
                                    )}
                                    {sampleData[1] && (
                                        <div className="sample-item">
                                            <CatalogItem data={sampleData[1]} />
                                        </div>
                                    )}
                                    {sampleData[2] && (
                                        <div className="sample-item">
                                            <CatalogItem data={sampleData[2]} />
                                        </div>
                                    )}
                                    {sampleData[3] && (
                                        <div className="sample-item">
                                            <CatalogItem data={sampleData[3]} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    <div className="container">
                        <div className="row goto-catalog">
                            <div
                                className="col-12"
                                onClick={() => {
                                    APIManager.setCookie('catalog_filter', '', {});
                                    navigate('/catalog', { state: {} });
                                }}
                            >
                                <span className="link-text">{I18n.t('MAIN_NAV_CATALOG') as string}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <PwResetModal
                    open={pwResetModalVisible}
                    token={token}
                    Close={() => {
                        setPwResetModalVisible(false);
                        setToken('');
                    }}
                />
            </section>
        </Wrapper>
    );
};
export default MainPage;
