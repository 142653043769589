import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import {
    getUserInfo,
    loginRequest,
    loginReset,
    createNewUser,
    newUserReset,
    passwordResetRequest,
} from '../../helpers/actions/redux-user-actions';
import '../../style/style.scss';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import Menuclose from '../../images/menu-mobile-cross.png';
import PasswordVisible from '../../images/visible.png';
import PasswordInvisible from '../../images/invisible.png';
import LoadingIcon from '../../images/loading.png';

const APIManager = API.instance;
interface Props {
    open: boolean;
    Close: () => void;
}

const LoginRegisterModal: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [screenOpen, setScreenOpen] = useState(false);

    const [loggingIn, setLoggingIn] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [resettingPw, setResettingPw] = useState(false);
    const [rotDeg, setRotDeg] = useState(0);

    const [seePassword, setSeePassword] = useState(false);
    const [seeRegPassword, setSeeRegPassword] = useState(false);

    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [loginStep, setloginStep] = useState(1);

    const [emailReg, setEmailReg] = useState('');
    const [passwordReg, setPasswordReg] = useState('');
    const [firstnameReg, setFirstnameReg] = useState('');
    const [lastnameReg, setLastnameReg] = useState('');
    const [regStep, setRegStep] = useState(1);

    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorRegFirstname, setErrorRegFirstname] = useState(false);
    const [errorRegLastname, setErrorRegLastname] = useState(false);
    const [errorRegEmail, setErrorRegEmail] = useState(false);
    const [errorRegPassword, setErrorRegPassword] = useState(false);
    const [errorFieldLogin, setErrorFieldLogin] = useState('');
    const [errorFieldReg, setErrorFieldReg] = useState('');

    const [resetEmail, setResetEmail] = useState('');
    const [errorResetEmail, setErrorResetEmail] = useState(false);

    const loginState = useSelector((state: RootStateOrAny) => state.userReducers.loginState);

    const regState = useSelector((state: RootStateOrAny) => state.userReducers.createNewUserState);
    const regError = useSelector((state: RootStateOrAny) => state.userReducers.createNewUserError);

    const pwResetRequestState = useSelector((state: RootStateOrAny) => state.userReducers.pwResetRequestState);

    const getUserState = useSelector((state: RootStateOrAny) => state.userReducers.getUserState);
    const userData = useSelector((state: RootStateOrAny) => state.userReducers.userData);

    useEffect(() => {
        if (screenOpen) {
            if (pwResetRequestState === 'success') {
                setResettingPw(false);
                setloginStep(3);
            }
        }
    }, [pwResetRequestState]);

    useEffect(() => {
        if (loggingIn) {
            setLoggingIn(false);

            if (APIManager.isLoggedIn()) {
                dispatch(getUserInfo());
            }
        }
    }, [APIManager.isLoggedIn()]);

    useEffect(() => {
        if (screenOpen) {
            if (getUserState === 'success') {
                APIManager.setCookie('user_id', userData.id, { maxAge: 604800 });
                props.Close();
                navigate('/account', { state: {} });
            } else if (getUserState === 'error') {
                props.Close();
                navigate('/', { state: {} });
            }
        }
    }, [getUserState]);

    useEffect(() => {
        if (screenOpen) {
            if (loginState === 'success') {
                dispatch(loginReset());
            } else if (loginState === 'error') {
                setLoggingIn(false);
                dispatch(loginReset());
                setErrorFieldLogin(I18n.t('LOGIN_ERROR') as string);
            }
        }
    }, [loginState]);

    useEffect(() => {
        if (screenOpen) {
            if (regState === 'success') {
                setRegistering(false);
                setRegStep(2);
                dispatch(newUserReset());
            } else if (regState === 'error') {
                setRegistering(false);
                dispatch(newUserReset());
                if (regError.status !== undefined && regError.status === 400) {
                    setErrorFieldReg(I18n.t('REG_ERROR') as string);
                } else {
                    setErrorFieldReg(I18n.t('ERROR_MSG') as string);
                }
            }
        }
    }, [regState]);

    useEffect(() => {
        if (props.open) {
            setScreenOpen(true);
        } else {
            setScreenOpen(false);
        }
    }, [props.open]);

    useEffect(() => {
        if (props.open && !screenOpen) {
            resetData();
            props.Close();
        }
    }, [screenOpen]);

    useEffect(() => {
        if (registering || loggingIn || resettingPw) {
            setTimeout(() => {
                setRotDeg(rotDeg < 360 ? rotDeg + 45 : 45);
            }, 100);
        }
    }, [rotDeg, registering, loggingIn, resettingPw]);

    const resetData = () => {
        setLoggingIn(false);
        setRegistering(false);
        setResettingPw(false);
        setloginStep(1);
        setRegStep(1);
        setRotDeg(0);

        setLoginEmail('');
        setLoginPassword('');
        setFirstnameReg('');
        setLastnameReg('');
        setEmailReg('');
        setPasswordReg('');

        setErrorEmail(false);
        setErrorPassword(false);
        setErrorRegFirstname(false);
        setErrorRegLastname(false);
        setErrorRegEmail(false);
        setErrorRegPassword(false);
        setErrorFieldLogin('');
        setErrorFieldReg('');

        dispatch(loginReset());
        dispatch(newUserReset());
    };

    useEffect(() => {
        setErrorEmail(false);
        setErrorFieldLogin('');
    }, [loginEmail]);

    useEffect(() => {
        setErrorPassword(false);
        setErrorFieldLogin('');
    }, [loginPassword]);

    useEffect(() => {
        setErrorRegFirstname(false);
    }, [firstnameReg]);

    useEffect(() => {
        setErrorRegLastname(false);
    }, [lastnameReg]);

    useEffect(() => {
        setErrorRegEmail(false);
        setErrorFieldReg('');
    }, [emailReg]);

    useEffect(() => {
        setErrorRegPassword(false);
        setErrorFieldReg('');
    }, [passwordReg]);

    useEffect(() => {
        setErrorResetEmail(false);
        setErrorFieldLogin('');
    }, [resetEmail]);

    const checkValidLoginData = () => {
        let issueFound = false;

        if (loginEmail === '') {
            issueFound = true;
            setErrorEmail(true);
        }
        if (loginPassword === '') {
            issueFound = true;
            setErrorPassword(true);
        }

        return !issueFound;
    };

    const checkValidRegistrationData = () => {
        let issueFound = false;

        if (firstnameReg === '') {
            issueFound = true;
            setErrorRegFirstname(true);
        }
        if (lastnameReg === '') {
            issueFound = true;
            setErrorRegLastname(true);
        }
        if (emailReg === '') {
            issueFound = true;
            setErrorRegEmail(true);
        } else if (!APIManager.isValidEmail(emailReg)) {
            issueFound = true;
            setErrorRegEmail(true);
            setErrorFieldReg(I18n.t('INVALID_FORMAT_EMAIL') as string);
        }
        if (passwordReg === '') {
            issueFound = true;
            setErrorRegPassword(true);
        } else if (!APIManager.isValidPassword(passwordReg)) {
            issueFound = true;
            setErrorRegPassword(true);
            setErrorFieldReg(I18n.t('INVALID_FORMAT_PASSWORD') as string);
        }

        return !issueFound;
    };

    const startRegistration = () => {
        if (checkValidRegistrationData()) {
            setRegistering(true);

            dispatch(
                createNewUser({
                    first_name: firstnameReg,
                    last_name: lastnameReg,
                    email: emailReg,
                    password: passwordReg,
                    language: APIManager.getUserLanguage(),
                })
            );
        }
    };

    const startLogin = () => {
        if (checkValidLoginData()) {
            setLoggingIn(true);

            dispatch(
                loginRequest({
                    email: loginEmail,
                    password: loginPassword,
                })
            );
        }
    };

    const startResetPassword = () => {
        if (APIManager.isValidEmail(resetEmail)) {
            setResettingPw(true);

            dispatch(
                passwordResetRequest({
                    email: resetEmail,
                })
            );
        } else {
            if (resetEmail !== '') {
                setErrorFieldLogin(I18n.t('INVALID_FORMAT_EMAIL') as string);
            }
            setErrorResetEmail(true);
        }
    };

    const renderLoginBlock = () => {
        return (
            <div className="col-12 col-lg-6 login-block">
                {loginStep === 1 ? (
                    <div>
                        <div className="title">{I18n.t('LOGIN_TITLE') as string}</div>
                        <div className="field-error">{errorFieldLogin !== '' && errorFieldLogin}</div>

                        <div className="input-email-text">{I18n.t('LOGIN_NAME') as string}</div>
                        <div className={`input-email-field ${errorEmail && 'error'}`}>
                            <input
                                name="input-email"
                                type="email"
                                value={loginEmail}
                                onChange={(e) => setLoginEmail(e.target.value)}
                                tabIndex={1}
                            />
                        </div>
                        {errorEmail && <div className="error-text">{I18n.t('FIELD_ERROR') as string}</div>}
                        <div className="input-password-text">{I18n.t('LOGIN_PASSWORD') as string}</div>
                        <div className={`input-password-field ${errorPassword && 'error'}`}>
                            <input
                                name="input-password"
                                type={seePassword ? 'text' : 'password'}
                                value={loginPassword}
                                onChange={(e) => setLoginPassword(e.target.value)}
                                tabIndex={2}
                                onKeyDown={(event) => {
                                    if (event.code === 'Enter') {
                                        startLogin();
                                    }
                                }}
                            />
                            <img
                                src={seePassword ? PasswordVisible : PasswordInvisible}
                                className={`icon-password ${seePassword && 'visible'}`}
                                alt="menu"
                                onClick={() => setSeePassword(!seePassword)}
                            />
                            <div
                                className="input-password-text-forgot"
                                onClick={() => {
                                    setResetEmail(loginEmail);
                                    setloginStep(2);
                                }}
                                onKeyDown={(event) => {
                                    if (event.code === 'Enter') {
                                        setResetEmail(loginEmail);
                                        setloginStep(2);
                                    }
                                }}
                                tabIndex={4}
                            >
                                {I18n.t('LOGIN_FORGET') as string}
                            </div>
                        </div>
                        {errorPassword && <div className="error-text">{I18n.t('FIELD_ERROR') as string}</div>}

                        <div
                            className={`login-button ${errorPassword && 'error'}`}
                            onClick={() => startLogin()}
                            onKeyDown={(event) => {
                                if (event.code === 'Enter') {
                                    startLogin();
                                }
                            }}
                            tabIndex={3}
                        >
                            {loggingIn ? (
                                <img
                                    src={LoadingIcon}
                                    className="loading-icon"
                                    style={{
                                        rotate: rotDeg + 'deg',
                                    }}
                                    alt=""
                                />
                            ) : (
                                (I18n.t('LOGIN_TITLE') as string)
                            )}
                        </div>
                    </div>
                ) : loginStep === 2 ? (
                    <div>
                        <div className="title">{I18n.t('RESET_PW_TITLE') as string}</div>
                        <div className="field-error">{errorFieldLogin !== '' && errorFieldLogin}</div>

                        <div className="reset-email-text">{I18n.t('RESET_PW_TEXT') as string}</div>
                        <div className={`reset-email-field ${errorResetEmail && 'error'}`}>
                            <input
                                name="reset-email"
                                type="text"
                                value={resetEmail}
                                onChange={(e) => setResetEmail(e.target.value)}
                                autoComplete="new-off"
                                tabIndex={5}
                                onKeyDown={(event) => {
                                    if (event.code === 'Enter') {
                                        startResetPassword();
                                    }
                                }}
                            />
                        </div>
                        {errorResetEmail && <div className="error-text">{I18n.t('FIELD_ERROR') as string}</div>}
                        <div
                            className="reset-button"
                            onClick={() => startResetPassword()}
                            onKeyDown={(event) => {
                                if (event.code === 'Enter') {
                                    startResetPassword();
                                }
                            }}
                            tabIndex={6}
                        >
                            {resettingPw ? (
                                <img
                                    src={LoadingIcon}
                                    className="loading-icon"
                                    style={{
                                        rotate: rotDeg + 'deg',
                                    }}
                                    alt=""
                                />
                            ) : (
                                (I18n.t('RESET_PW_BUTTON') as string)
                            )}
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="title">{I18n.t('RESET_PW_TITLE') as string}</div>
                        <div className="field-error">{errorFieldLogin !== '' && errorFieldLogin}</div>

                        <div className="reset-email-text">{I18n.t('RESET_PW_DONE') as string}</div>
                        <div className="reset-button" onClick={() => setloginStep(1)}>
                            {I18n.t('OK') as string}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderRegisterBlock = () => {
        return (
            <div className="col-12 col-lg-6 register-block">
                <div className="title">{I18n.t('REGISTER_TITLE') as string}</div>
                <div className="field-error">{errorFieldReg}</div>

                {regStep === 1 ? (
                    <div>
                        <div className="d-flex justify-content-between">
                            <div className="namecol">
                                <div className="register-firstname-text">{I18n.t('REGISTER_FIRSTNAME') as string}</div>
                                <div className={`register-firstname-field ${errorRegFirstname && 'error'}`}>
                                    <input
                                        name="input-firstname"
                                        type="text"
                                        value={firstnameReg}
                                        onChange={(e) => setFirstnameReg(e.target.value)}
                                        autoComplete="new-password"
                                        tabIndex={7}
                                    />
                                </div>
                            </div>
                            <div className="namecol">
                                <div className="register-lastname-text">{I18n.t('REGISTER_LASTNAME') as string}</div>
                                <div className={`register-lastname-field ${errorRegLastname && 'error'}`}>
                                    <input
                                        name="input-lastname"
                                        type="text"
                                        value={lastnameReg}
                                        onChange={(e) => setLastnameReg(e.target.value)}
                                        autoComplete="new-password"
                                        tabIndex={8}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="name-error-block">
                            {(errorRegFirstname || errorRegLastname) && (
                                <div className="error-text">{I18n.t('FIELD_ERROR') as string}</div>
                            )}
                        </div>

                        <div className="register-email-text">{I18n.t('LOGIN_NAME') as string}</div>
                        <div className={`register-email-field ${errorRegEmail && 'error'}`}>
                            <input
                                name="input-email"
                                type="text"
                                value={emailReg}
                                onChange={(e) => setEmailReg(e.target.value)}
                                autoComplete="new-password"
                                tabIndex={9}
                            />
                        </div>
                        {errorRegEmail && <div className="error-text">{I18n.t('FIELD_ERROR') as string}</div>}
                        <div className="register-password-text">{I18n.t('LOGIN_PASSWORD') as string}</div>
                        <div className={`register-password-field ${errorRegPassword && 'error'}`}>
                            <input
                                name="input-password"
                                type={seeRegPassword ? 'text' : 'password'}
                                value={passwordReg}
                                onChange={(e) => setPasswordReg(e.target.value)}
                                autoComplete="new-password"
                                tabIndex={10}
                            />
                            <img
                                src={seeRegPassword ? PasswordVisible : PasswordInvisible}
                                className={`icon-password ${seeRegPassword && 'visible'}`}
                                alt="menu"
                                onClick={() => setSeeRegPassword(!seeRegPassword)}
                            />
                        </div>
                        {errorRegPassword && <div className="error-text">{I18n.t('FIELD_ERROR') as string}</div>}
                        <div className="register-disclaimer">
                            {I18n.t('REGISTER_DISCLAIMER_TEXT') as string}
                            <span
                                className="register-disclaimer-link"
                                onClick={() => navigate('/privacy', { state: {} })}
                            >
                                {(I18n.t('MENU_PRIVACY') as string) + '.'}
                            </span>
                        </div>
                        <div
                            className="register-button"
                            onClick={() => startRegistration()}
                            onKeyDown={(event) => {
                                if (event.code === 'Enter') {
                                    startRegistration();
                                }
                            }}
                            tabIndex={11}
                        >
                            {registering ? (
                                <img
                                    src={LoadingIcon}
                                    className="loading-icon"
                                    style={{
                                        rotate: rotDeg + 'deg',
                                    }}
                                    alt=""
                                />
                            ) : (
                                (I18n.t('REGISTER_TITLE') as string)
                            )}
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="reg-success-title">{I18n.t('REG_SUCCESS_TITLE') as string}</div>
                        <div className="reg-success-text">{I18n.t('REG_SUCCESS_TEXT') as string}</div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <div className={`dark-background ${screenOpen && 'open'}`} onClick={() => setScreenOpen(!screenOpen)} />

            <div className={`modal-login-register ${screenOpen && 'open'}`}>
                <div className="row d-flex">
                    <div className="icon-close-container" onClick={() => setScreenOpen(!screenOpen)}>
                        <img src={Menuclose} className="icon-close" alt="menu" />
                    </div>
                    {renderLoginBlock()}
                    {renderRegisterBlock()}
                </div>
            </div>
        </div>
    );
};

export default LoginRegisterModal;
