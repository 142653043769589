import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { passwordReset, resetPasswordReset } from '../../helpers/actions/redux-user-actions';
import '../../style/style.scss';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import Menuclose from '../../images/menu-mobile-cross.png';
import LoadingIcon from '../../images/loading.png';
import PasswordVisible from '../../images/visible.png';
import PasswordInvisible from '../../images/invisible.png';

const APIManager = API.instance;
interface Props {
    open: boolean;
    token: string;
    Close: () => void;
}

const PwResetModal: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [step, setStep] = useState(1);
    const [screenOpen, setScreenOpen] = useState(false);
    const [rotating, setRotating] = useState(false);
    const [rotDeg, setRotDeg] = useState(0);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [seePassword, setSeePassword] = useState(false);
    const [errorField, setErrorField] = useState('');

    const pwResetState = useSelector((state: RootStateOrAny) => state.userReducers.pwResetState);
    const pwResetError = useSelector((state: RootStateOrAny) => state.userReducers.pwResetError);

    useEffect(() => {
        if (screenOpen) {
            if (pwResetState === 'success') {
                setRotating(false);
                dispatch(resetPasswordReset());
                setStep(2);
            } else if (pwResetState === 'error') {
                setRotating(false);
                dispatch(resetPasswordReset());
                setStep(3);
                if (pwResetError !== '' && pwResetError.status !== undefined) {
                    if (pwResetError.status === 404) {
                        setErrorField(
                            ((I18n.t('RESET_PW_FAILURE_EXPIRED') as string) +
                                ' ' +
                                I18n.t('RESET_PW_FAILURE_RETURN')) as string
                        );
                    } else {
                        setErrorField(
                            ((I18n.t('ERROR_MSG') as string) + ' ' + I18n.t('RESET_PW_FAILURE_RETURN')) as string
                        );
                    }
                } else {
                    setErrorField(I18n.t('ERROR_MSG') as string);
                }
            }
        }
    }, [pwResetState]);

    useEffect(() => {
        if (rotating) {
            setTimeout(() => {
                setRotDeg(rotDeg < 360 ? rotDeg + 45 : 45);
            }, 100);
        }
    }, [rotDeg, rotating]);

    useEffect(() => {
        if (props.open) {
            setScreenOpen(true);
        } else {
            setScreenOpen(false);
        }
    }, [props.open]);

    useEffect(() => {
        if (props.open && !screenOpen) {
            setRotating(false);
            props.Close();
        }
    }, [screenOpen]);

    useEffect(() => {
        setErrorEmail(false);
    }, [email]);

    useEffect(() => {
        setErrorPassword(false);
    }, [password]);

    const checkEnteredData = () => {
        if (!APIManager.isValidPassword(password)) {
            setErrorPassword(true);
        }
        if (!APIManager.isValidEmail(email)) {
            setErrorEmail(true);
        }

        if (APIManager.isValidPassword(password) && APIManager.isValidEmail(email)) {
            setRotating(true);
            dispatch(
                passwordReset({
                    token: props.token,
                    email: email,
                    password: password,
                })
            );
        }
    };

    return (
        <div>
            <div className={`dark-background ${screenOpen && 'open'}`} onClick={() => setScreenOpen(!screenOpen)} />

            <div className={`modal-pwreset ${screenOpen && 'open'}`}>
                <img
                    src={Menuclose}
                    className="icon-close"
                    alt="menu"
                    onClick={() => setScreenOpen(!screenOpen)}
                    onKeyDown={(event) => {
                        if (event.code === 'Enter') {
                            setScreenOpen(!screenOpen);
                        }
                    }}
                />
                <div className="container pwreset-block">
                    <div className="row justify-content-center">
                        {step === 1 && (
                            <div>
                                <div className="title">{I18n.t('RESET_PW_BUTTON') as string}</div>
                                <div className="text">{I18n.t('RESET_PW_CONFIM_TEXT') as string}</div>

                                <div className="input-email-text">{I18n.t('LOGIN_NAME') as string}</div>
                                <div className={`input-email-field ${errorEmail && 'error'}`}>
                                    <input
                                        name="input-email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        tabIndex={1}
                                    />
                                </div>
                                {errorEmail && <div className="error-text">{I18n.t('FIELD_ERROR') as string}</div>}
                                <div className="input-password-text">{I18n.t('NEW_PW') as string}</div>
                                <div className={`input-password-field ${errorPassword && 'error'}`}>
                                    <input
                                        name="input-password"
                                        type={seePassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        tabIndex={2}
                                        onKeyDown={(event) => {
                                            if (event.code === 'Enter') {
                                                checkEnteredData();
                                            }
                                        }}
                                    />
                                    <img
                                        src={seePassword ? PasswordVisible : PasswordInvisible}
                                        className={`icon-password ${seePassword && 'visible'}`}
                                        alt="menu"
                                        onClick={() => setSeePassword(!seePassword)}
                                    />
                                </div>
                                {errorPassword && <div className="error-text">{I18n.t('FIELD_ERROR') as string}</div>}

                                <div
                                    className="button-reset"
                                    onClick={() => checkEnteredData()}
                                    onKeyDown={(event) => {
                                        if (event.code === 'Enter') {
                                            checkEnteredData();
                                        }
                                    }}
                                    tabIndex={3}
                                >
                                    {rotating ? (
                                        <img
                                            src={LoadingIcon}
                                            className="loading-icon"
                                            style={{
                                                rotate: rotDeg + 'deg',
                                            }}
                                            alt=""
                                        />
                                    ) : (
                                        (I18n.t('RESET_PW_BUTTON') as string)
                                    )}
                                </div>
                                <div>
                                    <span
                                        className="text-underlined"
                                        onClick={() => setScreenOpen(!screenOpen)}
                                        onKeyDown={(event) => {
                                            if (event.code === 'Enter') {
                                                setScreenOpen(!screenOpen);
                                            }
                                        }}
                                        tabIndex={4}
                                    >
                                        {I18n.t('CANCEL') as string}
                                    </span>
                                </div>
                            </div>
                        )}
                        {step === 2 && (
                            <div>
                                <div className="title">{I18n.t('RESET_PW_BUTTON') as string}</div>
                                <div className="text">{I18n.t('RESET_PW_SUCCESS') as string}</div>
                                <div
                                    className="button-reset"
                                    onClick={() => {
                                        props.Close();
                                        navigate('/', {
                                            state: {
                                                showLoginModal: true,
                                            },
                                        });
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.code === 'Enter') {
                                            props.Close();
                                            navigate('/', {
                                                state: {
                                                    showLoginModal: true,
                                                },
                                            });
                                        }
                                    }}
                                    tabIndex={5}
                                >
                                    {I18n.t('OK') as string}
                                </div>
                                <div>
                                    <span
                                        className="text-underlined"
                                        onClick={() => props.Close()}
                                        onKeyDown={(event) => {
                                            if (event.code === 'Enter') {
                                                props.Close();
                                            }
                                        }}
                                        tabIndex={7}
                                    >
                                        {I18n.t('CANCEL') as string}
                                    </span>
                                </div>
                            </div>
                        )}
                        {step === 3 && (
                            <div>
                                <div className="title">{I18n.t('RESET_PW_BUTTON') as string}</div>
                                <div className="text center">{errorField !== '' && errorField}</div>
                                <div
                                    className="button-reset"
                                    onClick={() => {
                                        props.Close();
                                        navigate('/', { state: {} });
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.code === 'Enter') {
                                            props.Close();
                                            navigate('/', { state: {} });
                                        }
                                    }}
                                    tabIndex={6}
                                >
                                    {I18n.t('OK') as string}
                                </div>
                                <div>
                                    <span
                                        className="text-underlined"
                                        onClick={() => setStep(1)}
                                        onKeyDown={(event) => {
                                            if (event.code === 'Enter') {
                                                setStep(1);
                                            }
                                        }}
                                        tabIndex={7}
                                    >
                                        {I18n.t('BACK') as string}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PwResetModal;
