import React, { FC, useState, useEffect } from 'react';
import '../../style/style.scss';
import I18n from '../../helpers/i18n';

interface Props {
    currentPage: number;
    maxPages: number;
    setPage: (page: number) => void;
}

const PageButtonNavBar: FC<Props> = (props) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const [page, setPage] = useState(
        queryParameters.get('page') !== null ? parseInt(queryParameters.get('page') as any, 10) : 1
    );

    useEffect(() => {
        setPage(props.currentPage);
    }, [props.currentPage]);

    useEffect(() => {
        if (page !== props.currentPage) {
            props.setPage(page);
            toTop();
        }
    }, [page]);

    const toTop = () => {
        window.scrollTo({
            top: 100,
            behavior: 'instant',
        } as any);
    };

    const renderItem = (text: any, style?: string) => {
        return (
            <div
                className={'button ' + style}
                onClick={() => {
                    style !== undefined && text !== '..'
                        ? setPage(text)
                        : (text as string) === (I18n.t('PREVIOUS') as string) && page > 1
                        ? setPage(page - 1)
                        : (text as string) === (I18n.t('NEXT') as string) && page < props.maxPages && setPage(page + 1);
                }}
            >
                {text}
            </div>
        );
    };

    const renderPageButtons = () => {
        let renderObjects = [] as any;

        if (props.maxPages > 1) {
            renderObjects.push(renderItem(I18n.t('PREVIOUS') as string));

            if (props.maxPages < 8) {
                for (let i = 1; i <= props.maxPages; i++) {
                    renderObjects.push(renderItem(i, page === i ? 'selected' : 'number'));
                }
            } else {
                renderObjects.push(renderItem(1, page === 1 ? 'selected' : 'number'));

                for (let i = 2; i < props.maxPages; i++) {
                    if (page < 6) {
                        if (i < 6) {
                            renderObjects.push(renderItem(i, page === i ? 'selected' : 'number'));
                        } else if (i === 6) {
                            renderObjects.push(renderItem('..', 'etc'));
                        }
                    } else if (page + 5 > props.maxPages && i !== props.maxPages) {
                        if (i + 5 > props.maxPages) {
                            renderObjects.push(renderItem(i, page === i ? 'selected' : 'number'));
                        } else if (i + 5 === props.maxPages) {
                            renderObjects.push(renderItem('..', 'etc'));
                        }
                    } else if (page > 5 && page < props.maxPages - 4) {
                        if (i === 2 || i + 1 === props.maxPages) {
                            renderObjects.push(renderItem('..', 'etc'));
                        } else if (
                            i > 4 &&
                            i < props.maxPages - 3 &&
                            (i - 1 === page || i === page || i + 1 === page)
                        ) {
                            renderObjects.push(renderItem(i, page === i ? 'selected' : 'number'));
                        }
                    }
                }

                renderObjects.push(renderItem(props.maxPages, page === props.maxPages ? 'selected' : 'number'));
            }

            renderObjects.push(renderItem(I18n.t('NEXT') as string));
        }

        return renderObjects;
    };

    return <div className="pages-button-container d-flex">{renderPageButtons()}</div>;
};

export default PageButtonNavBar;
