import React, { FC } from 'react';
import API from '../../../helpers/api';

const APIManager = API.instance;

interface Props {
    index: number;
    data: any;
    changeValueOption: (data: any, limit: number) => void;
    submittingData: boolean;
}

const OptionString: FC<Props> = (props) => {
    const defaultInputLimit = APIManager.getCustomizeFieldLimit();

    let currentLenght = props.data['optionValue'] !== undefined ? props.data['optionValue'].length : 0;
    let maxLimit = defaultInputLimit;

    if (props.data['optionType'].includes('string') && props.data['optionType'].split('-')[1] !== undefined) {
        maxLimit = props.data['optionType'].split('-')[1];
    }

    return (
        <div className="option-string" key={props.index}>
            <div className="option-title">{props.data['optionText']}</div>
            <div className="input-field">
                <input
                    disabled={props.submittingData}
                    type="text"
                    value={props.data['optionValue']}
                    onChange={(e) => props.changeValueOption(e.target.value, maxLimit)}
                    placeholder={props.data['optionPlaceholder']}
                />
                <span className="option-limit-text">{currentLenght + '/' + maxLimit}</span>
            </div>
        </div>
    );
};
export default OptionString;
