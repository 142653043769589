import React, { useEffect, FC } from 'react';
import CookieConsent from 'react-cookie-consent';
import I18n from '../helpers/i18n';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const Analytics: FC = (props) => {
    const location = useLocation();

    useEffect(() => {
        checkIfPermissionIsGiven();
    }, [location]);

    const checkIfPermissionIsGiven = () => {
        var cookie: any = Cookies.get('PixstudiosCookieConsentGiven');
        if (cookie !== undefined && (cookie === 'true' || cookie === true)) {
            trackData();
        } else {
            console.log('No cookie consent given, tracking disabled.');
        }
    };

    const trackData = () => {
        const TRACKING_ID = 'G-MH3D2LYZXN';
        ReactGA.initialize(TRACKING_ID);
        var tracking = window.location.pathname + window.location.search;

        ReactGA.send(tracking);
        //ReactGA.send({ hitType: 'pageview', page: tracking });
    };

    return (
        <CookieConsent
            location="bottom"
            cookieName="PixstudiosCookieConsentGiven"
            expires={150}
            acceptOnScroll={false}
            onAccept={() => {
                trackData();
            }}
            buttonText={I18n.t('COOKIE_BAR_BTN_ACCEPT') as string}
            declineButtonText={I18n.t('COOKIE_BAR_BTN_DECLINE') as string}
            enableDeclineButton
            declineCookieValue={'false'}
            hideOnAccept={true}
            buttonWrapperClasses={'buttons d-flex h-150 flex-column align-items-center justify-content-center'}
            flipButtons={true}
            containerClasses="cookie-consent-bar"
            contentClasses="content-wrapper"
        >
            <div className="cookie-bar">
                <span className="cookie-title">{I18n.t('COOKIE_BAR_TITLE') as string}</span>
                <span className="cookie-text">
                    {I18n.t('COOKIE_BAR_TEXT') + ' '}
                    <a href="/privacy">{I18n.t('COOKIE_BAR_LINK_TEXT') as string}</a>
                    {' ' + I18n.t('COOKIE_BAR_TEXT_TWO')}
                </span>
            </div>
        </CookieConsent>
    );
};

export default Analytics;
