import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../components/wrapper/wrapper';
import {
    getProducts,
    postCheckout,
    hasValidSubscription,
    hasValidSubscriptionReset,
} from '../../helpers/actions/redux-stripe-actions';
import { getPagePrice } from '../../helpers/actions/redux-page-actions';
import htmr from 'htmr';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import '../../style/style.scss';

import CheckPurple from '../../images/check-purple.png';
import CheckOrange from '../../images/check-orange.png';
import CheckGreen from '../../images/check-green.png';
import LoadingIcon from '../../images/loading.png';

const APIManager = API.instance;
interface Props {}

const PricingPage: FC<Props> = (props) => {
    interface priceObject {
        id: string;
        title: string;
        text: string;
        price: string;
    }
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDesc, setSeoDesc] = useState('');

    const [loadingData, setLoadingData] = useState(true);
    const [loadingPrices, setLoadingPrices] = useState(true);
    const [loadingLink, setLoadingLink] = useState(false);
    const [data, setData] = useState<priceObject[]>([]);
    const [subscriptionData, setSubscriptionData] = useState({ id: '', status: false });
    const [errorMessage, setErrorMessage] = useState('');

    const [rotDeg, setRotDeg] = useState(0);
    const [newWindow, setNewWindow] = useState(null as any);

    const pageData = useSelector((state: RootStateOrAny) => state.pageReducers.priceData);
    const productsState = useSelector((state: RootStateOrAny) => state.stripeReducers.productsState);
    const productsData = useSelector((state: RootStateOrAny) => state.stripeReducers.productsData);
    const checkoutState = useSelector((state: RootStateOrAny) => state.stripeReducers.checkoutState);
    const checkoutError = useSelector((state: RootStateOrAny) => state.stripeReducers.checkoutError);
    const checkoutData = useSelector((state: RootStateOrAny) => state.stripeReducers.checkoutData);
    const validSubState = useSelector((state: RootStateOrAny) => state.stripeReducers.validSubState);
    const validSubData = useSelector((state: RootStateOrAny) => state.stripeReducers.validSubData);

    useEffect(() => {
        dispatch(getPagePrice());
        dispatch(getProducts());
        if (APIManager.isLoggedIn()) {
            dispatch(hasValidSubscription());
        }

        return () => {
            dispatch(hasValidSubscriptionReset());
        };
    }, []);

    useEffect(() => {
        if (validSubState === 'success') {
            setSubscriptionData(validSubData);
        } else if (validSubState === 'error') {
            setSubscriptionData({ id: '', status: false });
            dispatch(hasValidSubscriptionReset());
        }
    }, [validSubState]);

    useEffect(() => {
        if (loadingLink) {
            if (checkoutState === 'success') {
                setLoadingLink(false);
                browserTabOpen(checkoutData);
            } else if (checkoutState === 'error') {
                if (checkoutError.errorText !== undefined) {
                    //setErrorMessage(I18n.t('STUCK') + ': ' + I18n.t('ORDER_ALREADY_SUB'));
                    setErrorMessage(I18n.t('STUCK') + ': ' + checkoutError.errorText);
                }
                setLoadingLink(false);
                browserTabClose();
            }
        }
    }, [checkoutState]);

    useEffect(() => {
        if (Object.keys(pageData).length > 0) {
            setSeoTitle(pageData.seo_title);
            setSeoDesc(pageData.seo_description);
            setLoadingData(false);
        }
    }, [pageData]);

    useEffect(() => {
        if (loadingPrices && productsState === 'success') {
            setLoadingPrices(false);

            for (let product of productsData) {
                let translatedData = APIManager.filterLanguageData(product);

                setData((oldArray) => [
                    ...oldArray,
                    {
                        id: product.id,
                        title: translatedData['product_name'],
                        text: translatedData['description'],
                        price: product.price,
                    },
                ]);
            }
        }
    }, [productsState]);

    useEffect(() => {
        if (loadingLink) {
            setTimeout(() => {
                setRotDeg(rotDeg < 360 ? rotDeg + 45 : 45);
            }, 100);
        }
    }, [rotDeg, loadingLink]);

    const browserTabOpen = (url: string) => {
        if (newWindow !== null && newWindow.location !== undefined) {
            setNewWindow((newWindow.location.href = url));
        }
    };

    const browserTabClose = () => {
        if (newWindow !== null) {
            setNewWindow(newWindow.close());
        }
    };

    const descriptionText = (text: any, icon: any) => {
        let renderObjects = [] as any;

        if (text !== null && text !== undefined && text !== '') {
            var sentences = text.replace(/\n/g, '|').split('|');

            for (let i = 0; i < sentences.length; i++) {
                if (sentences[i] !== '') {
                    sentences[i] = sentences[i].replace('<p>', '').replace('</p>', '');
                    renderObjects.push(
                        <div className="sentence-item" key={'item' + i}>
                            <img src={icon} className="sentence-item-icon" alt="sentence-icon" />
                            <div>{htmr(sentences[i])}</div>
                        </div>
                    );
                }
            }
        }

        return renderObjects;
    };

    const convertCurrency = (name: string, value: number) => {
        const symbols: Record<string, any> = {
            USD: '$',
            EUR: '€',
            GBP: '£',
            ILS: '₪',
            JPY: '¥',
            UAH: '₴',
        };
        if (symbols[name] !== undefined) {
            return symbols[name] + ' ' + value;
        } else {
            return value + ' ' + name;
        }
    };

    const renderPriceBlock = (index: number, item: any) => {
        //if (index > 2) return;

        let itemColour = index === 0 ? 'purple' : index === 1 ? 'orange' : index === 2 || index === 3 ? 'green' : '';
        let itemIcon =
            index === 0 ? CheckPurple : index === 1 ? CheckOrange : index === 2 || index === 3 ? CheckGreen : '';

        return (
            <div className="col-12 col-md-6 col-lg-4 item" key={index}>
                <div>
                    <div className="title-item">{item.title}</div>
                    <div className="divider-item" />
                    <div className="text-item">{descriptionText(item.text, itemIcon)}</div>
                </div>
                <div className="bottom-container">
                    <div className="price-item">{convertCurrency('USD', item.price)}</div>

                    {subscriptionData['id'] === item.id && subscriptionData['status'] === true ? (
                        <div className="owned-button">{I18n.t('OWNED') as string}</div>
                    ) : subscriptionData['id'] !== item.id && subscriptionData['status'] === true ? (
                        <div className="empty-buton"></div>
                    ) : (
                        <div
                            className={'purchase-button ' + itemColour}
                            onClick={() => {
                                if (!loadingLink) {
                                    if (APIManager.isLoggedIn()) {
                                        setErrorMessage('');
                                        setNewWindow(window.open('', '_blank'));
                                        setLoadingLink(true);
                                        dispatch(postCheckout({ product_id: item.id }));
                                    } else {
                                        navigate('/pricing', {
                                            state: {
                                                showLoginModal: true,
                                            },
                                        });
                                    }
                                }
                            }}
                        >
                            {loadingLink ? (
                                <img
                                    src={LoadingIcon}
                                    className="loading-icon"
                                    style={{
                                        rotate: rotDeg + 'deg',
                                    }}
                                    alt=""
                                />
                            ) : (
                                (I18n.t('PURCHASE') as string)
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <Wrapper seo_title={seoTitle} seo_description={seoDesc}>
            <section className="section-pricing">
                <div className="text-title">{!loadingData && pageData['title'] && htmr(pageData['title'])}</div>
                <div className="divider" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-9">
                            <div className="text-paragraph">
                                {!loadingData && pageData['text'] && htmr(pageData['text'])}
                            </div>
                        </div>
                    </div>
                </div>

                {!loadingData && !loadingPrices && (
                    <div className="container items">
                        <div className="error-text">{errorMessage !== '' && errorMessage}</div>
                        <div className="row d-flex justify-content-center">
                            {data.map((item: any, index: number) => {
                                return renderPriceBlock(index, item);
                            })}
                        </div>
                    </div>
                )}
            </section>
        </Wrapper>
    );
};
export default PricingPage;
