import axios from 'axios';
import Cookies from 'universal-cookie';
import moment from 'moment';

//VARIABLES
const apiURL = process.env.REACT_APP_API_URL;
const linkURL = process.env.REACT_APP_LINK_URL;
const newUserRoleId = process.env.REACT_APP_NEW_USER_ROLE_ID;
const availableLanguages = ['en-US', 'es-ES'];
const availableSizes = [
    null, //0 unused
    '1072 x 1072', //1
    '1072 x 1440', //2
    '1072 x 1920', //3
    '1440 x 1072', //4
    '1920 x 1072', //5
    '2732 x 2048', //6
    '2048 x 2732', //7
    null, //8
    null, //9
    null, //10
    '1080 x 1080', //11
    '1080 x 1440', //12
    '1080 x 1920', //13
    '1440 x 1080', //14
    '1920 x 1080', //15
];

//Customize page
const uploadFolderId = process.env.REACT_APP_UPLOAD_FOLDER_ID;
const uploadLimit = 10000000; //10MB
const uploadSupportedFileFormats = ['image/png'];
const customizeDefaultInputLimit = 20;
const customizeAvailableSizes = 5; //Directus Gallery Item size_1...size_5
const customizeAvailableOptions = 10; //Directus Gallery Item option1...option5
//Customize page

const api = axios.create({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
    timeout: 30000,
});

//FUNCTIONS
export default class API {
    static instance: API = new API();
    cookies = new Cookies();

    get = async (path: string) => {
        try {
            const { data } = await api.get(`${apiURL}` + path, {});
            if (data !== undefined) {
                return data;
            }
            return null;
        } catch (err: any) {
            return err;
        }
    };
    post = async (path: string, body: any) => {
        try {
            const { data } = await api.post(`${apiURL}` + path, body);
            if (data !== undefined) {
                return data;
            }
            return null;
        } catch (err: any) {
            return err;
        }
    };
    patch = async (path: string, body: any) => {
        try {
            const { data } = await api.patch(`${apiURL}` + path, body);
            if (data !== undefined) {
                return data;
            }
            return null;
        } catch (err: any) {
            return err;
        }
    };

    delete = async (path: string) => {
        try {
            const { data } = await api.delete(`${apiURL}` + path);
            if (data !== undefined) {
                return data;
            }
            return null;
        } catch (err: any) {
            return err;
        }
    };

    getDirectusItemURL = (id: string) => {
        return linkURL + 'assets/' + id;
    };
    getDirectusDownloadURL = (id: string) => {
        return linkURL + 'assets/' + id + '?download&access_token=' + this.cookies.get('access_token');
    };
    getNewUserRole = () => {
        return newUserRoleId as string;
    };
    getUploadFolder = () => {
        return uploadFolderId as string;
    };
    getUploadFileSizeLimit = () => {
        return uploadLimit as number;
    };
    getUploadFileFormats = () => {
        return uploadSupportedFileFormats as string[];
    };
    getCustomizeFieldLimit = () => {
        return customizeDefaultInputLimit as number;
    };
    getCustomizeMaxSizes = () => {
        return customizeAvailableSizes as number;
    };
    getCustomizeMaxOptions = () => {
        return customizeAvailableOptions as number;
    };
    getCatalogPreviewURL = (data: any, index: number) => {
        if (data['thumbfile' + index] !== undefined && data['thumbfile' + index] !== null) {
            let isVideo = false;

            if (data['ext' + index] !== undefined && data['ext' + index] !== null && data['ext' + index] == 'mp4') {
                isVideo = true;
            }

            return [linkURL + 'assets/' + data['thumbfile' + index], isVideo];
        } else {
            return ['', false];
        }
    };
    getCookie = (name: string) => {
        return this.cookies.get(name);
    };
    setCookie = (name: string, value: string, options: any) => {
        if (value === '') {
            this.cookies.remove(name);
        } else {
            this.cookies.set(name, value, options);
        }
    };
    isValidEmail = (value: string) => {
        return value.indexOf('@') !== -1 && value.indexOf('@') + 1 < value.length;
    };
    isValidPassword = (value: string) => {
        const rules = {
            capital: /(?=.*[A-Z])/,
            length: /(?=.{7,40}$)/,
            specialChar: /[ -/:-@[-`{-~]/,
            digit: /(?=.*[0-9])/,
        };

        return (
            rules.capital.test(value) &&
            rules.length.test(value) &&
            rules.specialChar.test(value) &&
            rules.digit.test(value)
        );
    };
    isLoggedIn = () => {
        if (this.cookies.get('access_token') !== undefined && this.cookies.get('refresh_token') !== undefined) {
            return true;
        } else {
            return false;
        }
    };
    hasUserID = () => {
        if (this.cookies.get('user_id') !== undefined) {
            return true;
        } else {
            return false;
        }
    };
    refreshPossible = () => {
        if (this.cookies.get('refresh_token') !== undefined) {
            return true;
        } else {
            return false;
        }
    };
    expiredDate = (date: string) => {
        return moment().isAfter(date, 'day');
    };
    resetUserData = () => {
        this.cookies.remove('access_token');
        this.cookies.remove('refresh_token');
        this.cookies.remove('user_id');
        this.cookies.remove('catalog_filter');
    };
    getAvailableSizes = () => {
        return availableSizes as string[];
    };
    getAvailableLanguages = () => {
        return availableLanguages as string[];
    };
    getUserLanguage = () => {
        let userLanguage =
            this.cookies.get('language') !== undefined ? this.cookies.get('language') : navigator.language;
        return userLanguage;
    };
    filterLanguageData = (data: any) => {
        if (
            data !== null &&
            data !== undefined &&
            data.translations !== undefined &&
            data.translations !== null &&
            data.translations[0] !== undefined
        ) {
            let userLanguage = this.getUserLanguage();
            let languageId = 0;

            for (let i = 0; i < data.translations.length; i++) {
                if (
                    data.translations[i].languages_code !== undefined &&
                    data.translations[i].languages_code === userLanguage
                ) {
                    languageId = i;
                }
            }

            return data.translations[languageId];
        } else {
            return data;
        }
    };
    filterErrorData = (data: any) => {
        let response = {} as any;

        if (data.code !== undefined) {
            response['code'] = data.code;
        }
        if (data.message !== undefined) {
            response['message'] = data.message;
        }
        if (data.response !== undefined) {
            if (data.response.status !== undefined) {
                response['status'] = data.response.status;
            }
            if (data.response.statusText !== undefined) {
                response['statusText'] = data.response.statusText;
            }
            if (data.response.data !== undefined && data.response.data.errors !== undefined) {
                response['errorTextArray'] = data.response.data.errors;
            }
            if (data.response.data !== undefined && data.response.data.message !== undefined) {
                response['errorText'] = data.response.data.message;
            }
        }

        return response;
    };
}
