export const userConstants = {
    LOGIN: 'LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGIN_RESET: 'LOGIN_RESET',

    REFRESH_TOKEN: 'REFRESH_TOKEN',
    REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
    REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',
    REFRESH_TOKEN_RESET: 'REFRESH_TOKEN_RESET',

    LOGOUT: 'LOGOUT',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_ERROR: 'LOGOUT_ERROR',
    LOGOUT_RESET: 'LOGOUT_RESET',

    CREATE_NEW_USER: 'CREATE_NEW_USER',
    CREATE_NEW_USER_SUCCESS: 'CREATE_NEW_USER_SUCCESS',
    CREATE_NEW_USER_ERROR: 'CREATE_NEW_USER_ERROR',
    CREATE_NEW_USER_RESET: 'CREATE_NEW_USER_RESET',

    GET_USER_INFO: 'GET_USER_INFO',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_ERROR: 'GET_USER_INFO_ERROR',
    GET_USER_INFO_RESET: 'GET_USER_INFO_RESET',

    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
    UPDATE_USER_INFO_SUCCESS: 'UPDATE_USER_INFO_SUCCESS',
    UPDATE_USER_INFO_ERROR: 'UPDATE_USER_INFO_ERROR',
    UPDATE_USER_INFO_RESET: 'UPDATE_USER_INFO_RESET',

    PASSWORD_RESET_REQUEST: 'PASSWORD_RESET_REQUEST',
    PASSWORD_RESET_REQUEST_SUCCESS: 'PASSWORD_RESET_REQUEST_SUCCESS',

    PASSWORD_RESET: 'PASSWORD_REQUEST',
    PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
    PASSWORD_RESET_ERROR: 'PASSWORD_RESET_ERROR',
    PASSWORD_RESET_RESET: 'PASSWORD_REQUEST_RESET',

    POST_RENDER_REQUEST: 'POST_RENDER_REQUEST',
    POST_RENDER_REQUEST_SUCCESS: 'POST_RENDER_REQUEST_SUCCESS',
    POST_RENDER_REQUEST_ERROR: 'POST_RENDER_REQUEST_ERROR',
    POST_RENDER_REQUEST_RESET: 'POST_RENDER_REQUEST_RESET',

    GET_USER_RENDER_REQUESTS: 'GET_USER_RENDER_REQUESTS',
    GET_USER_RENDER_REQUESTS_SUCCESS: 'GET_USER_RENDER_REQUESTS_SUCCESS',
    GET_USER_RENDER_REQUESTS_ERROR: 'GET_USER_RENDER_REQUESTS_ERROR',
    GET_USER_RENDER_REQUESTS_RESET: 'GET_USER_RENDER_REQUESTS_RESET',

    GET_RENDER_PROGRESS: 'GET_RENDER_PROGRESS',
    GET_RENDER_PROGRESS_SUCCESS: 'GET_RENDER_PROGRESS_SUCCESS',
    GET_RENDER_PROGRESS_ERROR: 'GET_RENDER_PROGRESS_ERROR',
    GET_RENDER_PROGRESS_RESET: 'GET_RENDER_PROGRESS_RESET',

    DELETE_STATE: 'DELETE_STATE',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_ERROR: 'DELETE_ERROR',
    DELETE_RESET: 'DELETE_RESET',

    USER_LIMIT_REACHED_STATE: 'USER_LIMIT_REACHED_STATE',
    USER_LIMIT_REACHED_SUCCESS: 'USER_LIMIT_REACHED_SUCCESS',
    USER_LIMIT_REACHED_ERROR: 'USER_LIMIT_REACHED_ERROR',
    USER_LIMIT_REACHED_RESET: 'USER_LIMIT_REACHED_RESET',
};
