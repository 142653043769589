import { userConstants } from '../constants/redux-user-constants';

import API from '../api';
const APIManager = API.instance;
const defaultErrorObject = {
    code: '404',
    message: 'No data found',
};

//LOGIN
export const loginRequest = (data: any) => {
    return (dispatch: any) => {
        dispatch(loginStart());

        APIManager.post('auth/login', data).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(
                    loginFailure({
                        message: response.message,
                        code: response.code,
                    })
                );
            } else {
                if (response.data !== null && response.data !== undefined) {
                    APIManager.setCookie('access_token', response.data.access_token, {
                        maxAge: response.data.expires / 1000,
                    });
                    APIManager.setCookie('refresh_token', response.data.refresh_token, { maxAge: 604800 });
                    dispatch(loginSuccess({}));
                } else {
                    dispatch(loginFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const loginStart = () => {
    return {
        type: userConstants.LOGIN,
    };
};
export const loginSuccess = (data: any) => {
    return {
        type: userConstants.LOGIN_SUCCESS,
        payload: data,
    };
};
export const loginFailure = (data: any) => {
    return {
        type: userConstants.LOGIN_ERROR,
        payload: data,
    };
};
export const loginReset = () => {
    return {
        type: userConstants.LOGIN_RESET,
    };
};

//Refresh token
export const refreshTokenRequest = () => {
    return (dispatch: any) => {
        dispatch(loginStart());

        APIManager.post('auth/refresh', {
            refresh_token: APIManager.getCookie('refresh_token'),
        }).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(
                    refreshTokenFailure({
                        message: response.message,
                        code: response.code,
                    })
                );
            } else {
                if (response.data !== null && response.data !== undefined) {
                    APIManager.setCookie('access_token', response.data.access_token, {
                        maxAge: response.data.expires / 1000,
                    });
                    APIManager.setCookie('refresh_token', response.data.refresh_token, { maxAge: 604800 });
                    dispatch(refreshTokenSuccess({}));
                } else {
                    dispatch(refreshTokenFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const refreshTokenStart = () => {
    return {
        type: userConstants.REFRESH_TOKEN,
    };
};
export const refreshTokenSuccess = (data: any) => {
    return {
        type: userConstants.REFRESH_TOKEN_SUCCESS,
        payload: data,
    };
};
export const refreshTokenFailure = (data: any) => {
    return {
        type: userConstants.REFRESH_TOKEN_ERROR,
        payload: data,
    };
};
export const refreshTokenReset = () => {
    return {
        type: userConstants.REFRESH_TOKEN_RESET,
    };
};

//LOGOUT
export const logoutRequest = () => {
    return (dispatch: any) => {
        dispatch(logoutStart());

        APIManager.post('auth/logout', {
            refresh_token: APIManager.getCookie('refresh_token'),
        }).then((response) => {
            APIManager.resetUserData();

            if (response.message !== null && response.message !== undefined) {
                dispatch(
                    logoutFailure({
                        message: response.message,
                        code: response.code,
                    })
                );
            } else {
                dispatch(logoutSuccess({}));
            }
        });
    };
};
export const logoutStart = () => {
    return {
        type: userConstants.LOGOUT,
    };
};
export const logoutSuccess = (data: any) => {
    return {
        type: userConstants.LOGOUT_SUCCESS,
        payload: data,
    };
};
export const logoutFailure = (data: any) => {
    return {
        type: userConstants.LOGOUT_ERROR,
        payload: data,
    };
};
export const logoutReset = () => {
    return {
        type: userConstants.LOGOUT_RESET,
    };
};

//CREATE NEW USER
export const createNewUser = (data: any) => {
    return (dispatch: any) => {
        dispatch(newUserStart());

        data['role'] = APIManager.getNewUserRole();

        APIManager.post('users', data).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(newUserFailure(APIManager.filterErrorData(response)));
            } else {
                dispatch(newUserSuccess());
            }
        });
    };
};
export const newUserStart = () => {
    return {
        type: userConstants.CREATE_NEW_USER,
    };
};
export const newUserSuccess = () => {
    return {
        type: userConstants.CREATE_NEW_USER_SUCCESS,
    };
};
export const newUserFailure = (data: any) => {
    return {
        type: userConstants.CREATE_NEW_USER_ERROR,
        payload: data,
    };
};
export const newUserReset = () => {
    return {
        type: userConstants.CREATE_NEW_USER_RESET,
    };
};

//GET USER INFO
export const getUserInfo = () => {
    return (dispatch: any) => {
        dispatch(getUserInfoStart());

        let link = 'users/me?access_token=' + APIManager.getCookie('access_token');
        link += '&fields=id,email,first_name,last_name,language';

        APIManager.get(link).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getUserInfoFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    dispatch(getUserInfoSuccess(response.data));
                } else {
                    dispatch(getUserInfoFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getUserInfoStart = () => {
    return {
        type: userConstants.GET_USER_INFO,
    };
};
export const getUserInfoSuccess = (data: any) => {
    return {
        type: userConstants.GET_USER_INFO_SUCCESS,
        payload: data,
    };
};
export const getUserInfoFailure = (data: any) => {
    return {
        type: userConstants.GET_USER_INFO_ERROR,
        payload: data,
    };
};
export const getUserInfoReset = () => {
    return {
        type: userConstants.GET_USER_INFO_RESET,
    };
};

//UPDATE USER INFO
export const updateUserInfo = (body: any) => {
    return (dispatch: any) => {
        dispatch(updateUserInfoStart());

        let link = 'users/me?access_token=' + APIManager.getCookie('access_token');
        link += '&fields=id,email,first_name,last_name,language';

        APIManager.patch(link, body).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(updateUserInfoFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    dispatch(updateUserInfoSuccess());
                } else {
                    dispatch(updateUserInfoFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const updateUserInfoStart = () => {
    return {
        type: userConstants.UPDATE_USER_INFO,
    };
};
export const updateUserInfoSuccess = () => {
    return {
        type: userConstants.UPDATE_USER_INFO_SUCCESS,
    };
};
export const updateUserInfoFailure = (data: any) => {
    return {
        type: userConstants.UPDATE_USER_INFO_ERROR,
        payload: data,
    };
};
export const updateUserInfoReset = () => {
    return {
        type: userConstants.UPDATE_USER_INFO_RESET,
    };
};

//REQUEST PASSWORD RESET
export const passwordResetRequest = (data: any) => {
    return (dispatch: any) => {
        dispatch(passwordResetRequestStart());

        APIManager.post('forgot-password/forgot', data).then((response) => {
            dispatch(passwordResetRequestSuccess());
        });
    };
};
export const passwordResetRequestStart = () => {
    return {
        type: userConstants.PASSWORD_RESET_REQUEST,
    };
};
export const passwordResetRequestSuccess = () => {
    return {
        type: userConstants.PASSWORD_RESET_REQUEST_SUCCESS,
    };
};

//PASSWORD RESET
export const passwordReset = (data: any) => {
    return (dispatch: any) => {
        dispatch(passwordResetStart());

        APIManager.post('forgot-password/reset', JSON.stringify(data)).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(passwordResetFailure(APIManager.filterErrorData(response)));
            } else {
                dispatch(passwordResetSuccess());
            }
        });
    };
};
export const passwordResetStart = () => {
    return {
        type: userConstants.PASSWORD_RESET,
    };
};
export const passwordResetSuccess = () => {
    return {
        type: userConstants.PASSWORD_RESET_SUCCESS,
    };
};
export const passwordResetFailure = (data: any) => {
    return {
        type: userConstants.PASSWORD_RESET_ERROR,
        payload: data,
    };
};
export const resetPasswordReset = () => {
    return {
        type: userConstants.PASSWORD_RESET_RESET,
    };
};

//POST RENDER REQUEST IN QUEUE
export const renderRequest = (data: any) => {
    return (dispatch: any) => {
        dispatch(renderRequestStart());

        let link = 'items/queue?access_token=' + APIManager.getCookie('access_token');
        APIManager.post(link, JSON.stringify(data)).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(renderRequestFailure(APIManager.filterErrorData(response)));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    dispatch(renderRequestSuccess(response.data));
                } else {
                    dispatch(renderRequestSuccess(response));
                }
            }
        });
    };
};
export const renderRequestStart = () => {
    return {
        type: userConstants.POST_RENDER_REQUEST,
    };
};
export const renderRequestSuccess = (data: any) => {
    return {
        type: userConstants.POST_RENDER_REQUEST_SUCCESS,
        payload: data,
    };
};
export const renderRequestFailure = (data: any) => {
    return {
        type: userConstants.POST_RENDER_REQUEST_ERROR,
        payload: data,
    };
};
export const renderRequestReset = () => {
    return {
        type: userConstants.POST_RENDER_REQUEST_RESET,
    };
};

//GET user requests open in rendering QUEUE
export const getUserRenderRequests = () => {
    return (dispatch: any) => {
        dispatch(getUserRenderRequestsStart());

        let link =
            'items/queue?access_token=' +
            APIManager.getCookie('access_token') +
            '&filter[user][_eq]=' +
            APIManager.getCookie('user_id') +
            '&fields=*' +
            '&fields=gallery.*' +
            '&sort=-date_created' +
            '&filter[status][_neq]=deleted';

        APIManager.get(link).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getUserRenderRequestsFailure({ message: response.message, code: response.code }));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    dispatch(getUserRenderRequestsSuccess(response.data));
                } else {
                    dispatch(getUserRenderRequestsFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getUserRenderRequestsStart = () => {
    return {
        type: userConstants.GET_USER_RENDER_REQUESTS,
    };
};
export const getUserRenderRequestsSuccess = (data: any) => {
    return {
        type: userConstants.GET_USER_RENDER_REQUESTS_SUCCESS,
        payload: data,
    };
};
export const getUserRenderRequestsFailure = (data: any) => {
    return {
        type: userConstants.GET_USER_RENDER_REQUESTS_ERROR,
        payload: data,
    };
};
export const getUserRenderRequestsReset = () => {
    return {
        type: userConstants.GET_USER_RENDER_REQUESTS_RESET,
    };
};

//GET specific user request (ID) in rendering QUEUE
export const getRenderProgress = (id: number) => {
    return (dispatch: any) => {
        dispatch(getRenderProgressStart());

        let link =
            'items/queue?access_token=' +
            APIManager.getCookie('access_token') +
            '&filter[id][_eq]=' +
            id +
            '&filter[user][_eq]=' +
            APIManager.getCookie('user_id') +
            '&limit=1';

        APIManager.get(link).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(getRenderProgressFailure(APIManager.filterErrorData(response)));
            } else {
                if (
                    response.data !== null &&
                    response.data !== undefined &&
                    response.data[0] !== null &&
                    response.data[0] !== undefined
                ) {
                    dispatch(getRenderProgressSuccess(response.data[0]));
                } else {
                    dispatch(getRenderProgressFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const getRenderProgressStart = () => {
    return {
        type: userConstants.GET_RENDER_PROGRESS,
    };
};
export const getRenderProgressSuccess = (data: any) => {
    return {
        type: userConstants.GET_RENDER_PROGRESS_SUCCESS,
        payload: data,
    };
};
export const getRenderProgressFailure = (data: any) => {
    return {
        type: userConstants.GET_RENDER_PROGRESS_ERROR,
        payload: data,
    };
};
export const getRenderProgressReset = () => {
    return {
        type: userConstants.GET_RENDER_PROGRESS_RESET,
    };
};

//PATCH status request(ID), soft delete
export const deleteRequest = (id: number) => {
    return (dispatch: any) => {
        dispatch(deleteRequestStart());

        let link = `items/queue/${id}?access_token=` + APIManager.getCookie('access_token');

        APIManager.patch(link, { status: 'deleted' }).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(deleteRequestFailure(APIManager.filterErrorData(response)));
            } else {
                if (response.data !== null && response.data !== undefined) {
                    dispatch(deleteRequestSuccess(response.data));
                } else {
                    dispatch(deleteRequestFailure(defaultErrorObject));
                }
            }
        });
    };
};
export const deleteRequestStart = () => {
    return {
        type: userConstants.DELETE_STATE,
    };
};
export const deleteRequestSuccess = (data: any) => {
    return {
        type: userConstants.DELETE_SUCCESS,
        payload: data,
    };
};
export const deleteRequestFailure = (data: any) => {
    return {
        type: userConstants.DELETE_ERROR,
        payload: data,
    };
};
export const deleteRequestReset = () => {
    return {
        type: userConstants.DELETE_RESET,
    };
};

//Check User processing limit
export const checkUserLimit = () => {
    return (dispatch: any) => {
        dispatch(checkUserLimitStart());

        let link = 'check-limits?access_token=' + APIManager.getCookie('access_token');

        APIManager.get(link).then((response) => {
            if (response.message !== null && response.message !== undefined) {
                dispatch(checkUserLimitFailure(APIManager.filterErrorData(response)));
            } else if (response.remain) {
                dispatch(checkUserLimitSuccess(response));
            } else {
                dispatch(checkUserLimitFailure(defaultErrorObject));
            }
        });
    };
};
export const checkUserLimitStart = () => {
    return {
        type: userConstants.USER_LIMIT_REACHED_STATE,
    };
};
export const checkUserLimitSuccess = (data: any) => {
    return {
        type: userConstants.USER_LIMIT_REACHED_SUCCESS,
        payload: data,
    };
};
export const checkUserLimitFailure = (data: any) => {
    return {
        type: userConstants.USER_LIMIT_REACHED_ERROR,
        payload: data,
    };
};
export const checkUserLimitReset = () => {
    return {
        type: userConstants.USER_LIMIT_REACHED_RESET,
    };
};
