import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import {
    postStripeURL,
    postStripeURLReset,
    setAutoRenewal,
    setAutoRenewalReset,
    getSubscriptions,
    getSubscriptionsReset,
} from '../../../helpers/actions/redux-stripe-actions';
import '../../../style/style.scss';
import I18n from '../../../helpers/i18n';
import API from '../../../helpers/api';
import moment from 'moment';
import LoadingIcon from '../../../images/loading.png';

const APIManager = API.instance;
interface Props {
    active: boolean;
    id: string;
}

const AccountOrderDetails: FC<Props> = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [loadingURL, setLoadingURL] = useState(false);
    const [loadingAuto, setLoadingAuto] = useState(false);

    const [rotDeg, setRotDeg] = useState(0);
    const [newWindow, setNewWindow] = useState(null as any);
    const [data, setData] = useState({} as any);
    const [expired, setExpired] = useState(true);
    const [errorField, setErrorField] = useState('');

    const getSubsState = useSelector((state: RootStateOrAny) => state.stripeReducers.getSubsState);
    const getSubsData = useSelector((state: RootStateOrAny) => state.stripeReducers.getSubsData);
    const stripeDataURLState = useSelector((state: RootStateOrAny) => state.stripeReducers.stripeDataURLState);
    const stripeDataURLData = useSelector((state: RootStateOrAny) => state.stripeReducers.stripeDataURLData);
    const renewalState = useSelector((state: RootStateOrAny) => state.stripeReducers.renewalState);

    const toTop = () => {
        window.scrollTo({
            top: 100,
            behavior: 'instant',
        } as any);
    };

    useEffect(() => {
        if (props.active) {
            if (props.id) {
                setLoading(true);
                dispatch(getSubscriptions(props.id));
            } else {
                setErrorField(I18n.t('ERROR_MSG') as string);
            }
        } else {
            dispatch(getSubscriptionsReset());
            dispatch(postStripeURLReset());
            dispatch(setAutoRenewalReset());
        }
    }, [props.active]);

    useEffect(() => {
        if (loading) {
            if (getSubsState === 'success') {
                setLoadingAuto(false);
                setLoading(false);
                if (getSubsData !== null && getSubsData !== undefined && getSubsData.length > 0) {
                    setData(getSubsData[0]);
                } else {
                    setErrorField(I18n.t('ERROR_MSG') as string);
                }
            } else if (getSubsState === 'error') {
                setLoadingAuto(false);
                setLoading(false);
                setErrorField(I18n.t('ERROR_MSG') as string);
            }
        }
    }, [getSubsState]);

    useEffect(() => {
        if (
            Object.keys(data).length > 0 &&
            data['status'] !== null &&
            data['status'] !== undefined &&
            data['status'] !== 'finalized'
        ) {
            setExpired(false);
        }
    }, [data]);

    useEffect(() => {
        if (loadingURL) {
            if (stripeDataURLState === 'success') {
                setLoadingURL(false);
                browserTabOpen(stripeDataURLData);
            } else if (stripeDataURLState === 'error') {
                toTop();
                setErrorField(I18n.t('ERROR_MSG') as string);
                setLoadingURL(false);
                browserTabClose();
            }
        }
    }, [stripeDataURLState]);

    useEffect(() => {
        if (loadingAuto) {
            if (renewalState === 'success') {
                setLoading(true);
                dispatch(getSubscriptions(data.stripe_id_subscription));
            } else if (renewalState === 'error') {
                toTop();
                setErrorField(I18n.t('ERROR_MSG') as string);
                setLoadingAuto(false);
            }
        }
    }, [renewalState]);

    useEffect(() => {
        if (loadingURL || loadingAuto) {
            setTimeout(() => {
                setRotDeg(rotDeg < 360 ? rotDeg + 45 : 45);
            }, 100);
        }
    }, [rotDeg, loadingURL, loadingAuto]);

    const browserTabOpen = (url: string) => {
        if (newWindow !== null && newWindow.location !== undefined) {
            setNewWindow((newWindow.location.href = url));
        }
    };

    const browserTabClose = () => {
        if (newWindow !== null) {
            setNewWindow(newWindow.close());
        }
    };

    const renderItemRow = (name: string, value: string) => {
        return (
            <div className="row details-row">
                <div className="col-5 col-lg-3 details-item-name details-text">{name}</div>
                <div className="col-7 col-lg-9 details-text">{value}</div>
            </div>
        );
    };

    const getProductName = (data: any) => {
        if (data !== null && data !== undefined && data.product !== undefined) {
            let translated = APIManager.filterLanguageData(data['product']);
            return translated['product_name'];
        }
        return '';
    };

    return (
        <section className="section-account-orderdetails">
            <div className="container">
                <div className="col-12">
                    <div className="title">{I18n.t('ACCOUNT_ORDER_DETAILS') as string}</div>
                    <div className="field-error">{errorField !== '' && errorField}</div>
                </div>
                {!loading && Object.keys(data).length > 0 && (
                    <div>
                        <div>
                            {renderItemRow(I18n.t('ACCOUNT_ITEM_PRODUCT'), getProductName(data))}
                            {renderItemRow(I18n.t('ACCOUNT_ITEM_STATUS'), data['status'])}

                            {renderItemRow(
                                I18n.t('ACCOUNT_ITEM_AUTO'),
                                data['automatic_renewal'] ? I18n.t('YES') : I18n.t('NO')
                            )}
                            {renderItemRow(
                                I18n.t('ACCOUNT_ITEM_START'),
                                moment.utc(data['date_created']).format('MMM DD, YYYY')
                            )}
                            {renderItemRow(
                                I18n.t('ACCOUNT_ITEM_END'),
                                moment.utc(data['expiration_time']).format('MMM DD, YYYY')
                            )}
                            {renderItemRow(
                                I18n.t('ACCOUNT_ITEM_UPDATE'),
                                moment.utc(data['date_updated']).format('MMM DD, YYYY - hh:mm A ')
                            )}
                            {renderItemRow(I18n.t('ACCOUNT_ITEM_ORDER'), data['id'])}
                            {data['user'] !== undefined &&
                                renderItemRow(
                                    I18n.t('ACCOUNT_ITEM_NAME'),
                                    data['user']['first_name'] + ' ' + data['user']['last_name']
                                )}
                            {data['user'] !== undefined &&
                                renderItemRow(I18n.t('ACCOUNT_ITEM_EMAIL'), data['user']['email'])}
                        </div>
                        {!expired && (
                            <div className="manage-buttons">
                                <div
                                    className="manage-button"
                                    onClick={() => {
                                        if (data.stripe_id_subscription && !loadingURL && !loadingAuto) {
                                            setNewWindow(window.open('', '_blank'));
                                            setLoadingURL(true);
                                            setErrorField('');
                                            dispatch(postStripeURL(data.stripe_id_subscription));
                                        }
                                    }}
                                >
                                    {loadingURL ? (
                                        <img
                                            src={LoadingIcon}
                                            className="loading-icon"
                                            style={{
                                                rotate: rotDeg + 'deg',
                                            }}
                                            alt=""
                                        />
                                    ) : (
                                        (I18n.t('ACCOUNT_ITEM_BUTTON_INFO') as string)
                                    )}
                                </div>
                                <div
                                    className="manage-button"
                                    onClick={() => {
                                        if (data.stripe_id_subscription && !loadingURL && !loadingAuto) {
                                            setLoadingAuto(true);
                                            setErrorField('');
                                            dispatch(
                                                setAutoRenewal(data.stripe_id_subscription, !data.automatic_renewal)
                                            );
                                        }
                                    }}
                                >
                                    {loadingAuto ? (
                                        <img
                                            src={LoadingIcon}
                                            className="loading-icon"
                                            style={{
                                                rotate: rotDeg + 'deg',
                                            }}
                                            alt=""
                                        />
                                    ) : (
                                        (I18n.t('ACCOUNT_ITEM_BUTTON_AUTO') as string)
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </section>
    );
};

export default AccountOrderDetails;
