import React, { useState, FC, useEffect } from 'react';
import Wrapper from '../../components/wrapper/wrapper';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { getRenderProgress, getRenderProgressReset } from '../../helpers/actions/redux-user-actions';
import { getPageProcessing } from '../../helpers/actions/redux-page-actions';
import API from '../../helpers/api';
import I18n from '../../helpers/i18n';
import htmr from 'htmr';
import QRCode from 'react-qr-code';
import '../../style/style.scss';
import ProgressIcon from '../../images/inprogress.png';
import syncErrorIcon from '../../images/sync-error-red.png';
import renderErrorIcon from '../../images/error-red.png';
import Link from '../../components/link';

const APIManager = API.instance;

interface Props {}

const ProcessingPage: FC<Props> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParameters = new URLSearchParams(window.location.search);
    const itemId = parseInt(queryParameters.get('id') as string);
    const refreshTime = 15000; //msec
    const downloadSectionId = '10'; //see account/account.tsx const sectionYourFiles

    const [seoTitle, setSeoTitle] = useState('');
    const [seoDesc, setSeoDesc] = useState('');

    const [downloadLink, setDownloadLink] = useState('');
    const [preview, setPreview] = useState<(string | boolean)[]>([]);
    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState(0);
    const [rotDeg, setRotDeg] = useState(0);
    const [processingMessage, setProcessingMessage] = useState('');
    const [renderCode, setRenderCode] = useState(null);
    const [randomMessagesArray, setRandomMessagesArray] = useState<string[]>([]);

    const pageDataState = useSelector((state: RootStateOrAny) => state.pageReducers.processingState);
    const processingPageData = useSelector((state: RootStateOrAny) => state.pageReducers.processingData);
    const renderProgressState = useSelector((state: RootStateOrAny) => state.userReducers.renderProgressState);
    const renderProgressData = useSelector((state: RootStateOrAny) => state.userReducers.renderProgressData);

    useEffect(() => {
        if (itemId !== null && itemId !== undefined) {
            setStep(1);
            dispatch(getRenderProgress(itemId));
        } else {
            setStep(3);
        }

        return () => {
            dispatch(getRenderProgressReset());
        };
    }, []);

    useEffect(() => {
        if (Object.keys(processingPageData).length > 0) {
            setSeoTitle(processingPageData.seo_title);
            setSeoDesc(processingPageData.seo_description);
            saveRandomMessages(processingPageData.messages);
        } else if (pageDataState !== 'success' && pageDataState !== 'error') {
            dispatch(getPageProcessing());
        }
    }, [processingPageData]);

    useEffect(() => {
        if (loading) {
            if (renderProgressState === 'success') {
                if (
                    renderProgressData.status === 'created' ||
                    renderProgressData.status === 'processing' ||
                    renderProgressData.status === 'render' ||
                    (renderProgressData.status === 'completed' && renderProgressData.file == null)
                ) {
                    dispatch(getRenderProgressReset());
                    setTimeout(() => {
                        dispatch(getRenderProgress(itemId));
                    }, refreshTime);
                } else if (renderProgressData.status === 'completed' && renderProgressData.file !== null) {
                    setLoading(false);
                    setStep(2);
                    setDownloadLink(APIManager.getDirectusDownloadURL(renderProgressData.file));
                    if (renderProgressData.thumb !== null) {
                        let isVideo =
                            renderProgressData.ext !== undefined &&
                            renderProgressData.ext !== null &&
                            renderProgressData.ext == 'mp4'
                                ? true
                                : false;
                        setPreview([APIManager.getDirectusItemURL(renderProgressData.thumb), isVideo]);
                    }
                } else {
                    //renderProgressData.status = error or canceled
                    setLoading(false);
                    setStep(4);
                    setRenderCode(renderProgressData.process_message);
                }
            } else if (renderProgressState === 'error') {
                setLoading(false);
                setStep(3);
            }
        }
    }, [renderProgressState]);

    useEffect(() => {
        if (loading && step === 1) {
            setTimeout(() => {
                setRotDeg(rotDeg < 360 ? rotDeg + 45 : 45);
            }, 100);
        }
    }, [rotDeg, loading, step]);

    useEffect(() => {
        if (randomMessagesArray.length > 0) {
            setRandomMessage();
        }
    }, [randomMessagesArray]);

    const saveRandomMessages = (data: any) => {
        let sentences = data.replace(/\n/g, '|').split('|');
        for (let i = 0; i < sentences.length; i++) {
            if (sentences[i] !== '') {
                sentences[i] = sentences[i].replace('<p>', '').replace('</p>', '');
            }
        }
        setRandomMessagesArray(sentences);
    };

    const setRandomMessage = () => {
        if (randomMessagesArray.length > 0) {
            setProcessingMessage(randomMessagesArray[Math.floor(Math.random() * randomMessagesArray.length)]);
        }
    };

    const decodeRenderCode = () => {
        if (renderCode !== null) {
            let decoded = JSON.parse(renderCode);

            if (typeof decoded == 'string') {
                return decoded;
            } else if (typeof decoded == 'object') {
                if (decoded.code !== undefined) {
                    switch (decoded.code) {
                        case 'RENDER_LIMIT':
                            return I18n.t('RENDER_LIMIT') as string;
                        case 'ERROR_1':
                            return I18n.t('ERROR_1') as string;
                        case 'ERROR_2':
                            return I18n.t('ERROR_2') as string;
                        default:
                            return decoded.code;
                    }
                }
            }
        }
        return I18n.t('ERROR_MSG') as string;
    };

    const renderPreview = () => {
        return preview[0] !== undefined ? (
            preview[1] !== undefined && preview[1] == true ? (
                <video
                    autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    className="preview-image"
                    style={{ objectFit: 'contain' }}
                >
                    <source src={preview[0] as string} />
                </video>
            ) : (
                <img src={preview[0] as string} alt={'preview'} className="preview-image" />
            )
        ) : (
            <></>
        );
    };

    return (
        <Wrapper seo_title={seoTitle} seo_description={seoDesc}>
            <section className="section-processing">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2" />
                        <div className="col-lg-10 text-header">
                            <Link
                                title={I18n.t('MENU_CATALOG') as any}
                                onClick={() => navigate('/catalog')}
                                path={'/catalog'}
                            />
                            <span className="arrow nonselectable">{'>'}</span>
                            {itemId !== null && (
                                <span className="nonselectable">
                                    {(I18n.t('ACCOUNT_ITEM_ORDER') as string) + ' ' + itemId}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="divider" />

                {step === 1 && (
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="processing-title">{I18n.t('ORDER_PROC_TITLE') as string}</div>
                            <img
                                src={ProgressIcon}
                                className="progress-icon"
                                style={{
                                    rotate: rotDeg + 'deg',
                                }}
                                alt=""
                            />
                            <div className="processing-message">{htmr(processingMessage)}</div>
                            <div className="processing-text">{I18n.t('ORDER_PROC_TEXT2') as string}</div>
                            <div className="processing-text">
                                {I18n.t('ORDER_PROC_TEXT3') as string}
                                <Link
                                    title={(' ' + I18n.t('ACCOUNT_FILES_TITLE')).toLowerCase() as string}
                                    onClick={() => navigate('/account?section=' + downloadSectionId, {})}
                                    path={'/account'}
                                />
                                .
                            </div>
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="processing-title">{I18n.t('ORDER_FINISHED_TITLE') as string}</div>
                            {renderPreview()}
                            <div className="download-text">{I18n.t('ORDER_FINISHED_TEXT') as string}</div>

                            <div
                                className="download-button"
                                onClick={() => {
                                    if (downloadLink === '' || downloadLink === null) {
                                        navigate('/account?section=' + downloadSectionId, {});
                                    } else {
                                        window.open(downloadLink);
                                    }
                                }}
                            >
                                {I18n.t('ORDER_FINISHED_BUTTON') as string}
                            </div>

                            {downloadLink !== null && downloadLink !== '' && (
                                <div className="row justify-content-center">
                                    <div className="download-text">
                                        <div>{I18n.t('ORDER_FINISHED_QR_TEXT1') as string}</div>
                                        <div>{I18n.t('ORDER_FINISHED_QR_TEXT2') as string}</div>
                                    </div>
                                    <QRCode value={downloadLink} className="qr-code" />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {step === 3 && (
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 processing-title">{I18n.t('ORDER_PROC_TITLE') as string}</div>
                            <div className="col-12" style={{ textAlign: 'center' }}>
                                <img src={syncErrorIcon} className="sync-icon" alt="" />
                            </div>
                            <div className="col-6 processing-message">
                                {I18n.t('ORDER_TRACKING_FAILED') as string}
                                <span
                                    className="link"
                                    onClick={() => navigate('/account?section=' + downloadSectionId, {})}
                                >
                                    {I18n.t('ACCOUNT_FILES_TITLE').toLowerCase() as string}
                                </span>
                                .
                            </div>
                        </div>
                    </div>
                )}
                {step === 4 && (
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 processing-title">{I18n.t('ORDER_PROC_TITLE') as string}</div>
                            <div className="col-12" style={{ textAlign: 'center' }}>
                                <img src={renderErrorIcon} className="error-icon" alt="" />
                                <div className="col-12 rendering-error-title">{I18n.t('ERROR') as string}</div>
                                <div className="col-12 rendering-error-text">{decodeRenderCode()}</div>
                            </div>
                            <div className="col-6 processing-message">
                                {I18n.t('ORDER_RENDER_FAILED') as string}
                                <span className="link" onClick={() => navigate('/contact', {})}>
                                    {I18n.t('MENU_CONTACT').toLowerCase() as string}
                                </span>
                                .
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </Wrapper>
    );
};
export default ProcessingPage;
