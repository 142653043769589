import React, { useState, FC, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import '../../style/style.scss';
import Wrapper from '../../components/wrapper/wrapper';
import LogoutModal from '../../components/logout/logout';
import AccountDetails from './details/details';
import CustomShare from './customSharing/custom';
import AccountFiles from './files/files';
import AccountSubscription from './subscription/subscription';
import AccountOrderDetails from './orderdetails/orderdetails';
import Underline from '../../images/menu-selected.png';
import { getPageAccount } from '../../helpers/actions/redux-page-actions';

const APIManager = API.instance;
interface Props {}

const AccountPage: FC<Props> = (props) => {
    const sectionEmptyView = 0; //always lowest number
    const sectionLogout = 1;
    const sectionCustomShare = 9;
    const sectionYourFiles = 10;
    const sectionSubscriptions = 11;
    const sectionAccountDetails = 12;
    const sectionOrderDetails = 20; //always highest number

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParameters = new URLSearchParams(window.location.search);

    const [seoTitle, setSeoTitle] = useState('');
    const [seoDesc, setSeoDesc] = useState('');
    const pageData = useSelector((state: RootStateOrAny) => state.pageReducers.accountData);

    const [previousSection, setPreviousSection] = useState(sectionEmptyView);
    const [currentSection, setCurrentSection] = useState(sectionEmptyView);
    const [logoutModalVisible, setLogoutModalVisible] = useState(false);
    const [id, setId] = useState('');

    useEffect(() => {
        dispatch(getPageAccount());

        let section = queryParameters.get('section');
        let id = queryParameters.get('id');
        let subscription = queryParameters.get('subscription');

        if (section !== null && parseInt(section) > sectionEmptyView && parseInt(section) < sectionOrderDetails) {
            setCurrentSection(parseInt(section));
        } else if (id !== null && id !== '') {
            setCurrentSection(sectionOrderDetails);
            setId(id);
        } else if (subscription !== null) {
            setCurrentSection(sectionOrderDetails);
            setId(subscription);
        } else {
            setCurrentSection(sectionYourFiles);
            setAddressBarURL(sectionYourFiles);
        }
    }, []);

    useEffect(() => {
        if (Object.keys(pageData).length > 0) {
            setSeoTitle(pageData.seo_title);
            setSeoDesc(pageData.seo_description);
        }
    }, [pageData]);

    useEffect(() => {
        if (currentSection === sectionLogout) {
            setLogoutModalVisible(true);
            setCurrentSection(sectionEmptyView);
            setAddressBarURL(sectionEmptyView);
        } else if (currentSection !== sectionEmptyView && (!APIManager.isLoggedIn() || !APIManager.hasUserID())) {
            navigate('/', { state: {} });
        }

        if (currentSection === sectionOrderDetails) {
            setId('');
        }
    }, [currentSection]);

    useEffect(() => {
        if (id !== '') {
            setCurrentSection(sectionOrderDetails);
            setAddressBarURL(sectionOrderDetails, id);
        }
    }, [id]);

    useEffect(() => {
        let section = queryParameters.get('section');
        let id = queryParameters.get('id');

        if (
            section !== null &&
            parseInt(section) !== currentSection &&
            parseInt(section) !== sectionEmptyView &&
            currentSection !== sectionEmptyView
        ) {
            setCurrentSection(parseInt(section));

            if (id !== null) {
                setId(id);
            }
        }
    }, [queryParameters]);

    const setAddressBarURL = (link: number, id?: string) => {
        let catalogLink = '/account?section=' + link;
        if (id !== null && id !== undefined && id !== '') {
            catalogLink += '&id=' + id;
        }
        window.history.pushState(null, '', catalogLink);
    };

    const renderMenuItem = (name: string, section: number) => {
        if (section === sectionLogout) {
            return (
                <div
                    className="menu-item"
                    onClick={() => {
                        setPreviousSection(currentSection);
                        setCurrentSection(section);
                        setAddressBarURL(section);
                    }}
                >
                    {name}
                    {section === currentSection && <img src={Underline} className="underlined" alt="" />}
                </div>
            );
        } else {
            return (
                <Link to={'/account?section=' + section}>
                    <div className="menu-item">
                        {name}
                        {section === currentSection && <img src={Underline} className="underlined" alt="" />}
                    </div>
                </Link>
            );
        }
    };

    const menuOptions = () => {
        return (
            <>
                {renderMenuItem(I18n.t('MENU_FILES'), sectionYourFiles)}
                {renderMenuItem(I18n.t('MENU_CUSTOM_LINK'), sectionCustomShare)}
                {renderMenuItem(I18n.t('MENU_SUB'), sectionSubscriptions)}
                {renderMenuItem(I18n.t('MENU_ACCOUNT_DETAILS'), sectionAccountDetails)}
                {renderMenuItem(I18n.t('MENU_LOGOUT'), sectionLogout)}
            </>
        );
    };

    return (
        <Wrapper seo_title={seoTitle} seo_description={seoDesc}>
            <section className="section-account">
                <div className="text-title">{I18n.t('MENU_ACCOUNT') as string}</div>

                <div className="mobile-account-menu">
                    <div className="d-flex justify-content-around">{menuOptions()}</div>
                </div>

                <div className="divider" />

                <div className="d-flex">
                    <div className="col-1 col-xl-3 left-container">{menuOptions()}</div>
                    <div className="col-12 col-xl-9 right-container">
                        {currentSection === sectionYourFiles && (
                            <AccountFiles active={currentSection === sectionYourFiles} />
                        )}
                        {currentSection === sectionCustomShare && (
                            <CustomShare active={currentSection == sectionCustomShare} />
                        )}
                        {currentSection === sectionSubscriptions && (
                            <AccountSubscription
                                active={currentSection === sectionSubscriptions}
                                viewOrderDetails={(id) => setId(id)}
                            />
                        )}
                        {currentSection === sectionAccountDetails && (
                            <AccountDetails active={currentSection === sectionAccountDetails} />
                        )}
                        {currentSection === sectionOrderDetails && (
                            <AccountOrderDetails active={currentSection === sectionOrderDetails} id={id} />
                        )}
                    </div>
                </div>

                <LogoutModal
                    open={logoutModalVisible}
                    Close={() => {
                        setLogoutModalVisible(false);
                        setCurrentSection(previousSection);
                        setAddressBarURL(previousSection);
                    }}
                />
            </section>
        </Wrapper>
    );
};
export default AccountPage;
