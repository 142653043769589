import React, { FC } from 'react';
import IconSelected from '../../../images/filter-selected.png';

interface Props {
    index: number;
    data: any;
    changeValueOption: (data: any) => void;
    submittingData: boolean;
}

const OptionToggle: FC<Props> = (props) => {
    return (
        <div className="option-toggle" key={props.index}>
            <div className="option-title">{props.data['optionText']}</div>
            <div className="option-text">
                <div
                    className="filter-icon-container"
                    style={{ background: props.data['optionValue'] === 'true' ? '#cd2b38' : '#3A3A3A' }}
                    onClick={() =>
                        !props.submittingData &&
                        props.changeValueOption(props.data['optionValue'] !== 'true' ? 'true' : 'false')
                    }
                >
                    {props.data['optionValue'] === 'true' && (
                        <img src={IconSelected} alt={'option-toggle' + props.index} className="option-toggle-icon" />
                    )}
                </div>
                {props.data['optionName']}
            </div>
        </div>
    );
};
export default OptionToggle;
