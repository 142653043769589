import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import {
    getUserRenderRequests,
    getUserRenderRequestsReset,
    deleteRequest,
    deleteRequestReset,
    checkUserLimit,
    checkUserLimitReset,
} from '../../../helpers/actions/redux-user-actions';
import { hasValidSubscription, hasValidSubscriptionReset } from '../../../helpers/actions/redux-stripe-actions';
import '../../../style/style.scss';
import I18n from '../../../helpers/i18n';
import API from '../../../helpers/api';
import moment from 'moment';
import PageButtonNavBar from '../../../components/pagebar/pagebar';
import PreviewModal from '../../../components/modal/modal';
import Link from '../../../components/link';
import deleteIcon from '../../../images/delete.svg';
import qrIcon from '../../../images/qr-code.svg';
import { Tooltip } from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoIcon from '../../../images/info.svg';

const APIManager = API.instance;
interface Props {
    active: boolean;
}

const AccountFiles: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const defaultNoSubText = (
        <>
            <span className="zero">0</span>
            <span className="sub-needed">{((' (' + I18n.t('RENDERS_NO_SUB')) as string) + ')'}</span>
        </>
    );
    const pageSize = 10;
    const [page, setPage] = useState(1);

    const [loadingQueue, setLoadingQueue] = useState(true);
    const [dataQueue, setDataQueue] = useState([]);
    const [paginatedData, setPaginatedData] = useState([]);

    const [showPreview, setShowPreview] = useState(false);
    const [previewURL, setPreviewURL] = useState<(string | boolean)[]>([]);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const [showQRModal, setShowQRModal] = useState(false);
    const [qrCode, setQRCode] = useState('');

    const userRenderRequestsState = useSelector((state: RootStateOrAny) => state.userReducers.userRenderRequestsState);
    const userRenderRequestsData = useSelector((state: RootStateOrAny) => state.userReducers.userRenderRequestsData);

    const userLimitsState = useSelector((state: RootStateOrAny) => state.userReducers.limitState);
    const userLimitError = useSelector((state: RootStateOrAny) => state.userReducers.limitError);
    const userLimitsData = useSelector((state: RootStateOrAny) => state.userReducers.limitData);
    const [renderLimit, setRenderLimit] = useState<any>();

    const deleteItemState = useSelector((state: RootStateOrAny) => state.userReducers.deleteItemState);
    const [deleteLoader, setDeleteLoader] = useState(false);

    const validSubState = useSelector((state: RootStateOrAny) => state.stripeReducers.validSubState);
    const validSubData = useSelector((state: RootStateOrAny) => state.stripeReducers.validSubData);

    useEffect(() => {
        if (validSubState === 'success') {
            if (validSubData.status) {
                dispatch(checkUserLimit());
            } else {
                setRenderLimit(defaultNoSubText);
            }
        } else if (validSubState === 'error') {
            setRenderLimit(defaultNoSubText);
        }
    }, [validSubState]);

    useEffect(() => {
        if (props.active) {
            setLoadingQueue(true);
            dispatch(getUserRenderRequests());
            dispatch(hasValidSubscription());
        } else {
            setLoadingQueue(false);
            setDeleteLoader(false);
            dispatch(hasValidSubscriptionReset());
            dispatch(checkUserLimitReset());
            dispatch(getUserRenderRequestsReset());
            dispatch(deleteRequestReset());
        }
    }, [props.active]);

    useEffect(() => {
        if (userLimitsState === 'success') {
            if (
                userLimitsData &&
                userLimitsData !== '' &&
                userLimitsData !== undefined &&
                userLimitsData !== null &&
                userLimitsData?.remain &&
                userLimitsData.remain !== undefined
            ) {
                if (parseInt(userLimitsData.remain) <= 0) {
                    setRenderLimit('0');
                } else {
                    setRenderLimit(userLimitsData.remain);
                }
            }
        } else if (userLimitsState === 'error') {
            if (
                userLimitError !== '' &&
                userLimitError.errorText !== undefined &&
                userLimitError.errorText === 'Subscription not active'
            ) {
                setRenderLimit(defaultNoSubText);
            }
        }
    }, [userLimitsState]);

    useEffect(() => {
        if (userRenderRequestsState === 'success') {
            setLoadingQueue(false);
            setDataQueue(userRenderRequestsData);
        } else if (userRenderRequestsState === 'error') {
            setLoadingQueue(false);
        }
    }, [userRenderRequestsState]);

    useEffect(() => {
        setCurrentPageData();
    }, [dataQueue, page]);

    const setCurrentPageData = () => {
        if (dataQueue.length > 0) {
            let tempData = [] as any;

            for (let i = 0; i < dataQueue.length; i++) {
                if (i + 1 <= page * pageSize && i + 1 > (page - 1) * pageSize) {
                    tempData.push(dataQueue[i]);
                }
            }
            setPaginatedData(tempData);
        } else {
            setPaginatedData([]);
        }
    };

    const getSize = (id: number) => {
        return APIManager.getAvailableSizes()[id];
    };

    const deleteItem = () => {
        let itemID = itemToDelete;
        if (itemID !== null) {
            dispatch(deleteRequestReset());
            dispatch(deleteRequest(itemID));
            setDeleteLoader(true);
        }
    };

    useEffect(() => {
        if (deleteItemState === 'success' && deleteLoader) {
            setShowDeleteModal(false);
            setItemToDelete(null);
            dispatch(getUserRenderRequestsReset());
            setLoadingQueue(true);
            dispatch(getUserRenderRequests());
            toast(I18n.t('TOAST_FILE_DELETE_SUCCESS') as string);
            setDeleteLoader(false);
        } else if (deleteItemState === 'error' && deleteLoader) {
            setShowDeleteModal(false);
            toast(I18n.t('TOAST_FILE_DELETE_ERROR') as string);
            setDeleteLoader(false);
        }
    }, [deleteItemState]);

    const toolTipDecodeMessage = (data: any) => {
        let renderCode;

        try {
            renderCode = JSON.parse(data.process_message);
        } catch (e: any) {
            return I18n.t('ERROR_1') as string;
        }

        if (renderCode !== undefined && typeof renderCode === 'object' && renderCode.code !== undefined) {
            switch (renderCode['code']) {
                case 'RENDER_LIMIT':
                    return I18n.t('RENDER_LIMIT') as string;
                case 'ERROR_1':
                    return I18n.t('ERROR_1') as string;
                case 'ERROR_2':
                    return I18n.t('ERROR_2') as string;
                default:
                    return I18n.t('ERROR_1') as string;
            }
        } else {
            return I18n.t('ERROR_1') as string;
        }
    };

    const renderItemRow = (data?: any) => {
        if (data === undefined) {
            return (
                <div className="row file-row" key={data?.id}>
                    <div className="col-md-3 col-xl-2 file-date first">{I18n.t('ACCOUNT_DATA_DATE') as string}</div>
                    <div className="col-3 col-md-2 col-xl-4 file-name first">
                        {I18n.t('ACCOUNT_DATA_DETAILS') as string}
                    </div>
                    <div className="col-2 col-md-1 col-xl-1 file-preview first">
                        {I18n.t('ACCOUNT_ITEM_QR') as string}
                    </div>
                    <div className="col-2 col-md-2 col-xl-2 file-preview first">
                        {I18n.t('ACCOUNT_ITEM_PREVIEW') as string}
                    </div>
                    <div className="col-3 col-md-2 col-xl-2 file-status first">
                        {I18n.t('ACCOUNT_DATA_FILE') as string}
                    </div>
                    <div className="col-2 col-md-2 col-xl-1 file-action first">
                        {I18n.t('ACCOUNT_ITEM_ACTION') as string}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="row file-row" key={data?.id}>
                    <div className="col-md-3 col-xl-2 file-date rest">
                        {moment.utc(data.date_created).format('MMM DD, YYYY')}
                    </div>
                    <div className="col-3 col-md-2 col-xl-4 file-name rest">
                        {data.gallery.name + ', '}
                        <span className="file-size-format">{getSize(parseInt(data.size))}</span>
                    </div>
                    {data.file !== null ? (
                        <div className="col-2 col-md-1 col-xl-1 file-qr rest">
                            <img
                                src={qrIcon}
                                className="qr-icon"
                                data-tooltip-id="qr-tooltip"
                                data-tooltip-content={I18n.t('ACCOUNT_ITEM_QR_TOOLTIP')}
                                onClick={() => {
                                    setShowQRModal(true);
                                    setQRCode(APIManager.getDirectusDownloadURL(data.file));
                                }}
                                alt=""
                            />
                            <Tooltip id="qr-tooltip" />
                        </div>
                    ) : (
                        <div className="col-2 col-md-1 col-xl-1 file-qr rest">{I18n.t('NOT_AVAILABLE') as string}</div>
                    )}
                    {data.thumb !== null ? (
                        <div className="col-2 col-md-2 col-xl-2 file-preview">
                            <Link
                                onClick={() => {
                                    setPreviewURL([
                                        data.thumb,
                                        data.ext !== undefined && data.ext !== null && data.ext == 'mp4' ? true : false,
                                    ]);
                                    setShowPreview(true);
                                }}
                                path={data.thumb}
                            >
                                <span className="preview-button">{I18n.t('CUST_PREVIEW') as string}</span>
                            </Link>
                        </div>
                    ) : (
                        <div className="col-2 col-md-2 col-xl-2 file-preview-text">
                            {I18n.t('NOT_AVAILABLE') as string}
                        </div>
                    )}
                    {data.file !== null ? (
                        <div className="col-3 col-md-2 col-xl-2 file-download">
                            <a
                                href={APIManager.getDirectusDownloadURL(data.file)}
                                download={data.file}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className="download-button">{I18n.t('DOWNLOAD') as string}</div>
                            </a>
                        </div>
                    ) : (
                        <div className="col-3 col-md-2 col-xl-2 file-status rest">
                            <span className="capitalize">{data.status}</span>
                            {data.status === 'error' && (
                                <img
                                    src={InfoIcon}
                                    className="error-icon"
                                    data-tooltip-id="error-tooltip"
                                    data-tooltip-content={
                                        (I18n.t('ACCOUNT_ITEM_ORDER') as string) +
                                        data.id +
                                        ': ' +
                                        toolTipDecodeMessage(data)
                                    }
                                    alt=""
                                />
                            )}
                            <Tooltip id="error-tooltip" />
                        </div>
                    )}
                    <div className="col-2 col-md-2 col-xl-1 file-action rest">
                        <img
                            src={deleteIcon}
                            className="delete-icon"
                            data-tooltip-id="delete-tooltip"
                            data-tooltip-content={I18n.t('ACCOUNT_ITEM_DELETE')}
                            onClick={() => {
                                setShowDeleteModal(true);
                                setItemToDelete(data.id);
                            }}
                            alt=""
                        />
                        <Tooltip id="delete-tooltip" />
                    </div>
                </div>
            );
        }
    };

    return (
        <section className="section-account-files">
            <div className="container">
                <div className="col-12 d-flex justify-content-between">
                    <div className="title">{I18n.t('ACCOUNT_FILES_TITLE') as string}</div>
                    <div className="limits">
                        {renderLimit && (
                            <>
                                {(I18n.t('RENDERS_LEFT') as string) + ':'}
                                <span className="renders-left">{renderLimit}</span>
                                <img
                                    src={InfoIcon}
                                    className="info-icon"
                                    data-tooltip-id="limit-tooltip"
                                    data-tooltip-content={I18n.t('RENDERS_TOOLTIP')}
                                    alt=""
                                />
                                <Tooltip id="limit-tooltip" />
                            </>
                        )}
                    </div>
                </div>

                {dataQueue.length > 0 ? (
                    <div>
                        {renderItemRow()}
                        {paginatedData.map((item: any, index: number) => {
                            return renderItemRow(item);
                        })}
                        <div className="divider" />
                        <div className="warning">{I18n.t('ACCOUNT_FILES_WARNING') as string}</div>
                        <div className="page-buttons">
                            <PageButtonNavBar
                                currentPage={page}
                                setPage={(value) => setPage(value)}
                                maxPages={Math.ceil(dataQueue.length / pageSize)}
                            />
                        </div>
                    </div>
                ) : (
                    dataQueue.length === 0 &&
                    !loadingQueue && (
                        <div className="col-12">
                            <span className="no-result">{I18n.t('ACCOUNT_FILES_NONE_TEXT1') as string}</span>
                            <Link onClick={() => navigate('/catalog', { state: {} })} path={'/catalog'}>
                                <span className="no-result-link">{I18n.t('ACCOUNT_FILES_NONE_TEXT2') as string}</span>
                            </Link>
                        </div>
                    )
                )}
                <ToastContainer position="top-right" theme="dark" />
            </div>

            <PreviewModal previewUrl={previewURL} show={showPreview} onClose={() => setShowPreview(false)} />
            <PreviewModal qrCode={qrCode} show={showQRModal} onClose={() => setShowQRModal(false)} />
            <PreviewModal
                show={showDeleteModal}
                onClose={() => {
                    setShowDeleteModal(false);
                    setItemToDelete(null);
                }}
                title={I18n.t('ALERT_DELETE_TITLE')}
                description={I18n.t('ALERT_DELETE_DESCRIPTION')}
                cancel
                confirmText={I18n.t('ALERT_DELETE_TITLE')}
                onConfirm={() => deleteItem()}
            />
        </section>
    );
};

export default AccountFiles;
